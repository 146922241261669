import { AfterContentInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionItem } from './core/data/collection/collection.model';
import { InboxDataService } from './core/data/collection/inbox-data.service';
import { GlobalDialogService } from './core/services/global-dialog.service';
import { MediaVersionUploadService } from './core/services/media-version-upload.service';
import { Subscription } from 'rxjs';

import { AuthService } from './auth/auth.service';
import { ObjectType } from './core/data/enums/enums';
import { take } from 'rxjs/operators';
import { ProcessApiJobHubService } from './core/hub/processapi-job-hub.service';

// import { ToasterService } from "app/core/services/toaster.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    public authService: AuthService, private processApiJobHubService: ProcessApiJobHubService) {
  }

  public isRouterOutletOpened = false;

  public isSubmitting = false;

  public ngOnInit() {
    this.processApiJobHubService.init();
  }

  public onRouterOutletActivate(event: any) {
    this.isRouterOutletOpened = true;
    
    this.processApiJobHubService.init();
  }

  public onRouterOutletDeactivate(event: any) {
    this.isRouterOutletOpened = false;
  }
  public onLogOutClick() {
    if (this.isSubmitting) { return; }
    this.isSubmitting = true;
    this.authService.logout().pipe(take(1)).subscribe((value) => {
      // comment
    }, () => {
      this.isSubmitting = false;
    }, () => {
      this.isSubmitting = false;
    });
  }

  public onThemeToggleChange() {
    document.body.classList.toggle('app-dark-theme');
  }

  public ngOnDestroy() {

  }
}
