import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { ResourceTypeIntent } from '../enums/enums';

// fake service for lookup
@Injectable()
export class ResourceTypeIntentDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: ResourceTypeIntent.Main, name: 'Main' });
        lookups.push({ id: ResourceTypeIntent.Detail, name: 'Detail' });
        lookups.push({ id: ResourceTypeIntent.Background, name: 'Background' });
        lookups.push({ id: ResourceTypeIntent.Logo, name: 'Logo' });
        lookups.push({ id: ResourceTypeIntent.MainBackup, name: 'Backup for Main' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
