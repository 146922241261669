import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { DeviceChannel, IDeviceChannel } from '../device-channel/device-channel.model';
import { ObjectType } from '../enums/enums';

export enum LocationMode { AllAllowed = 0, AllowSelected = 1, RestrictSelected = 2 }

export interface IMediaDeviceChannelRelation extends IBaseRelationModel {
    media_id: number;
    device_channel_id: number;
    is_restricted: number;
    deviceChannel: IDeviceChannel;
}

export class MediaDeviceChannelRelation extends BaseRelationModel <IMediaDeviceChannelRelation> {
    constructor(dtm: IMediaDeviceChannelRelation = null) {
        super(dtm || {
            media_id: 0,
            device_channel_id: 0,
            is_restricted: 0,
            deviceChannel: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get device_channel_id() {
        return this.dtm.device_channel_id;
    }
    public set device_channel_id(value) {
        this.dtm.device_channel_id = value;
    }

    public get is_restricted() {
        return this.dtm.is_restricted === 1;
    }
    public set is_restricted(value) {
        this.dtm.is_restricted = value ? 1 : 0;
    }

    public update(dtm: IMediaDeviceChannelRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IMediaDeviceChannelRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.deviceChannel;
    }

    public static getInstance(media_id: number, device_channel_id: number, is_restricted: boolean){
        const empty = new MediaDeviceChannelRelation();
        empty.media_id = media_id;
        empty.device_channel_id = device_channel_id;
        empty.is_restricted = is_restricted;
        return empty;
    }
}
