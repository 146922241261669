import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IProduct, Product } from './product.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ProductDataService extends BaseListableDataService<Product, IProduct, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'product', ObjectType.PRODUCT, ['related', 'productgroups', 'tags', 'resources', 'productprices', 'children'], ['related', 'productgroups', 'tags', 'resources', 'productprices', 'children', 'contentSchedules']);
  }

}
