import { IBlobProgress } from '../../services/blob-progress.model';
import * as Enumerable from 'linq-es2015';

export enum UploadItemType { MediaVersion, Resource }
export enum UploadItemStatus { Waiting, Uploading, UploadError, Uploaded }
export class UploadItem {
    constructor(public file: File, public type: UploadItemType) {

        const blobs = this.getFileBlobs(file);

        blobs.forEach((blob, index) => {
            this.blobProgresses.push({ blob, progress: 0, blobID: index + 1 });
        });
    }

    public parentID = 0;

    public get name() {
        return this.file.name;
    }

    public get mime_type() {
        return this.file.type;
    }

    public get isImage() {
        return this.file.type && this.file.type.indexOf('image') > -1;
    }

    public get isVideo() {
        return this.file.type && this.file.type.indexOf('video') > - 1;
    }

    public get mime_icon() {
        if (this.isImage) {
            return 'image';
        } else if (this.isVideo) {
            return 'movie';
        }
        return 'warning';
    }

    public get find_text() {
        if (this.type === UploadItemType.MediaVersion) {
            return 'Find in Inbox';
        } else if (this.type === UploadItemType.Resource) {
            return 'Find in Resources';
        }
        return 'Unknown Type';
    }

    public uploadFunction: () => Promise<void> = null;

    public status = UploadItemStatus.Waiting;

    public get isWaiting() {
        return this.status === UploadItemStatus.Waiting;
    }
    public get isUploading() {
        return this.status === UploadItemStatus.Uploading;
    }
    public get isUploadError() {
        return this.status === UploadItemStatus.UploadError;
    }
    public get isUploaded() {
        return this.status === UploadItemStatus.Uploaded;
    }

    public blobProgresses: IBlobProgress[] = [];

    public get progress() {
        if (this.blobProgresses.length < 1) { return 0; }
        return Math.floor(Enumerable.From(this.blobProgresses).Sum((x) => x.progress) / this.blobProgresses.length);
    }

    public get progressLabel() {
        return `${this.progress}%`;
    }

    public get progressMode(): 'indeterminate' | 'determinate' | 'buffer' | 'query' {
        return 'determinate';
    }

    private getFileBlobs(file: File): Blob[] {

        const blobSize = (1024 * 1024 * 1); // 1MB

        const fileSize = file.size;
        const blobsCount = Math.ceil(file.size / blobSize);
        let blobIndex = 0;

        const blobs: Blob[] = [];
        while (blobIndex < blobsCount) {
            const offset = (blobIndex * blobSize);
            blobs.push(file.slice(offset, offset + blobSize));
            blobIndex++;
        }
        return blobs;
    }

}
