import { BaseModel, PropertyUpdateMode } from '../base.model';
import { IProperty, Property } from './property.model';
import { IPropertyIntent, PropertyIntent } from './property-intent.model';
import { IPropertyValue, PropertyValue } from './property-value.model';
// import { PropertyValues } from './property-values.model';
import * as Enumerable from 'linq-es2015';
import { ObjectType } from '../enums/enums';
import { SystemLanguage, ISystemLanguage } from '../language/system-language.model';

export interface IPropertySet {
    property: IProperty;
    intent: IPropertyIntent;
    value: IPropertyValue[];
}

export class PropertySet extends BaseModel<IPropertySet> {
    constructor(dtm: IPropertySet = null) {
        super(dtm || {
            property: null,
            intent: null,
            value: [],
        });
        this.update(this.dtm, true, true);
    }

    public get property() { return this.getModelProperty(() => this.dtm.property, (x) => new Property(x), PropertyUpdateMode.Update); }
    public set property(value) { this.setModelProperty(() => this.dtm.property, (x) => this.dtm.property = x, value, PropertyUpdateMode.Update); }

    public get intent() { return this.getModelProperty(() => this.dtm.intent, (x) => new PropertyIntent(x), PropertyUpdateMode.Update); }
    public set intent(value) { this.setModelProperty(() => this.dtm.intent, (x) => this.dtm.intent = x, value, PropertyUpdateMode.Update); }


    // public get valueWrapper() {
    //     if (!this._valueWrapper && this.dtm.value) {
    //         this._valueWrapper = new PropertyValues(this.dtm.value);
    //     }
    //     return /*stange*/ this._valueWrapper;
    // }
    // public set valueWrapper(value) {
    //     this._valueWrapper = value;
    // }

    public get values() { return this.getModelArray(() => this.dtm.value, (x) => new PropertyValue(this, x), PropertyUpdateMode.Clear); }
    public set values(value) { this.setModelArray(() => this.dtm.value, (x) => this.dtm.value = x, value, PropertyUpdateMode.Clear); }

    // private _valueWrapper: PropertyValues = null;

    public labelOverride: string;
    public requiredOverride: boolean;
    public placeholderOverride: string;
    public hintOverride: string;
    public groupTitleOverride: string;
    public lookupQuery: string;
    public objectSelectType: 'dialog' | 'autocomplete' | 'dropdown' = 'dialog';
    public inputType: 'default' | 'textarea' = 'default';
    // public groupExtraBottomMargin: boolean;
    public isHidden = false;

    public static fillMissingValues(propertySets: PropertySet[]) {
        if (!propertySets) { return; }

        const requiredLanguages: SystemLanguage[] = [];

        const systemLanguagesString = sessionStorage.getItem('system_languages');
        if (systemLanguagesString) {
            const requiredLanguageDtms = JSON.parse(systemLanguagesString) as ISystemLanguage[];
            if (requiredLanguageDtms && requiredLanguageDtms.length) {
                requiredLanguageDtms.forEach((x) => {
                    requiredLanguages.push(new SystemLanguage(x));
                });
            }
        }


        propertySets.forEach((propertySet) => {
            let systemLanguages: SystemLanguage[] = [];

            if (propertySet.property.isLangDependent && requiredLanguages.length) {
                // add all language blank objects
                systemLanguages = requiredLanguages;
            } else if (propertySet.values.length === 0) { // !propertySet.property.isObject && 
                const nullLanguage = new SystemLanguage();
                nullLanguage.id = null;
                systemLanguages = [nullLanguage];
            }

            systemLanguages.forEach((systemLanguage) => {
                const match = Enumerable.AsEnumerable(propertySet.values).FirstOrDefault((x) => x.language_id === systemLanguage.id);
                if (!match) {
                    const nullLanguageMatch = Enumerable.AsEnumerable(propertySet.values).FirstOrDefault((x) => x.language_id === null);
                    if (!nullLanguageMatch) {
                        propertySet.values.push(new PropertyValue(propertySet, {
                            id: null,
                            client_id: null,
                            language_id: systemLanguage.id,
                            language: systemLanguage.dtm,
                            // property_id: propertySet.property.id,
                            content: null,
                            content_override: null,
                            content_type_id: ObjectType.UNKNOWN,
                            content_data: null,
                            content_override_data: null,
                            is_overrided: false,
                        }));
                    } else { // if we have a exicting item with null language, then give the first language id to that item
                        // needed if 'is language dependent' is changed while data already created
                        nullLanguageMatch.language_id = systemLanguage.id;
                        nullLanguageMatch.language = systemLanguage;
                    }
                } else {
                    // make sure that the language object is filled
                    if (!match.language) {
                        match.language = systemLanguage;
                    }
                }
            });

            if(propertySet.property.isLangDependent){
                console.log(propertySet.values);
                // remove values that have no language id set
                propertySet.values = propertySet.values.filter(x => x.language_id > 0);
                console.log(propertySet.values);
            }
            // update dtm values also
            propertySet.values = propertySet.values;
        });
    }

    public update(dtm: IPropertySet, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);


        this.updateModelProperty(() => this.dtm.property, (x) => new Property(x), (x) => this.property = x, forceReplaceDtm);
        this.updateModelProperty(() => this.dtm.intent, (x) => new PropertyIntent(x), (x) => this.intent = x, forceReplaceDtm);

    }

    protected clearLocalProps() {
        super.clearLocalProps();

    }

    public cleanForSave(dtm: IPropertySet, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTM(PropertyIntent, dtm.intent, saver);
        this.cleanDTM(Property, dtm.property, saver);
        this.cleanDTMs(PropertyValue, dtm.value, saver);
    }

}
