import { IInstalledPackage, InstalledPackage } from './installed-package.model';
import { BaseModel, PropertyUpdateMode } from 'app/core/data/base.model';


export interface IIotDeviceInfo {
    DeviceArchitecture: string;
    DeviceModel: string;
    DeviceName: string;
    OSVersion: string;

    // merged request propery
    playerAppPackage: IInstalledPackage;
}

export class IotDeviceInfo extends BaseModel<IIotDeviceInfo> {
    constructor(dtm: IIotDeviceInfo) {
        super(dtm);
        this.update(this.dtm, true, true);
    }



    public get playerAppPackage() { return this.getModelProperty(() => this.dtm.playerAppPackage, (x) => new InstalledPackage(x), PropertyUpdateMode.Clear); }



    public get playerAppVersion() {
        if (this.playerAppPackage && this.playerAppPackage.version) {
            return this.playerAppPackage.version;
        }
        return '';
    }

    public get osVersion() {
        return this.dtm.OSVersion;
    }

    public update(dtm: IIotDeviceInfo, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }
}