import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup, ILookupWithTitles } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { SortMy } from '../sort/sort.model';
import { FilterMy } from '../filter/filter.model';
import * as Enumerable from 'linq-es2015';
import { CampaignType, ObjectType } from '../enums/enums';
import { Booking, IBooking } from './booking.model';

@Injectable()
export class BookingDataService extends BaseListableDataService<Booking, IBooking, ILookupWithTitles> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'campaign', ObjectType.CAMPAIGN,
      ['related'], ['related', 'media', 'rating', 'events', 'locations', 'screengroups', 'campaignSchedules', 'spotAppSources']);

      this.requiredfilters = [new FilterMy({key: 'type_id', value: CampaignType.Booking })];
  }

  protected getLookupDatas(
    pageNr: number, pageSize: number,
    filters: FilterMy[], sorts: SortMy[], includes: string[],
    search = '', query = '', subResourceName = ''): Observable<IListData<ILookupWithTitles>> {

    sorts = [new SortMy({ key: 'created_at', direction: 'desc' })];

    return this.getPage(pageNr, pageSize, filters, sorts, search, query, subResourceName, includes).pipe(map((res) => {
      return {
        data: Enumerable.AsEnumerable(res.data).Select((x) => {
          const booking = new Booking(x);
          const titles = booking.titleProperty ? Enumerable.AsEnumerable(booking.titleProperty.values)
            .Where((v) => !!v.contentString && v.contentString !== x.name).Select((v) => v.contentString).Distinct().ToArray() : [];
          return {
            id: x.id,
            name: x.name,
            titles
          };
        }).ToArray(),
        filter: res.filter,
        totalCount: res.totalCount,
      };
    }));
  }
}
