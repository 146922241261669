import { BaseModel } from 'app/core/data/base.model';

export interface IVersion {
    Build: number;
    Major: number;
    Minor: number;
    Revision: number;
}

export interface IRegisteredUser {
    UserDisplayName: string;
    UserSID: string;
}

export interface IInstalledPackage {
    AppListEntry: number;
    CanUninstall: boolean;
    Name: string;
    PackageDisplayName: string;
    PackageFamilyName: string;
    PackageFullName: string;
    PackageOrigin: number;
    PackageRelativeId: string;
    Publisher: string;
    Version: IVersion;
    RegisteredUsers: IRegisteredUser[];
}

export class InstalledPackage extends BaseModel<IInstalledPackage> {
    constructor(dtm: IInstalledPackage) {
        super(dtm);
        this.update(this.dtm, true, true);
    }

    public get version() {
        return `${this.dtm.Version.Major}.${this.dtm.Version.Minor}.${this.dtm.Version.Build}.${this.dtm.Version.Revision}`;
    }

    public update(dtm: IInstalledPackage, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }
}
