import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ResourceType, IResourceType } from '../resource-type/resource-type.model';
import { ObjectType } from '../enums/enums';

export interface IResourceResourceTypeRelation extends IBaseRelationModel {
    resource_id: number;
    type_id: number;
    resourceType: IResourceType;
}

export class ResourceResourceTypeRelation extends BaseRelationModel <IResourceResourceTypeRelation> {
    constructor(dtm: IResourceResourceTypeRelation = null) {
        super(dtm || {
            resource_id: 0,
            type_id: 0,
            resourceType: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get resourceID() {
        return this.dtm.resource_id;
    }
    public set resourceID(value) {
        this.dtm.resource_id = value;
    }

    public get resourceTypeID() {
        return this.dtm.type_id;
    }
    public set resourceTypeID(value) {
        this.dtm.type_id = value;
    }


    public update(dtm: IResourceResourceTypeRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IResourceResourceTypeRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.resourceType;
    }

    public static getInstance(resourceID: number, resourceTypeID: number){
        const empty = new ResourceResourceTypeRelation();
        empty.resourceID = resourceID;
        empty.resourceTypeID = resourceTypeID;
        return empty;
    }
}
