import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { ClientResolver } from './core/resolvers/client.resolver';
import { UserResolver } from './core/resolvers/user.resolver';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: 'auth', loadChildren: () => import('app/auth/auth.module').then((m) => m.AuthModule) },
            { path: '', redirectTo: 'content', pathMatch: 'full', canLoad: [AuthGuardService] },
            { path: 'content', loadChildren: () => import('app/content/content.module').then((m) => m.ContentModule), canLoad: [AuthGuardService] },
            { path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.module').then((m) => m.DashboardModule), canLoad: [AuthGuardService] },
            { path: 'account', loadChildren: () => import('app/account/account.module').then((m) => m.AccountModule), canLoad: [AuthGuardService] },
            {
                path: 'configuration', loadChildren: () => import('app/configuration/configuration.module').then((m) => m.ConfigurationModule),
                canLoad: [AuthGuardService]
            },
            {
                path: 'integration', loadChildren: () => import('app/integration/integration.module').then((m) => m.IntegrationModule),
                canLoad: [AuthGuardService]
            },
            { path: 'output', loadChildren: () => import('app/output/output.module').then((m) => m.OutputModule), canLoad: [AuthGuardService] },
            {
                path: 'programming', loadChildren: () => import('app/programming/programming.module').then((m) => m.ProgrammingModule),
                canLoad: [AuthGuardService]
            },
            { path: 'collection', loadChildren: () => import('app/collection/collection.module').then((m) => m.CollectionModule), canLoad: [AuthGuardService] },
            {
                path: '**', component: PageNotFoundComponent, canActivate: [AuthGuardService],
                resolve: { client: ClientResolver, user: UserResolver }
            }
        ], {})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
