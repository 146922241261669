import { BaseIdModel, IBaseIdModel } from './base-id.model';
import { HttpRequest } from '@angular/common/http';
import { ObjectType } from './enums/enums';

export interface IBaseCreationModel extends IBaseIdModel {
    created_at: number;
    updated_at: number;
    created_by: number;
    updated_by: number;
}

export abstract class BaseCreationModel<IT extends IBaseCreationModel> extends BaseIdModel<IT>  {
    constructor(dtm: IT) {
        super(dtm);
    }

    public get created_at() {
        return this.dtm.created_at;
    }
    public set created_at(value) {
        this.dtm.created_at = value;
    }
    public get created_at_date() {
        return new Date(this.created_at * 1000);
    }

    public get created_by() {
        return this.dtm.created_by;
    }
    public set created_by(value) {
        this.dtm.created_by = value;
    }

    public get updated_at() {
        return this.dtm.updated_at;
    }
    public set updated_at(value) {
        this.dtm.updated_at = value;
    }
    public get updated_at_date() {
        return new Date(this.updated_at * 1000);
    }

    public get updated_by() {
        return this.dtm.updated_by;
    }
    public set updated_by(value) {
        this.dtm.updated_by = value;
    }

    public cleanForSave(dtm: IT, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
        delete dtm.created_at;
        delete dtm.created_by;
        delete dtm.updated_at;
        delete dtm.updated_by;
    }
}
