import { HttpClient } from '@angular/common/http';
import { ToasterService } from '../services/toaster.service';
import { Observable, Subject } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { BaseDataService, IObjectData } from './base-data.service';
import { FilterMy } from './filter/filter.model';
import { SortMy } from './sort/sort.model';
import { Status, DataServiceType, ObjectType } from './enums/enums';
import { IBaseStatusModel } from './base-status.model';
import { BaseModel } from './base.model';
import * as Enumerable from 'linq-es2015';

export interface IModelInitedSubject<Tdtm> {
    // parent_id: number;
    model: Tdtm;
}

export class BaseDetailDataService<Tmodel extends BaseModel<Tdtm>, Tdtm> extends BaseDataService {

    private modelInitedSubject = new Subject<IModelInitedSubject<Tdtm>>();
    public modelInited$ = this.modelInitedSubject.asObservable().pipe(share());
    public resourceName: string;
    public resourceDisplayName: string;

    constructor(
        protected http: HttpClient, toastr: ToasterService,
        resourceName: string, private objectType: ObjectType, public detailIncludes: string[], dataServiceType: DataServiceType = 'default') {
        super(toastr, dataServiceType);
        this.resourceName = resourceName;

        if (this.resourceName) {

            let rn = this.resourceName.trim().replace('-', ' ').replace('/', ' ');
            rn = rn.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
            rn = rn.replace(/\b[a-z](?=[a-z]{2})/g, (letter) => {
                return letter.toUpperCase();
            });
            this.resourceDisplayName = rn;

        }
    }

    protected resetCachedObservables() {
        // to override
    }

    public get(id: number, alertOnError = true): Observable<Tdtm> {
        return this.getData(id, this.detailIncludes, alertOnError)
            .pipe(tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName}`, 'Error!');
            }));
    }

    public add(model: Tmodel): Observable<Tdtm> {
        // clean object
        const dtm = JSON.parse(JSON.stringify(model.dtm)) as Tdtm;
        model.cleanForSave(dtm, this.objectType);

        return this.addData(dtm, this.detailIncludes)
            .pipe(map((value) => value.data)
                , tap((value) => {
                    this.toastr.success(`${this.resourceDisplayName} added`, 'Success!');
                }, (error) => {
                    this.toastr.error(`Failed to add ${this.resourceDisplayName}`, 'Error!');
                }, () => {
                    this.resetCachedObservables();
                }));
    }

    public put(id: number, model: Tmodel, alertSucces = false): Observable<Tdtm> {
        // clean object
        const dtm = JSON.parse(JSON.stringify(model.dtm)) as Tdtm;
        model.cleanForSave(dtm, this.objectType);

        return this.putData(id, dtm, this.detailIncludes)
            .pipe(map((value) => value.data)
                , tap((value) => {
                    if (alertSucces) {
                        this.toastr.success(`${this.resourceDisplayName} saved`, 'Success!');
                    }
                }, (error) => {
                    this.toastr.error(`Failed to save ${this.resourceDisplayName}`, 'Error!');
                }, () => {
                    this.resetCachedObservables();
                }));
    }

    public patch(id: number, dtm: Tdtm, alertSucces = false): Observable<Tdtm> {

        return this.patchData(id, dtm, this.detailIncludes)
            .pipe(map((value) => value.data)
                , tap((value) => {
                    if (alertSucces) {
                        this.toastr.success(`${this.resourceDisplayName} saved`, 'Success!');
                    }
                }, (error) => {
                    this.toastr.error(`Failed to save ${this.resourceDisplayName}`, 'Error!');
                }, () => {
                    this.resetCachedObservables();
                }));
    }

    public publish(id: number, model: Tmodel, alertSucces = false): Observable<Tdtm> {

        // clean object
        const dtm = JSON.parse(JSON.stringify(model.dtm)) as Tdtm;
        model.cleanForSave(dtm, this.objectType);

        return this.publishData(id, dtm).pipe(map((value) => value.data)
            , tap((value) => {
                if (alertSucces) {
                    this.toastr.success(`${this.resourceDisplayName} published`, 'Success!');
                }
            }, (error) => {
                this.toastr.error(`Failed to publish ${this.resourceDisplayName}`, 'Error!');
            }, () => {
                this.resetCachedObservables();
            }));
    }

    public trash(id: number): Observable<boolean> {
        return this.trashData(id).pipe(tap((value) => {
            this.toastr.success(`${this.resourceDisplayName} trashed`, 'Success!');
        }, (error) => {
            this.toastr.error(`Failed to trash ${this.resourceDisplayName}`, 'Error!');
        }, () => {
            this.resetCachedObservables();
        }));
    }

    public suspend(id: number): Observable<boolean> {
        return this.suspendData(id).pipe(map((value) => value.data.status === Status.Suspended),
            tap((value) => {
                this.toastr.success(`${this.resourceDisplayName} suspended`, 'Success!');
            }, (error) => {
                this.toastr.error(`Failed to suspend ${this.resourceDisplayName}`, 'Error!');
            }, () => {
                this.resetCachedObservables();
            }));
    }

    public activate(id: number): Observable<boolean> {
        return this.activateData(id).pipe(map((value) => value.data.status === Status.Active),
            tap((value) => {
                this.toastr.success(`${this.resourceDisplayName} activated`, 'Success!');
            }, (error) => {
                this.toastr.error(`Failed to activate ${this.resourceDisplayName}`, 'Error!');
            }, () => {
                this.resetCachedObservables();
            }));
    }

    public delete(id: number): Observable<any> {
        return this.deleteData(id).pipe(tap((value) => {
            this.toastr.success(`${this.resourceDisplayName} deleted`, 'Success!');
        }, (error) => {
            this.toastr.error(`Failed to delete ${this.resourceDisplayName}`, 'Error!');
        }, () => {
            this.resetCachedObservables();
        }));
    }

    public init(data: any = null): Observable<Tdtm> {
        return this.initData(data, this.detailIncludes).pipe(map((x) => x.data), tap((value) => {
            this.modelInitedSubject.next({
                model: value
            });
            // this.toastr.success(`${this.resourceDisplayName} inited`, "Success!");
        }, (error) => {
            this.toastr.error(`Failed to init ${this.resourceDisplayName}`, 'Error!');
        }, () => {
            // comment
        }));
    }

    public removeInit(id: number): Observable<boolean> {
        return this.removeInitData(id).pipe(map((x) => true), tap((value) => {
            // this.toastr.success(`${this.resourceDisplayName} inited`, "Success!");
        }, (error) => {
            this.toastr.error(`Failed to remove init ${this.resourceDisplayName}`, 'Error!');
        }, () => {
            this.resetCachedObservables();
        }));
    }

    protected getData(id: number, includes: string[], alertOnError = true): Observable<Tdtm> {
        const include = this.getIncludeQueryString('?', includes);

        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}${include}`;
        return this.http.get<Tdtm>(url.replace(/\?$/, '').replace(/&$/, ''), {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught, alertOnError)));
    }

    private addData(model: Tdtm, includes: string[]): Observable<IObjectData<Tdtm>> {
        const include = this.getIncludeQueryString('?', includes);

        if ((model as any).client_id === 0) {
            delete (model as any).client_id;
        }

        const url = `${this.apiBaseUrl}/${this.resourceName}${include}`;
        return this.http.post<IObjectData<Tdtm>>(url.replace(/\?$/, '').replace(/&$/, ''), model, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private putData(id: number, model: Tdtm, includes: string[]): Observable<IObjectData<Tdtm>> {
        const include = this.getIncludeQueryString('?', includes);

        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}${include}`;
        return this.http.put<IObjectData<Tdtm>>(url.replace(/\?$/, '').replace(/&$/, ''), model, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private patchData(id: number, model: Tdtm, includes: string[]): Observable<IObjectData<Tdtm>> {
        const include = this.getIncludeQueryString('?', includes);

        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}${include}`;
        return this.http.patch<IObjectData<Tdtm>>(url.replace(/\?$/, '').replace(/&$/, ''), model, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private publishData(id: number, model: Tdtm): Observable<IObjectData<Tdtm>> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}/publish`;
        return this.http.put<IObjectData<Tdtm>>(url, model, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private trashData(id: number): Observable<boolean> {

        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}/trash`;

        return this.http.put<boolean>(url, null, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private suspendData(id: number) {
        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}`;
        return this.http.patch<IObjectData<IBaseStatusModel>>(url, { status: Status.Suspended }, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private activateData(id: number) {
        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}`;
        return this.http.patch<IObjectData<IBaseStatusModel>>(url, { status: Status.Active }, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected deleteData(id: number): Observable<any> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/${id}`;
        return this.http.delete(url, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private initData(data: any, includes: string[]): Observable<IObjectData<Tdtm>> {
        // if gotten from query string then all values are strings
        if (data) {
            if (data.related_conf_base_id) {
                data.related_conf_base_id = parseInt(data.related_conf_base_id, 10);
            }
            if (data.status) {
                data.status = parseInt(data.status, 10);
            }
            if (data.media_id) {
                data.media_id = parseInt(data.media_id, 10);
            }
            if (data.type_id) {
                data.type_id = parseInt(data.type_id, 10);
            }
        }

        const include = this.getIncludeQueryString('?', includes);

        return this.http.post<IObjectData<Tdtm>>(`${this.apiBaseUrl}/${this.resourceName}/init${include}`, data, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private removeInitData(id: number): Observable<any> {
        return this.http.delete<any>(`${this.apiBaseUrl}/${this.resourceName}/${id}/init`, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected getIncludeQueryString(prefix: string, items: string[]) {
        let include = '';
        if (items) {
            items = Enumerable.from(items).Distinct().ToArray();
            items.forEach((x) => {
                if (x !== null) {
                    const i = items.indexOf(x);
                    include = `${include}${i > 0 ? '&' : ''}$include[]=${x}`;
                }
            });
        }

        if (include.length) {
            include = `${prefix}${include}`;
        }

        return include;
    }

    protected getFilterQueryString(prefix: string, items: FilterMy[]) {
        return FilterMy.getQueryString(prefix, items);
    }

    protected getSortQueryString(prefix: string, items: SortMy[]) {
        return SortMy.getQueryString(prefix, items);
    }

    public fixQueryStringStart(query: string) {

        if (query && query.length) {
            if (query.indexOf('&') === 0) {
                query = query.substr(1);
            }

            if (query.indexOf('?&') === 0) {
                query = query.substr(2);
            }

            if (query.indexOf('?') !== 0) {
                query = `?${query}`;
            }
        }

        return query;
    }
}
