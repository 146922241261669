import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/et';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



// https://jaxenter.com/reactive-programming-http-and-angular-2-124560.html

// ng build --aot=true --environment=prod --output-hashing=all --sourcemaps=false
// --extract-css=true --named-chunks=true --stats-json

// ng build --prod --build-optimizer
// npm update -D && npm update -S

// creating symlinks in powershell for projects
// cmd /c mklink /d enums C:\Repos\voogtv-client-ng\src\app\core\data\enums
// also add "preserveSymlinks": true, to angular.json
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
