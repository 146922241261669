import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtCommand, IExtCommand } from 'app/core/data/ext-command/ext-command.model';

import { ToasterService } from '../../services/toaster.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ExtCommandDataService extends BaseListableDataService<ExtCommand, IExtCommand, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'ext-command', ObjectType.EXT_COMMAND, ['output', 'source', 'output-group', 'tuner'], []);

  }

  protected buildLookupItems() {
    super.buildLookupItems();
    // this.tvEventLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.TvEvent}`).pipe(share());
    // this.tvEventShowLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.TvEventShow}`).pipe(share());
  }

  // public tvEventLookups$: Observable<ILookup[]>;
  // public tvEventShowLookups$: Observable<ILookup[]>;
}
