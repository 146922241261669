import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClientDataService } from '../data/client/client-data.service';
import { Client } from '../data/client/client.model';
import { Observable } from 'rxjs';

@Injectable()
export class ClientResolver  {
    constructor(private dataService: ClientDataService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Client> | Client {
        if (this.dataService.sessionClient) {
            return this.dataService.sessionClient;
        } else {
            return this.dataService.sessionGet();
        }
    }
}
