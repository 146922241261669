import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import {
    MediaVersionStatusFlow,
    MediaVersionStatusTechProcess,
    MediaVersionStatusTechUpload,
    Status,
    StatusSystem,
    FileType,
    ObjectType,
} from '../enums/enums';
import { FileMy, IFileMy } from '../file/file.model';
import { ILookupRestrict } from '../lookup-restrict.model';
import { ILookup } from '../lookup.model';
import { IRating } from '../rating/rating.model';
import { UploadItem } from '../upload-item/upload-item.model';
import * as Enumerable from 'linq-es2015';

import { IMediaVersionTagRelation, MediaVersionTagRelation } from './media-version-tag-relation.model';
import {
    IMediaVersionTargetAudienceRelation,
    MediaVersionTargetAudienceRelation,
} from './media-version-target-audience-relation.model';
import { MyJob } from '../background-job/background-job.model';




export interface IMediaVersionOptionSet {
    play_duration: number;
}

export interface IMediaVersion extends IBaseNameModel {
    hash: string;
    media_id: number;
    rating_id: number;
    rating: IRating;
    mediaVersionTags: IMediaVersionTagRelation[];
    mediaVersionTargetAudiences: IMediaVersionTargetAudienceRelation[];
    valid_from: string;
    valid_to: string;
    status: Status;

    files: IFileMy[];

    status_tech_upload: MediaVersionStatusTechUpload;
    status_tech_process: MediaVersionStatusTechProcess;
    upload_progress_current: number;
    upload_progress_total: number;
    process_progress_current: number;
    process_progress_total: number;
    status_flow: MediaVersionStatusFlow;

    option_set: IMediaVersionOptionSet;
}

export interface IMediaVersionInitUpload {
    media_id: number;
    name: string;
    // mime_type: string;
    rating_id: number;
    status: Status;
    status_tech_upload: MediaVersionStatusTechUpload;
}

export class MediaVersion extends BaseNameModel<IMediaVersion> {
    constructor(dtm: IMediaVersion = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            hash: '',
            name: '',
            media_id: 0,
            rating_id: 0,
            rating: null,
            mediaVersionTags: [],
            mediaVersionTargetAudiences: [],
            valid_from: '',
            valid_to: '',

            status: Status.Active,
            status_system: StatusSystem.Default,

            files: [],

            status_tech_upload: MediaVersionStatusTechUpload.Undefined,
            status_tech_process: MediaVersionStatusTechProcess.Undefined,
            upload_progress_current: 0,
            upload_progress_total: 0,
            process_progress_current: 0,
            process_progress_total: 0,

            status_flow: MediaVersionStatusFlow.Undefined,
            option_set: {
                play_duration: null
            },
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get hash() {
        return this.dtm.hash;
    }
    public set hash(value) {
        this.dtm.hash = value;
    }


    public get tag_lookups() { return this.getLookupArray(() => this.dtm.mediaVersionTags, (x) => this.createLookup(x.tag_id, x.tag?.name)); }
    public set tag_lookups(value) { this.setLookupArray(() => this.dtm.mediaVersionTags, (x) => this.dtm.mediaVersionTags = x, value, (item) => MediaVersionTagRelation.getInstance(this.id, item.id).dtm); }

    public get audience_lookups() { return this.getLookupArray(() => this.dtm.mediaVersionTargetAudiences, (x) => this.createRestrictLookup(x.target_audience_id, x.targetAudience?.name, x.is_restricted === 0)); }
    public set audience_lookups(value) { this.setLookupArray(() => this.dtm.mediaVersionTargetAudiences, (x) => this.dtm.mediaVersionTargetAudiences = x, value, (item) => MediaVersionTargetAudienceRelation.getInstance(this.id, item.id, item.restrict).dtm); }

    public get optionPlayDuration() {
        return this.dtm.option_set ? this.dtm.option_set.play_duration : null;
    }
    public set optionPlayDuration(value) {
        if (!this.dtm.option_set  || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as IMediaVersionOptionSet;
        }
        this.dtm.option_set.play_duration = value;
    }

    public get status_tech_upload() {
        return this.dtm.status_tech_upload;
    }
    public set status_tech_upload(value) {
        this.dtm.status_tech_upload = value;
    }

    public get status_tech_process() {
        return this.dtm.status_tech_process;
    }
    public set status_tech_process(value) {
        this.dtm.status_tech_process = value;
    }

    public get statusTechProcessName() {
        return MediaVersionStatusTechProcess[this.dtm.status_tech_process]
            ? MediaVersionStatusTechProcess[this.dtm.status_tech_process]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get upload_progress_current() {
        return this.dtm.upload_progress_current;
    }
    public set upload_progress_current(value) {
        this.dtm.upload_progress_current = value;
    }

    public get upload_progress_total() {
        return this.dtm.upload_progress_total;
    }
    public set upload_progress_total(value) {
        this.dtm.upload_progress_total = value;
    }

    public get process_progress_current() {
        return this.dtm.process_progress_current;
    }
    public set process_progress_current(value) {
        this.dtm.process_progress_current = value;
    }

    public get process_progress_total() {
        return this.dtm.process_progress_total;
    }
    public set process_progress_total(value) {
        this.dtm.process_progress_total = value;
    }

    public get processProgressDisplayName() {
        if (this.process_progress_total > 1) {
            return `${this.statusTechProcessName} ${this.process_progress_current}/${this.process_progress_total}`;
        }
        return `${this.statusTechProcessName}...`;
    }
    public get resolution() {
        // if (!this.fileWidth || !this.fileHeight) {
        //     return 'unprocessed';
        // }
        return `${this.fileWidth}x${this.fileHeight}`;
    }
    public get uploadItem() {
        return this._uploadItem;
    }
    // public file: File = null;
    // public blobProgresses: IBlobProgress[] = [];

    public get isCreated() {
        return this.id > 0;
    }

    public get isVideo() {
        return this.originalFile && this.originalFile.mime_type && this.originalFile.isVideo;
    }

    public get isImage() {
        return this.originalFile && this.originalFile.mime_type && this.originalFile.isImage;
    }

    public get mime_icon() {
        if (this.isImage) {
            return 'image';
        } else if (this.isVideo) {
            return 'movie';
        }
        return 'warning';
    }

    public get isProcessing() {
        return this.status_tech_process === MediaVersionStatusTechProcess.Processing; // this.upload_status == UploadStatus.Processing;
    }

    public get isProcessed() {
        return this.status_tech_process === MediaVersionStatusTechProcess.Processed;
    }

    public get isProcessError() {
        return this.status_tech_process === MediaVersionStatusTechProcess.ProcessError;
    }
    public get isUploadingCanceled() {
        return !this.isUploadingCalled
            && (this.status_tech_upload === MediaVersionStatusTechUpload.Uploading
                || this.status_tech_upload === MediaVersionStatusTechUpload.UploadInit);
    }

    public get isUploadInit() {
        return this.isUploadingCalled && this.status_tech_upload === MediaVersionStatusTechUpload.UploadInit;
    }

    public get isUploading() {
        return this.isUploadingCalled
            && this.status_tech_upload === MediaVersionStatusTechUpload.Uploading;
        // this.upload_status == UploadStatus.Uploading;
    }
    public get isUploaded() {
        return this.status_tech_upload === MediaVersionStatusTechUpload.Uploaded;
    }
    public get isUploadError() {
        return this.status_tech_upload === MediaVersionStatusTechUpload.UploadError;
    }

    public get isSuccess() {
        return this.isProcessed && this.isUploaded;
    }

    public get isProcess() {
        return this.isProcessing || this.isProcessed || this.isProcessError;
    }

    public get isUpload() {
        return this.isUploadInit || this.isUploading || this.isUploaded || this.isUploadError || this.isUploadingCanceled;
    }

    public get isError() {
        return this.isUploadError
            || this.isProcessError;
    }

    public get status_tech_upload_name() {
        if (this.isUploadingCanceled) {
            return 'Upload Canceled';
        }
        return MediaVersionStatusTechUpload[this.dtm.status_tech_upload]
            ? MediaVersionStatusTechUpload[this.dtm.status_tech_upload]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }
    public get status_tech_process_name() {
        return MediaVersionStatusTechProcess[this.dtm.status_tech_process]
            ? MediaVersionStatusTechProcess[this.dtm.status_tech_process]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get status_tech_name() {
        if (this.dtm.status_tech_process === MediaVersionStatusTechProcess.Undefined) {
            return this.status_tech_upload_name;
        }
        return this.status_tech_process_name;
    }

    public get isPortrait() {
        return this.originalFile && this.originalFile.width < this.originalFile.height;
    }

    public get isLandscape() {
        return this.originalFile && this.originalFile.width > this.originalFile.height;
    }

    public get isSquare() {
        return this.originalFile && this.originalFile.width === this.originalFile.height;
    }

    private _ratingLookup: ILookup;

    private _tagLookups: ILookup[] = null;

    public files: FileMy[] = [];
    public jobs: MyJob[] = [];
    // public jobsSub: Subscription = null;

    public originalFile: FileMy;
    public thumbnailFile: FileMy;

    public hasThumbanils: boolean;

    public mimeType: string;
    public fileWidth: number;
    public fileHeight: number;

    public _uploadItem: UploadItem = null;

    public isUploadingCalled = false;

    public static updateList(list: MediaVersion[], dtms: IMediaVersion[]) {

        const ids = dtms ? Enumerable.AsEnumerable(dtms).Select((x) => x.id).ToArray() : [];
        const removes = Enumerable.AsEnumerable(list).Where((x) => ids.indexOf(x.id) === -1).ToArray();
        removes.forEach((remove) => {
            list.splice(list.indexOf(remove), 1);
        });
        if (dtms) {
            dtms.forEach((value) => {
                const update = Enumerable.AsEnumerable(list).FirstOrDefault((x) => x.id === value.id);
                if (update) {
                    update.update(value);
                } else {
                    list.push(new MediaVersion(value));
                }
            });
        }
    }

    public update(dtm: IMediaVersion, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this._ratingLookup = null;
        this._tagLookups = null;

        if (this.uploadItem) {
            this.uploadItem.parentID = this.dtm.id;
        }

        this.updateProcessData(dtm);

    }

    public cleanForSave(dtm: IMediaVersion, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        // these properties are updated by external services or with batch request, don't overwrite them
        delete dtm.files;
        delete dtm.process_progress_current;
        delete dtm.process_progress_total;
        delete dtm.upload_progress_current;
        delete dtm.upload_progress_total;
        delete dtm.status_tech_process;
        delete dtm.status_tech_upload;

        delete dtm.rating;

        this.cleanDTMs(MediaVersionTagRelation, dtm.mediaVersionTags, saver);
        this.cleanDTMs(MediaVersionTargetAudienceRelation, dtm.mediaVersionTargetAudiences, saver);

    }

    public updateProcessData(dtm: IMediaVersion) {

        this.status_tech_process = dtm.status_tech_process;

        this.process_progress_current = dtm.process_progress_current;
        this.process_progress_total = dtm.process_progress_total;

        if (!dtm.files) {
            dtm.files = [];
        }

        this.files = Enumerable.AsEnumerable(dtm.files).Select((x) => new FileMy(x)).ToArray();
        this.originalFile = Enumerable.AsEnumerable(this.files).FirstOrDefault((x) => x.type === FileType.Original);
        this.thumbnailFile = Enumerable.AsEnumerable(this.files).FirstOrDefault((x) => x.type === FileType.Thumbnail);
        const tumbsCount = Enumerable.AsEnumerable(this.files).Count((x) => x.type === FileType.Thumbnail);
        this.hasThumbanils = tumbsCount > 0 ? true : false;
        if (this.originalFile) {
            this.mimeType = this.originalFile.mime_type;
            this.fileWidth = this.originalFile.width;
            this.fileHeight = this.originalFile.height;
        }
    }

    public updateJobs(jobs: MyJob[]) {
        this.jobs.length = 0;

        jobs.forEach((job) => {
            this.jobs.push(job);
        });

        // const ids = Enumerable.AsEnumerable(jobs).Select((x) => x.id).ToArray();
        // const removes = Enumerable.AsEnumerable(this.jobs).Where((x) => ids.indexOf(x.id) === -1).ToArray();
        // removes.forEach((remove) => {
        //     this.jobs.splice(this.jobs.indexOf(remove), 1);
        // });

        // jobs.forEach((job) => {
        //     const match = Enumerable.AsEnumerable(this.jobs).FirstOrDefault((x) => x.id === job.id);
        //     if (match) {
        //         match.update(job.dtm);
        //     } else {
        //         this.jobs.push(job);
        //     }
        // });
    }
    public setUploadItem(uploadItem: UploadItem) {
        uploadItem.parentID = this.id;
        this._uploadItem = uploadItem;
    }

}
