import * as moment from 'moment';
import { IBaseCreationModel, BaseCreationModel } from '../base-creation.model';
import { ITimeRangeRelation, TimeRangeRelation } from '../time-range/time-range-relation.model';
import * as Enumerable from 'linq-es2015';
import { ObjectType } from '../enums/enums';
import { PropertyUpdateMode } from '../base.model';

export interface IBookingSchedule extends IBaseCreationModel {
    campaign_id: number;
    valid_from: string;
    valid_to: string;
    timerangeRelations: ITimeRangeRelation[];
}

export class BookingSchedule extends BaseCreationModel<IBookingSchedule> {
    constructor(dtm: IBookingSchedule = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            campaign_id: 0,
            valid_from: null,
            valid_to: null,
            timerangeRelations: [],

            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    private dateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    public get campaignID() {
        return this.dtm.campaign_id;
    }
    public set campaignID(value) {
        this.dtm.campaign_id = value;
    }

    public get validFrom() {
        let value = this.dtm.valid_from;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set validFrom(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_from = value;
    }

    public get validTo() {
        let value = this.dtm.valid_to;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set validTo(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_to = value;
    }

    public get timeRangeRelations() { return this.getModelArray(() => this.dtm.timerangeRelations, (x) => new TimeRangeRelation(x), PropertyUpdateMode.Clear); }
    public set timeRangeRelations(value) { this.setModelArray(() => this.dtm.timerangeRelations, (x) => this.dtm.timerangeRelations = x, value, PropertyUpdateMode.Clear); }


    public update(dtm: IBookingSchedule, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        // remove if name changed and fixed
        if (!dtm.timerangeRelations) {
            dtm.timerangeRelations = [];
        }

    }


    protected clearLocalProps() {
        super.clearLocalProps();

    }

    public cleanForSave(dtm: IBookingSchedule, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTMs(TimeRangeRelation, dtm.timerangeRelations, saver);
    }

}
