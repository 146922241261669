import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { RegularEvent } from './regular-event.model';
import { IEventBase, EventBase } from './event.model';
import { share } from 'rxjs/operators';
import { Show } from '../show/show.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class EventDataService<TEvent extends EventBase<Show<any>>> extends BaseListableDataService<TEvent, IEventBase, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'event', ObjectType.EVENT, ['related'], ['related', "shows", 'resource', 'media', 'campaigns']);

  }

  protected buildLookupItems() {
    super.buildLookupItems();
    this.relatedConfBaseLookup$ = this.getPropertySet().pipe(share());
  }

}
