import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { PropertyUpdateMode } from '../base.model';
import { ObjectType } from '../enums/enums';
import { IScreenGroup, ScreenGroup} from '../screen-group/screen-group.model'

export interface ISalesPointScreenGroupRelation extends IBaseRelationModel {
    salespoint_id: number;
    screengroup_id: number;
    screengroup: IScreenGroup;
}

export class SalesPointScreenGroupRelation extends BaseRelationModel <ISalesPointScreenGroupRelation> {
    constructor(dtm: ISalesPointScreenGroupRelation = null) {
        super(dtm || {
            salespoint_id: 0,
            screengroup_id: 0,
            screengroup: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get salesPointID() {
        return this.dtm.salespoint_id;
    }
    public set salesPointID(value) {
        this.dtm.salespoint_id = value;
    }

    public get screenGroupID() {
        return this.dtm.screengroup_id;
    }
    public set screenGroupID(value) {
        this.dtm.screengroup_id = value;
    }


    public get screengroup() { return this.getModelProperty(() => this.dtm.screengroup, (x) => new ScreenGroup(x), PropertyUpdateMode.Update); }
    public set screengroup(value) { this.setModelProperty(() => this.dtm.screengroup, (x) => this.dtm.screengroup = x, value, PropertyUpdateMode.Update); }

    public update(dtm: ISalesPointScreenGroupRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.screengroup, (x) => new ScreenGroup(x), (x) => this.screengroup = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: ISalesPointScreenGroupRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.screengroup;
    }

    public static getInstance(salesPointID: number, screeGroupID: number){
        const empty = new SalesPointScreenGroupRelation();
        empty.salesPointID = salesPointID;
        empty.screenGroupID = screeGroupID;
        return empty;
    }
}
