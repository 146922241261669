// import 'moment/locale/et';

// import { registerLocaleData } from '@angular/common';
// import localeEt from '@angular/common/locales/global/et';
import { Injectable } from '@angular/core';
// import * as moment from 'moment';

// registerLocaleData(localeEt);

@Injectable()
export class LocaleSettingsService {

    constructor() {
        // moment.locale('et');
    }

    public get locale() {
        let language = window.navigator.language;
        console.log(language);
        language = 'et'; // 'et-EE'; // TODO get from client info

        return language;
    }

    // public get localeData() {
    //     if (this.locale.startsWith('et')) {
    //         return this.LOCALE_DATA_ET;
    //     } else {
    //         return this.LOCALE_DATA_EN;
    //     }
    // }

    // public get localeDateFormat() {
    //     if (this.locale.startsWith('et')) {
    //         return this.LOCALE_DATE_FORMAT_ET;
    //     } else {
    //         return this.LOCALE_DATE_FORMAT_EN;
    //     }
    // }

    // private LOCALE_DATE_FORMAT_EN = 'mm/dd/yy';
    // private LOCALE_DATA_EN = {
    //     firstDayOfWeek: 0,
    //     dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    //     dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    //     dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    //     monthNames: ['January', 'February', 'March', 'April',
    //         'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //     monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // };

    // private LOCALE_DATE_FORMAT_ET = 'dd.mm.yy';
    // private LOCALE_DATA_ET = {
    //     firstDayOfWeek: 1,
    //     dayNames: ['pühapäev', 'esmaspäev', 'teisipäev', 'kolmapäev', 'neljapäev', 'reede', 'laupäev'],
    //     dayNamesShort: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
    //     dayNamesMin: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
    //     monthNames: ['jaanuar', 'veebruar', 'märts', 'aprill', 'mai',
    //         'juuni', 'juuli', 'august', 'september', 'oktoober', 'november', 'detsember'],
    //     monthNamesShort: ['jaan', 'veebr', 'märts', 'apr', 'mai', 'juuni', 'juuli', 'aug', 'sept', 'okt', 'nov', 'dets']
    // };
}
