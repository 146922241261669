import { getPropertyName } from 'app/shared/extends/getPropertyName';
import * as Enumerable from 'linq-es2015';
import { BaseIdModel, IBaseIdModel } from './base-id.model';
import { IBaseRelationModel } from './base-relation.model';
import { ObjectType } from './enums/enums';
import { ILookupRestrict } from './lookup-restrict.model';
import { ILookup } from './lookup.model';

export interface IBaseModel {
    // [key: string]: any;
}

export enum PropertyUpdateMode { Clear, Update }


export abstract class BaseModel<IT extends IBaseModel> {
    constructor(dtm: IT) {

        if (this.dtm) {
            this.dtm = this.assign(this.dtm, dtm);
        } else {
            this.dtm = dtm;
        }
    }

    public dtm: IT;

    private setDtm(dtm: IT, forceReplaceDtm = false) {
        if (this.dtm && !forceReplaceDtm) {
            this.dtm = this.assign(this.dtm, dtm);
        } else {
            this.dtm = dtm;
        }

        this.clearLocalProps();
    }

    public assign(assignTo: any, assignFrom: any, propKey: string = ''): any {
        // Object.assign(assignTo, assignFrom);

        // copy properies from one object to another,
        // if property is and array then try to updated existing array object based on id property

        // if property is an object then rerun this function on that object

        if (assignTo == null || assignFrom == null) {
            return assignTo = assignFrom;
        } else if (Array.isArray(assignTo) || Array.isArray(assignFrom)) {
            if (Array.isArray(assignTo) && Array.isArray(assignFrom)) {
                assignTo = this.assignArrays(assignTo, assignFrom, propKey);
                return assignTo;
            } else {
                return assignTo = assignFrom;
            }
        } else if (assignTo === Object(assignTo) || assignFrom === Object(assignFrom)) {

            if (assignTo === Object(assignTo) && assignFrom === Object(assignFrom)) {
                Object.keys(assignFrom).map((key, index) => {

                    const fromProp = assignFrom[key];

                    const toProp = assignTo[key];

                    assignTo[key] = this.assign(toProp, fromProp, key);
                });
                return assignTo;
            } else {
                return assignTo = assignFrom;
            }
        } else {
            return assignTo = assignFrom;
        }
    }

    private assignArrays(toProp: any[], fromProp: any[], propKey: string = '') {

        if (toProp.length && fromProp.length
            && toProp[0].hasOwnProperty('id')
            && fromProp[0].hasOwnProperty('id')) {

            const toPropCopy = toProp.slice();
            toProp = [];
            fromProp.forEach((fromItem: any) => {
                const match = Enumerable.AsEnumerable(toPropCopy).SingleOrDefault((x) => x && fromItem && x.id === fromItem.id);
                if (match) {
                    this.assign(match, fromItem);
                    toProp.push(match);
                } else {
                    toProp.push(fromItem);
                }
            });
        } else {
            if (!toProp) {
                toProp = [];
            }
            toProp.length = 0;
            fromProp.forEach((x) => {
                toProp.push(x);
            });
        }
        return toProp;
    }

    private _localModelUpdateProperties: { [name: string]: any; } = {};
    private _localModelClearProperties: { [name: string]: any; } = {};
    private _localLookupUpdateProperties: { [name: string]: any; } = {};
    private _localLookupClearProperties: { [name: string]: any; } = {};

    protected clearLocalProps() {
        this._localModelClearProperties = {};
        this._localLookupClearProperties = {};
    }

    protected getModelArray<Tdtm, Tmodel extends BaseModel<Tdtm>>(
        dtmPropertyGet: () => Tdtm[], createInstance: (dtm: Tdtm) => Tmodel,
        propertyUpdateMode: PropertyUpdateMode = PropertyUpdateMode.Clear): Tmodel[] {
        return this.getArrayProperty(dtmPropertyGet, createInstance, this._localModelUpdateProperties, this._localModelClearProperties, propertyUpdateMode);
    }

    protected getLookupArray<Tdtm, Tlookup extends ILookup>(
        dtmPropertyGet: () => Tdtm[], createInstance: (dtm: Tdtm) => Tlookup): Tlookup[] {
        return this.getArrayProperty(dtmPropertyGet, createInstance, this._localLookupUpdateProperties, this._localLookupClearProperties, PropertyUpdateMode.Clear);
    }

    private getArrayProperty<Tdtm, Titem>(
        dtmPropertyGet: () => Tdtm[], createInstance: (dtm: Tdtm) => Titem,
        localUpdateProperties: { [name: string]: any; },
        localClearProperties: { [name: string]: any; },
        propertyUpdateMode: PropertyUpdateMode): Titem[] {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        const dtm = dtmPropertyGet();

        let localProperty = propertyUpdateMode === PropertyUpdateMode.Update ? localUpdateProperties[propertyName] : localClearProperties[propertyName];

        if ((!localProperty) && dtm !== null && dtm !== undefined) {
            localProperty = [];
            if(Array.isArray(dtm)){
                dtm.forEach((x) => {
                    localProperty.push(createInstance(x));
                });
            }
            if (propertyUpdateMode === PropertyUpdateMode.Update) {
                localUpdateProperties[propertyName] = localProperty;
            } else {
                localClearProperties[propertyName] = localProperty;
            }

        }
        return localProperty;
    }



    protected setModelArray<Tdtm, Tmodel extends BaseModel<Tdtm>>(
        dtmPropertyGet: () => Tdtm[], dtmPropertySet: (value: Tdtm[]) => void, values: Tmodel[],
        propertyUpdateMode: PropertyUpdateMode = PropertyUpdateMode.Clear, defaultValue: Tdtm[] = null) {
        this.setArrayProperty(dtmPropertyGet, dtmPropertySet, values, (item) => {
            if (item.dtm !== null && item.dtm !== undefined) {
                return item.dtm;
            }
            return null;
        }, this._localModelUpdateProperties, this._localModelClearProperties, propertyUpdateMode, defaultValue);
    }

    protected setLookupArray<Tdtm, Tlookup extends ILookup>(
        dtmPropertyGet: () => Tdtm[], dtmPropertySet: (value: Tdtm[]) => void, values: Tlookup[],
        dtmPropertyTransform: (item: Tlookup) => Tdtm, defaultValue: Tdtm[] = null) {
        this.setArrayProperty(dtmPropertyGet, dtmPropertySet, values, dtmPropertyTransform, this._localModelUpdateProperties, this._localModelClearProperties, PropertyUpdateMode.Clear, defaultValue);
    }

    private setArrayProperty<Tdtm, Titem>(
        dtmPropertyGet: () => Tdtm[],
        dtmPropertySet: (value: Tdtm[]) => void, values: Titem[],
        dtmPropertyTransform: (item: Titem) => Tdtm,
        localUpdateProperties: { [name: string]: any; },
        localClearProperties: { [name: string]: any; },
        propertyUpdateMode: PropertyUpdateMode, defaultValue: Tdtm[]) {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        let dtm = dtmPropertyGet();



        if (propertyUpdateMode === PropertyUpdateMode.Update) {
            localUpdateProperties[propertyName] = values;
        } else {
            localClearProperties[propertyName] = values;
        }

        if (values !== null && values !== undefined) {

            // make sure it's a array
            if (!Array.isArray(values)) {
                values = [values];
            }

            // make sure dtm value is empty array
            if (!dtm) {
                dtm = [];
                dtmPropertySet(dtm);
            }else  if (!Array.isArray(dtm)) {
                dtm = [];
                dtmPropertySet(dtm);
            }
            else {
                dtm.length = 0;
            }


            values.forEach((x) => {
                const pushModel = dtmPropertyTransform(x);
                if (pushModel) {
                    dtm.push(pushModel);
                }
            });
        } else {
            dtmPropertySet(defaultValue);
        }
    }


    protected updateModelArrayProperty<Tdtm extends IBaseIdModel, Tmodel extends BaseIdModel<Tdtm>>(
        dtmPropertyGet: () => Tdtm[],
        createInstance: (dtm: Tdtm) => Tmodel,
        propertySet: (value: Tmodel[]) => void, forceReplaceDtm: boolean) {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        let dtm = dtmPropertyGet();

        if (dtm !== null && dtm !== undefined) {
            if (!Array.isArray(dtm)) {
                dtm = [dtm];
            }

            const localProperty = this._localModelUpdateProperties[propertyName] as Tmodel[];

            if (localProperty) {

                const dmIDs = Enumerable.AsEnumerable(dtm).Select((x) => x.id).ToArray();

                const removes = Enumerable.AsEnumerable(localProperty).Where((x) => dmIDs.indexOf(x.id) === -1).ToArray();
                removes.forEach((remove) => {
                    localProperty.splice(localProperty.indexOf(remove), 1);
                });

                dtm.forEach((value) => {
                    const update = Enumerable.AsEnumerable(localProperty).FirstOrDefault((x) => x.id === value.id);
                    if (update) {
                        update.update(value, forceReplaceDtm);
                    } else {
                        localProperty.push(createInstance(value));
                    }
                });

            } else {
                const newItems: Tmodel[] = [];
                dtm.forEach((value) => {
                    newItems.push(createInstance(value));
                });

                propertySet(newItems);
            }
        } else {
            propertySet(null);
        }
    }

    protected updateModelArrayPropertyWitoutID<Tdtm, Tmodel>(
        dtmPropertyGet: () => Tdtm[],
        createInstance: (dtm: Tdtm) => Tmodel,
        propertySet: (value: Tmodel[]) => void, forceReplaceDtm: boolean) {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        let dtm = dtmPropertyGet();

        if (dtm !== null && dtm !== undefined) {
            if (!Array.isArray(dtm)) {
                dtm = [dtm];
            }

            const localProperty = this._localModelUpdateProperties[propertyName] as Tmodel[];

            if (localProperty) {

                localProperty.length = 0;

                dtm.forEach((value) => {
                    localProperty.push(createInstance(value));
                });
            } else {
                const newItems: Tmodel[] = [];
                dtm.forEach((value) => {
                    newItems.push(createInstance(value));
                });

                propertySet(newItems);
            }
        } else {
            propertySet(null);
        }
    }

    // protected clearArrayProperty(propertyGet: () => any[]) {
    //     const propertyName = getPropertyName(propertyGet.toString());
    //     this._localClearProperties[propertyName] = null;
    //     this._localUpdateProperties[propertyName] = null;
    // }

    protected getModelProperty<Tdtm, Tmodel>(
        dtmPropertyGet: () => Tdtm, createInstance: (dtm: Tdtm) => Tmodel,
        propertyUpdateMode: PropertyUpdateMode = PropertyUpdateMode.Clear): Tmodel {
        return this.getProperty(dtmPropertyGet, createInstance, (x) => true, this._localModelUpdateProperties, this._localModelClearProperties, propertyUpdateMode);
    }

    protected getLookupProperty<Tdtm, Tlookup extends ILookup>(
        dtmPropertyGet: () => Tdtm, createInstance: (dtm: Tdtm) => Tlookup): Tlookup {
        return this.getProperty(dtmPropertyGet, createInstance, (x) => x && x.id >= 0, this._localLookupUpdateProperties, this._localLookupClearProperties, PropertyUpdateMode.Clear);
    }

    private getProperty<Tdtm, Titem>(
        dtmPropertyGet: () => Tdtm,
        createInstance: (dtm: Tdtm) => Titem,
        propValidCheck: (dtm: Titem) => boolean,
        localUpdateProperties: { [name: string]: any; },
        localClearProperties: { [name: string]: any; },
        propertyUpdateMode: PropertyUpdateMode): Titem {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        const dtm = dtmPropertyGet();

        let localProperty = propertyUpdateMode === PropertyUpdateMode.Update ? localUpdateProperties[propertyName] as Titem : localClearProperties[propertyName] as Titem;

        if (!localProperty && dtm !== null && dtm !== undefined) { // dtm: 0 is still valid
            localProperty = createInstance(dtm);
            if (propValidCheck(localProperty)) {
                if (propertyUpdateMode === PropertyUpdateMode.Update) {
                    localUpdateProperties[propertyName] = localProperty;
                } else {
                    localClearProperties[propertyName] = localProperty;
                }
            } else {
                localProperty = null;
            }
        }

        return localProperty;
    }

    protected setModelProperty<Tdtm, Tmodel extends BaseModel<Tdtm>>(
        dtmPropertyGet: () => Tdtm,
        dtmPropertySet: (value: Tdtm) => void,
        value: Tmodel,
        propertyUpdateMode: PropertyUpdateMode = PropertyUpdateMode.Clear,
        defaultValue: Tdtm = null) {
        this.setProperty(dtmPropertyGet, dtmPropertySet, (item) => {
            if (item.dtm !== null && item.dtm !== undefined) { // dtm: 0 is still valid
                return item.dtm;
            }
            return null;
        }, value, this._localModelUpdateProperties, this._localModelClearProperties, propertyUpdateMode, defaultValue);
    }

    protected setLookupProperty<Tdtm, Tlookup extends ILookup>(
        dtmPropertyGet: () => Tdtm,
        dtmPropertySet: (value: Tdtm) => void,
        value: Tlookup,
        dtmPropertyTransform: (item: Tlookup) => Tdtm,
        defaultValue: Tdtm = null) {
        this.setProperty(dtmPropertyGet, dtmPropertySet, dtmPropertyTransform, value, this._localLookupUpdateProperties, this._localLookupClearProperties, PropertyUpdateMode.Clear, defaultValue);
    }

    private setProperty<Tdtm, Titem>(
        dtmPropertyGet: () => Tdtm,
        dtmPropertySet: (value: Tdtm) => void,
        dtmPropertyTransform: (item: Titem) => Tdtm,
        value: Titem,
        localUpdateProperties: { [name: string]: any; },
        localClearProperties: { [name: string]: any; },
        propertyUpdateMode: PropertyUpdateMode, defaultValue: Tdtm) {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        const dtm = dtmPropertyGet();

        if (value !== null && value !== undefined && Array.isArray(value)) { // value: 0 is still valid
            value = value[0];
        }


        if (propertyUpdateMode === PropertyUpdateMode.Update) {
            localUpdateProperties[propertyName] = value;
        } else {
            localClearProperties[propertyName] = value;
        }
        const pushModel = value !== null && value !== undefined ? dtmPropertyTransform(value) : null;
        if (pushModel) {
            dtmPropertySet(pushModel);
        } else {
            dtmPropertySet(defaultValue);
        }
    }

    protected updateModelProperty<Tdtm, Tmodel extends BaseModel<Tdtm>>(
        dtmPropertyGet: () => Tdtm, createInstance: (dtm: Tdtm) => Tmodel, propertySet: (value: Tmodel) => void, forceReplaceDtm: boolean) {
        const propertyName = getPropertyName(dtmPropertyGet.toString());
        const dtm = dtmPropertyGet();

        const localProperty = this._localModelUpdateProperties[propertyName] as Tmodel;

        if (dtm !== null && dtm !== undefined) {
            if (localProperty) {
                localProperty.update(dtm, forceReplaceDtm);
            } else {
                propertySet(createInstance(dtm));
            }
        } else {
            propertySet(null);
        }
    }




    public update(dtm: IT, forceReplaceDtm = false, fromConstructor = false) {
        this.setDtm(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IT, saver: ObjectType) {
        // throw new Error('Method not overrided.');
    }

    public cleanDTM<Tdtm, Tmodel extends BaseModel<Tdtm>>(createInstance: new () => Tmodel, dtm: Tdtm, saver: ObjectType) {
        if (dtm) {
            new createInstance().cleanForSave(dtm, saver);
        }
    }

    public cleanDTMs<Tdtm, Tmodel extends BaseModel<Tdtm>>(createInstance: new () => Tmodel, dtms: Tdtm[], saver: ObjectType) {
        if (dtms && dtms.length) {
            dtms.forEach((dtm) => {
                this.cleanDTM(createInstance, dtm, saver);
            });
        }
    }

    public createLookup(id: number, name: string): ILookup {
        return { id, name: name || `ID: ${id}` };
    }

    public createRestrictLookup(id: number, name: string, restrict: boolean): ILookupRestrict {
        return { id, name: name || `ID: ${id}`, restrict };
    }
}
