import { BaseModel } from '../base.model';
import * as moment from 'moment';

export enum MyJobState {
    Unknown = 0, Enqueued = 1, Processing = 2, Failed = 3,
    Scheduled = 4, Awaiting = 5, Deleted = 6, Succeeded = 7
}
export enum MyJobType {
    Unknown = 0, FileMeta = 1, FileProperies = 2,
    ResourceOriginal = 3, ResourceThumbnail = 4,
    MediaVersionOriginal = 5, MediaVersionThumbnail = 6,
    MediaVersionConvert = 7, MediaVersionTransposeConvert = 8,
    ResourceProcessStatusChange = 9, MediaVersionProcessStatusChange = 10,
    ResourceMeta = 11, MediaVersionMeta = 12
}
export interface IMyJob {
    id: string;
    state: MyJobState;
    targetID: number;
    type: MyJobType;
    date: string;
}

export class MyJob extends BaseModel<IMyJob> {
    constructor(dtm: IMyJob) {
        super(dtm);
    }

    public get id() {
        return this.dtm.id;
    }

    public get targetID() {
        return this.dtm.targetID;
    }

    public get state() {
        return this.dtm.state;
    }

    public get type() {
        return this.dtm.type;
    }

    public get displayName() {
        return MyJobType[this.type] ? MyJobType[this.type].replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get displayState() {
        return MyJobState[this.state] ? MyJobState[this.state].replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get date() {
        let value = this.dtm.date;
        if (value) {
            value = moment(value).format();
        }
        return value;
    }

    public get canRequeue() {
        return this.state === MyJobState.Failed || this.state === MyJobState.Scheduled;
    }

    public cleanUp = false;

    public static GetInstances<T, IT>(c: new (dtm: IT) => T, dtms: IT[]) {
        const items: T[] = [];
        dtms.forEach((dtm) => items.push(new c(dtm)));
        return items;
    }

    public update(dtm: IMyJob, forceReplaceDtm = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public isForMediaVersion() {
        if (this.type === MyJobType.MediaVersionConvert
            || this.type === MyJobType.MediaVersionOriginal
            || this.type === MyJobType.MediaVersionThumbnail
            || this.type === MyJobType.MediaVersionTransposeConvert
            || this.type === MyJobType.MediaVersionProcessStatusChange
            || this.type === MyJobType.MediaVersionMeta
        ) {
            return true;
        }
        return false;
    }

    public isForResource() {
        if (this.type === MyJobType.ResourceOriginal
            || this.type === MyJobType.ResourceProcessStatusChange
            || this.type === MyJobType.ResourceThumbnail
            || this.type === MyJobType.ResourceMeta
        ) {
            return true;
        }
        return false;
    }

}
