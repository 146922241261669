import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IExtContentSource, ExtContentSource } from '../ext-content-source/ext-content-source.model';
import { ObjectType } from '../enums/enums';

export interface IDeviceExtContentSourceRelation extends IBaseRelationModel {
    ext_content_source_id: number;
    device_id: number;
    // id: number;
    channel_number: number;
    extContentSource: IExtContentSource;
}

export class DeviceExtContentSourceRelation extends BaseRelationModel <IDeviceExtContentSourceRelation> {
    constructor(dtm: IDeviceExtContentSourceRelation = null) {
        super(dtm || {
            // id: null,
            ext_content_source_id: 0,
            device_id: 0,
            created_at: null,
            channel_number: null,
            created_by: null,
            extContentSource: null
        });
        this.update(this.dtm, true, true);
    }

    // public get id() {
    //     return this.dtm.id;
    // }
    // public set id(value) {
    //     this.dtm.id = value;
    // }

    public get channelNumber() {
        return this.dtm.channel_number;
    }
    public set channelNumber(value) {
        this.dtm.channel_number = value;
    }

    public get ext_content_source_id() {
        return this.dtm.ext_content_source_id;
    }
    public set ext_content_source_id(value) {
        this.dtm.ext_content_source_id = value;
    }

    public get device_id() {
        return this.dtm.device_id;
    }
    public set device_id(value) {
        this.dtm.device_id = value;
    }

    public get extContentSourceName() {
        if (this.extContentSource) {
            return this.extContentSource.name;
        }
        return 'Unknown';
    }

    public extContentSource: ExtContentSource;

    public update(dtm: IDeviceExtContentSourceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        if (dtm.extContentSource) {
            if (this.extContentSource) {
                this.extContentSource.update(dtm.extContentSource, forceReplaceDtm);
            } else {
                this.extContentSource = new ExtContentSource(dtm.extContentSource);
            }
        } else {
            this.extContentSource = null;
        }
    }

    public cleanForSave(dtm: IDeviceExtContentSourceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.extContentSource;

    }

    public static getInstance(device_id: number, ext_content_source_id: number, channelNumber: number){
        const empty = new DeviceExtContentSourceRelation();
        empty.device_id = device_id;
        empty.ext_content_source_id = ext_content_source_id;
        empty.channelNumber = channelNumber;
        return empty;
    }
}
