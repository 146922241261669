import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService, IListData } from '../base-data.service';
import * as Enumerable from 'linq-es2015';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { Client, IClient } from './client.model';
import { ILanguageMy } from '../language/language.model';
import { ToasterService } from '../../services/toaster.service';

@Injectable()
export class ClientDataService extends BaseDataService {
  public sessionClient: Client;
  constructor(private http: HttpClient, toastr: ToasterService) {
    super(toastr);

    // this.sessionClient$ = this.sessionGet();
  }

  public get(id: number) {
    return this.getData(id).pipe(map((x) => {
      return new Client(x);
    }), tap((value) => {
      // comment
    }), share());
  }

  // public sessionClient$: Observable<Client>;
  public sessionGet() {

    // TODO: remove if client data already includes system_languages
    const joined = combineLatest([this.sessionGetData(), this.getLanguages()]);

    return joined.pipe(map((res) => {

      if (!res[0].system_languages) {
        // set form system languages
        res[0].system_languages = Enumerable.AsEnumerable(res[1].data).Where((x) => !x.related_conf_base_id).ToArray();
      }
      return new Client(res[0]);
    }), tap((value) => {
      this.sessionClient = value;
    }), share());
  }

  private getData(id: number): Observable<IClient> {
    return this.http.get<IClient>(`${this.apiBaseUrl}/client/${id}`, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  private getLanguages(): Observable<IListData<ILanguageMy>> {
    return this.http.get<IListData<ILanguageMy>>(`${this.apiBaseUrl}/language`, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  private sessionGetData(): Observable<IClient> {
    return this.http.get<IClient>(`${this.apiBaseUrl}/session/client`, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

}
