import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Campaign, ICampaign } from '../campaign/campaign.model';
import { Media, IMedia } from './media.model';
import { ObjectType } from '../enums/enums';

export interface IMediaCampaignRelation extends IBaseRelationModel {
    media_id: number;
    campaign_id: number;
    campaign: ICampaign;
}

export class MediaCampaignRelation extends BaseRelationModel <IMediaCampaignRelation> {
    constructor(dtm: IMediaCampaignRelation = null) {
        super(dtm || {
            media_id: 0,
            campaign_id: 0,
            campaign: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get campaign_id() {
        return this.dtm.campaign_id;
    }
    public set campaign_id(value) {
        this.dtm.campaign_id = value;
    }

    public campaign: Campaign;

    public update(dtm: IMediaCampaignRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        if (dtm.campaign) {
            if (this.campaign) {
                this.campaign.update(dtm.campaign, forceReplaceDtm);
            } else {
                this.campaign = new Campaign(dtm.campaign);
            }
        } else {
            this.campaign = null;
        }
    }

    public cleanForSave(dtm: IMediaCampaignRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.campaign;
    }

    public static getInstance(media_id: number, campaign_id: number){
        const empty = new MediaCampaignRelation();
        empty.media_id = media_id;
        empty.campaign_id = campaign_id;
        return empty;
    }
}
