
import { SpotAppSourceTargetType, ObjectType, SpotAppSourcePerRangePlayTarget } from '../enums/enums';
import { ILookup } from '../lookup.model';
import { MediaType } from '../media-type/media-type.model';
import { Media } from '../media/media.model';
import { Tag } from '../tag/tag.model';
import { BaseCreationModel, IBaseCreationModel } from '../base-creation.model';
import { ISpotApp } from './spot-app.model';
import { IBaseNameModel } from '../base-name.model';

export interface ISpotAppSourceOptionSet {
    per_range_play_count: number;
    per_range_play_time: number;
    per_range_play_target: SpotAppSourcePerRangePlayTarget;
}

export interface ISpotAppSource extends IBaseCreationModel {
    target_type: SpotAppSourceTargetType;
    target_id: number;
    // spot_id: number;
    spot_app_id: number;
    related: IBaseNameModel;
    option_set: ISpotAppSourceOptionSet;
    spotApp: ISpotApp;
}

export class SpotAppSource extends BaseCreationModel<ISpotAppSource> {
    constructor(dtm: ISpotAppSource = null) {
        super(dtm || {
            id: 0,
            target_type: SpotAppSourceTargetType.Unknown,
            target_id: 0,
            // spot_id: 0,
            spot_app_id: 0,

            related: null,
            option_set: {
                per_range_play_count: null,
                per_range_play_time: null,
                per_range_play_target: SpotAppSourcePerRangePlayTarget.PerPool
            },
            client_id: 0,
            spotApp: null,

            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get optionPerRangePlayCount() {
        return this.dtm.option_set ? this.dtm.option_set.per_range_play_count : null;
    }
    public set optionPerRangePlayCount(value) {
        if (!this.dtm.option_set || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as ISpotAppSourceOptionSet;
        }
        this.dtm.option_set.per_range_play_count = value;
    }

    public get optionPerRangePlayTime() {
        return this.dtm.option_set ? this.dtm.option_set.per_range_play_time : null;
    }
    public set optionPerRangePlayTime(value) {
        if (!this.dtm.option_set || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as ISpotAppSourceOptionSet;
        }
        this.dtm.option_set.per_range_play_time = value;
    }

    public get optionPerRangePlayTarget() {
        return this.dtm.option_set ? this.dtm.option_set.per_range_play_target : null;
    }
    public set optionPerRangePlayTarget(value) {
        if (!this.dtm.option_set || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as ISpotAppSourceOptionSet;
        }
        this.dtm.option_set.per_range_play_target = value;
    }

    public get optionPerRangePlayTargetName() {
        return SpotAppSourcePerRangePlayTarget[this.optionPerRangePlayTarget]
            ? SpotAppSourcePerRangePlayTarget[this.optionPerRangePlayTarget]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get spot_app_id() {
        return this.dtm.spot_app_id;
    }
    public set spot_app_id(value) {
        this.dtm.spot_app_id = value;
    }

    public get target_type_name() {
        return SpotAppSourceTargetType[this.dtm.target_type]
            ? SpotAppSourceTargetType[this.dtm.target_type]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get target_type() {
        return this.dtm.target_type;
    }
    public set target_type(value) {
        this.dtm.target_type = value;
    }

    public get target_type_lookup() { return this.getLookupProperty(() => this.dtm.target_type, (x) => this.createLookup(this.dtm.target_type, this.target_type_name)); }
    public set target_type_lookup(value) { this.setLookupProperty(() => this.dtm.target_type, (x) => { this.dtm.target_type = x; }, value, (item) => item.id); }

    public get target_id() {
        return this.dtm.target_id;
    }
    public set target_id(value) {
        this.dtm.target_id = value;
    }

    public get target_name() {

        let name = 'ID: ' + this.dtm.target_id;

        if (this.dtm.target_id === 0
            && (this.dtm.target_type === SpotAppSourceTargetType.Location || this.dtm.target_type === SpotAppSourceTargetType.Tag)) {
            name = 'Inherited from Device';
        }

        if (this.dtm.related && this.dtm.related.name) {
            name = this.dtm.related.name;
        }

        return name;
    }

    public get target_id_lookup() { return this.getLookupProperty(() => this.dtm.target_id, (x) => this.createLookup(this.dtm.target_id, this.target_name)); }
    public set target_id_lookup(value) { this.setLookupProperty(() => this.dtm.target_id, (x) => { this.dtm.target_id = x; }, value, (item) => item.id); }

    public update(dtm: ISpotAppSource, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: ISpotAppSource, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.related;
    }
}
