import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { IAuditoriumLookup, ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';

import { Auditorium, IAuditorium } from './auditorium.model';
import { FilterMy } from '../filter/filter.model';
import { SortMy } from '../sort/sort.model';
import { IListData } from '../base-data.service';
import { map } from 'rxjs/operators';
import * as Enumerable from 'linq-es2015';
import { ObjectType } from '../enums/enums';

@Injectable()
export class AuditoriumDataService extends BaseListableDataService<Auditorium, IAuditorium, IAuditoriumLookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'auditorium', ObjectType.AUDITORIUM, ['location', 'related'], ['location', 'related']);

  }
  protected getLookupDatas(
    pageNr: number, pageSize: number,
    filters: FilterMy[], sorts: SortMy[], includes: string[],
    search = '', query = '', subResourceName = ''): Observable<IListData<IAuditoriumLookup>> {

    // sorts = [new SortMy({ key: 'created_at', direction: 'desc' })];

    return this.getPage(pageNr, pageSize, filters, sorts, search, query, subResourceName, includes).pipe(map((res) => {
      return {
        data: Enumerable.AsEnumerable(res.data).Select((x) => {
          const auditorium = new Auditorium(x);

          const locationPropertyValue = auditorium.locationProperty ? Enumerable.AsEnumerable(auditorium.locationProperty.values).FirstOrDefault() : null;
          return {
            id: x.id,
            name: x.name,
            location_id: locationPropertyValue?.firstContentID,
            location_name: locationPropertyValue?.firstContentDataName
          };
        }).OrderBy(x => x.location_name).ThenBy(x => x.name).ToArray(),
        filter: res.filter,
        totalCount: res.totalCount,
      };
    }));
  }
}
