
import { ObjectType, Status, StatusSystem, FileSubType, FileType } from '../enums/enums';
import { IBaseCreationModel, BaseCreationModel } from '../base-creation.model';
import * as Enumerable from 'linq-es2015';

export interface IFileMy extends IBaseCreationModel {
    target_type: ObjectType;
    target_id: number;
    type: FileType;
    subtype: FileSubType;
    url: string;
    format: string;
    size: number;
    encoding: string;
    mime_type: string;
    width: number;
    height: number;
    rotation: number;
    transpose: number;
    bitrate: number;
    duration: number;
    fps: string;
}

export class FileMy extends BaseCreationModel<IFileMy> {
    constructor(dtm: IFileMy = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            target_type: ObjectType.UNKNOWN,
            target_id: null,
            type: FileType.Unknown,
            subtype: FileSubType.Unknown,
            url: null,
            format: null,
            size: null,
            encoding: null,
            mime_type: null,
            width: null,
            height: null,
            rotation: null,
            transpose: null,
            bitrate: null,
            duration: null,
            fps: null,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get url() {
        return this.dtm.url;
    }

    public get format() {
        return this.dtm.format;
    }

    public get encoding() {
        return this.dtm.encoding;
    }

    public get type() {
        return this.dtm.type;
    }

    public get typeName() {
        return FileType[this.dtm.type] ? FileType[this.dtm.type]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get subTypeName() {
        return FileSubType[this.dtm.subtype] ? FileSubType[this.dtm.subtype]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get display_name() {
        let rot = '';
        if (this.rotation > 0) {
            rot = ` r${this.rotation}`;
        } else if (this.transpose > 0) {
            rot = ` t${this.transpose}`;
        }
        return `${this.typeName} ${this.format} ${this.size_name}${rot}`;
    }

    public get width() {
        return this.dtm.width;
    }

    public get height() {
        return this.dtm.height;
    }

    public get size_name() {
        return `${this.width}x${this.height}`;
    }

    public get duration() {
        // convert needed, as currently returned from API as string
        return Number(this.dtm.duration);
    }

    public get durationName() {
        if (this.duration) {
            return `${this.duration / 1000}s`;
        }
        return '';
    }

    public get rotation() {
        return this.dtm.rotation;
    }
    public get rotation_name() {
        return `${this.dtm.rotation}°`;
    }

    public get transpose() {
        return this.dtm.transpose;
    }
    public get transpose_name() {
        return `${this.dtm.transpose}°`;
    }

    public get fps() {
          // convert needed, as currently returned from API as string
        return Number(this.dtm.fps);
    }
    public get fpsName() {
        let value = parseFloat(this.dtm.fps);
        if(value == 0){
            return '-';
        }
        return value;
    }

    public get mime_type() {
        return this.dtm.mime_type;
    }

    public get mimeTypePrefix() {
        if (this.mime_type) {
            const split = this.mime_type.split('/');
            if (split.length === 2 && split[0].length) {
                return split[0];
            }
        }
        return 'unknown';
    }

    public get isVideo() {
        return this.mime_type && this.mime_type.toLowerCase().indexOf('video') > -1;
    }

    public get isLandscape() {
        return this.width > this.height;
    }

    public get isImage() {
        return this.mime_type && this.mime_type.toLowerCase().indexOf('image') > -1;
    }

    public update(dtm: IFileMy, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IFileMy, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
    }
}
