import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { DeviceDataService } from '../device/device-data.service';

@Injectable()
export class DeviceTypeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor(deviceDataService: DeviceDataService) {
        this.lookupItems$ = deviceDataService.typelookup$;
    }
}
