import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { DeviceChannel, IDeviceChannel } from './device-channel.model';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { ObjectType } from '../enums/enums';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DeviceChannelSequence, IDeviceChannelSequence } from './device-channel-sequence.model';
import { DeviceChannelSequenceData, IDeviceChannelSequenceData } from './device-channel-sequence-data.model';
import { environment } from 'environments/environment';


@Injectable()
export class DeviceChannelDataService extends BaseListableDataService<DeviceChannel, IDeviceChannel, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'device-channel', ObjectType.DEVICE_CHANNEL, [], []);
  }




  public getSequenceData(deviceChannelIDs: number[]): Observable<DeviceChannelSequenceData> {
    return this.getSequenceDataData(deviceChannelIDs).pipe(map((x) => x)
      , tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to get ${this.resourceDisplayName} sequence data`, 'Error!');
      }));
  }

  protected getSequenceDataData(deviceChannelIDs: number[]): Observable<DeviceChannelSequenceData> {
    const url = `${this.apiBaseUrl}/${this.resourceName}/sequencedata`;
    return this.http.post<IDeviceChannelSequenceData>(url, { "device-channel-ids": deviceChannelIDs }, {
      headers: this.headers
    }).pipe(map((item) => {
        if(item){
          return new DeviceChannelSequenceData(item);
        }
        return null;
    }), catchError((err, caught) => this.handleError(err, caught)));
  }


  public generatePlaylist(deviceChannelID: number): Observable<any> {

      return this.generateV3PlaylistData(deviceChannelID).pipe(tap((value) => {
        this.toastr.success(`${this.resourceDisplayName} Generate playlist`, 'Success!');
      }, (error) => {
        this.toastr.error(`Failed to generate ${this.resourceDisplayName} playlist`, 'Error!');
      }, () => {
        this.resetCachedObservables();
      }));



  }


  protected generateV3PlaylistData(id: number): Observable<any> {
    // TODO: add a proper way
    const url = `${environment.PLAYLIST_TEST_URL}/generate?deviceChannelID=${id}`;
    return this.http.get(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }


}
