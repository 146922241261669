import { Show, IShow } from './show.model';
import { RegularEvent } from '../event/regular-event.model';
import { EventBase } from '../event/event.model';

export class RegularEventShow extends Show<RegularEvent> {
    constructor(dtm: IShow = null) {
        super(dtm, (x) => new RegularEvent(x));
    }

    protected clearLocalProps() {
        super.clearLocalProps();
    }

}
