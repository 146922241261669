import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Resource, IResource } from '../resource/resource.model';
import { ObjectType } from '../enums/enums';
import { PropertyUpdateMode } from '../base.model';

export interface IEventResourceRelation extends IBaseRelationModel {
    event_id: number;
    resource_id: number;
    resource: IResource;
}

export class EventResourceRelation extends BaseRelationModel <IEventResourceRelation> {
    constructor(dtm: IEventResourceRelation = null) {
        super(dtm || {
            event_id: 0,
            resource_id: 0,
            resource: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get eventID() {
        return this.dtm.event_id;
    }
    public set eventID(value) {
        this.dtm.event_id = value;
    }

    public get resourceID() {
        return this.dtm.resource_id;
    }
    public set resourceID(value) {
        this.dtm.resource_id = value;
    }

    public get resource() { return this.getModelProperty(() => this.dtm.resource, (x) => new Resource(x), PropertyUpdateMode.Update); }
    public set resource(value) { this.setModelProperty(() => this.dtm.resource, (x) => this.dtm.resource = x, value, PropertyUpdateMode.Update); }

    public update(dtm: IEventResourceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.resource, (x) => new Resource(x), (x) => this.resource = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: IEventResourceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.resource;
    }

    public static getInstance(eventID: number, resourceID: number){
        const empty = new EventResourceRelation();
        empty.eventID = eventID;
        empty.resourceID = resourceID;
        return empty;
    }
}
