import { Status, StatusSystem } from './enums/enums';

import { BaseCreationModel, IBaseCreationModel } from './base-creation.model';
import { ILookup } from './lookup.model';

export interface IBaseStatusModel extends IBaseCreationModel {
    status: Status;
    status_system: StatusSystem;
}

export abstract class BaseStatusModel<IT extends IBaseStatusModel> extends BaseCreationModel<IT> {
    constructor(dtm: IT) {
        super(dtm);

    }

    public get status() {
        return this.dtm.status;
    }
    public set status(value) {
        this.dtm.status = value;
    }
    public get statusName() {
        return Status[this.dtm.status] ? Status[this.dtm.status]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get isStatusActive() {
        return this.status === Status.Active;
    }
    public set isStatusActive(value) {
        this.status = value ? Status.Active : Status.Suspended;
    }

    public get isStatusSuspended() {
        return this.status === Status.Suspended;
    }

    public get statusLookup(): ILookup {
        return {
            id: this.status,
            name: this.statusName
        };
    }
    public set statusLookup(value) {
        this.status = value ? value.id : null;
    }
    public get status_system() {
        return this.dtm.status_system;
    }
    public set status_system(value) {
        this.dtm.status_system = value;
    }
    public get status_system_name() {
        return StatusSystem[this.dtm.status_system] ? StatusSystem[this.dtm.status_system]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get isInit() {
        return this.status_system === StatusSystem.Initialized;
    }
}
