import { Injectable } from '@angular/core';
import { HubConnection, LogLevel, HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { IMyJob, MyJob } from '../data/background-job/background-job.model';
import { AuthDataService } from '../data/auth/auth-data.service';
import { BackgroundJobDataService } from '../data/background-job/background-job-data.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as Enumerable from 'linq-es2015';
import { ClientDataService } from '../data/client/client-data.service';

@Injectable()
export class ProcessApiJobHubService {

    constructor(private backgroundJobDataService: BackgroundJobDataService, private authDataService: AuthDataService, private cliendDataService: ClientDataService) {

    }

    private jobHub: HubConnection;
    private restartTimout = 60 * 1000; // 10s

    private inited = false;

    private restartTimer: number;
    public init() {

        if (!this.inited && this.cliendDataService.sessionClient) {
            const accessToken = this.authDataService.getDomainCookie('access-token');
            if (accessToken) {
                this.inited = true;
                // can't restart the connection if non in 'initial' state, se reset also the connection

                this.start(accessToken);
                console.log('ProcessApiJobHubService Init');

                this.loadJobs();
            }
        }
    }

    private start(accessToken: string) {
        this.jobHub = new HubConnectionBuilder()
            .withUrl(`${environment.PROCESSAPI_JOB_HUB_URL}&access-token=${accessToken}`, {
                transport: HttpTransportType.WebSockets,
                logger: LogLevel.Debug,
            })
            .build();

        this.jobHub.onclose((error: any) => {
            this.restart(accessToken);
        });

        // this is used for getting connection info back to client
        // this.messageHub.on('Ping', (connectionId: string) => {
        //     this.messageHub.send('Pong', connectionId);
        // });

        this.jobHub.on('OnStateApplied', (job: IMyJob) => {
            this.updateJob(job);
        });

        this.jobHub.on('OnPerformed', (job: IMyJob) => {
            this.updateJob(job);
        });

        this.jobHub.on('OnStateElection', (job: IMyJob) => {
            this.updateJob(job);
        });

        // this.jobHub.on('OnStateUnapplied', (job: IMyJob) => {
        //     // this.backgroundJobService.poll();
        // });

        this.jobHub.start().then(() => {
            // comment
        }, (reason: any) => {
            throw reason;
        }).catch((reason: any) => {
            this.restart(accessToken);
        });
    }
    private restart(hash: string) {
        clearTimeout(this.restartTimer);
        delete this.jobHub;

        this.restartTimer = window.setTimeout(() => {
            this.start(hash);
        }, this.restartTimout);
    }


    public jobs$ = new BehaviorSubject<MyJob[]>([]);

    public requeueJob(jobID: string) {
        return this.backgroundJobDataService.requeueJob(jobID);
    }

    public loadJobs() {
        this.backgroundJobDataService.getAll().pipe(take(1)).subscribe((items) => {
            this.jobs$.next(items);
        });
    }

    public updateJob(dtm: IMyJob) {
        const jobs = this.jobs$.value;
        const match = Enumerable.AsEnumerable(jobs).FirstOrDefault((x) => x.id === dtm.id);
        if (match) {
            match.update(dtm);
        } else {
            jobs.push(new MyJob(dtm));
        }

        this.cleanJobs(jobs);
        this.jobs$.next(jobs);

    }

    private cleanJobs(jobs: MyJob[]) {
        const toDelete: MyJob[] = [];

        // if you remove Succeeded jobs right away then, process data will not be loaded for final time
        // jobs.forEach((job) => {
        //   if (job.state === MyJobState.Succeeded) {
        //     toDelete.push(job);
        //   }
        // });

        toDelete.forEach((job) => {
            jobs.splice(jobs.indexOf(job), 1);
        });
    }
}
