import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { CollectionItem, ICollectionItem } from './collection.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class InboxDataService extends BaseListableDataService<CollectionItem, ICollectionItem, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'collection/inbox', ObjectType.COLLECTION, ['versions', 'campaigns', 'types', 'rating', 'locations'], []);

    // this.items$ =
    // this.getDatas().pipe(map((x) => {
    //   const items: CollectionItem[] = [];
    //   x.data.forEach((dtm) => {
    //     const c = new CollectionItem(dtm);
    //     items.push(c);
    //   });
    //   return items;
    // }), share());
  }
  // public items$: Observable<CollectionItem[]>;

  // private newItems: CollectionItem[] = [];
  // private newItemsSubject = new Subject<CollectionItem[]>();
  // public newItems$ = this.newItemsSubject.asObservable().pipe(share());

  // public addNewItem(item: CollectionItem) {
  //   this.newItems.unshift(item);
  //   this.newItemsSubject.next(this.newItems);
  // }

  public itemRemovedSubject = new Subject<CollectionItem>();
  public itemRemoved$ = this.itemRemovedSubject.asObservable().pipe(share());
}
