import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ITargetAudience, TargetAudience } from '../target-audience/target-audience.model';
import { ObjectType } from '../enums/enums';

export enum LocationMode { AllAllowed = 0, AllowSelected = 1, RestrictSelected = 2 }

export interface IMediaVersionTargetAudienceRelation extends IBaseRelationModel {
    mediaVersion_id: number;
    target_audience_id: number;
    is_restricted: number;
    targetAudience: ITargetAudience;
}

export class MediaVersionTargetAudienceRelation extends BaseRelationModel <IMediaVersionTargetAudienceRelation> {
    constructor(dtm: IMediaVersionTargetAudienceRelation = null) {
        super(dtm || {
            mediaVersion_id: 0,
            target_audience_id: 0,
            is_restricted: 0,
            targetAudience: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get mediaVersion_id() {
        return this.dtm.mediaVersion_id;
    }
    public set mediaVersion_id(value) {
        this.dtm.mediaVersion_id = value;
    }

    public get target_audience_id() {
        return this.dtm.target_audience_id;
    }
    public set target_audience_id(value) {
        this.dtm.target_audience_id = value;
    }

    public get is_restricted() {
        return this.dtm.is_restricted === 1;
    }
    public set is_restricted(value) {
        this.dtm.is_restricted = value ? 1 : 0;
    }


    public update(dtm: IMediaVersionTargetAudienceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IMediaVersionTargetAudienceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.targetAudience;
    }

    public static getInstance(mediaVersion_id: number, target_audience_id: number, is_restricted: boolean){
        const empty = new MediaVersionTargetAudienceRelation();
        empty.mediaVersion_id = mediaVersion_id;
        empty.target_audience_id = target_audience_id;
        empty.is_restricted = is_restricted;
        return empty;
    }
}
