import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ISalesPoint, SalesPoint } from '../sales-point/sales-point.model';
import { ILookup } from '../lookup.model';
import { PropertyUpdateMode } from '../base.model';
import { ObjectType } from '../enums/enums';

export interface IDeviceSalesPointRelation extends IBaseRelationModel {
    device_id: number;
    salespoint_id: number;
    salespoint: ISalesPoint;
}

export class DeviceSalesPointRelation extends BaseRelationModel <IDeviceSalesPointRelation> {
    constructor(dtm: IDeviceSalesPointRelation = null) {
        super(dtm || {
            device_id: 0,
            salespoint_id: 0,
            salespoint: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get deviceID() {
        return this.dtm.device_id;
    }
    public set deviceID(value) {
        this.dtm.device_id = value;
    }

    public get salesPointID() {
        return this.dtm.salespoint_id;
    }
    public set salesPointID(value) {
        this.dtm.salespoint_id = value;
    }


    public get salespoint() { return this.getModelProperty(() => this.dtm.salespoint, (x) => new SalesPoint(x), PropertyUpdateMode.Update); }
    public set salespoint(value) { this.setModelProperty(() => this.dtm.salespoint, (x) => this.dtm.salespoint = x, value, PropertyUpdateMode.Update); }

    public update(dtm: IDeviceSalesPointRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.salespoint, (x) => new SalesPoint(x), (x) => this.salespoint = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: IDeviceSalesPointRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.salespoint;

    }

    public static getInstance(deviceID: number, salesPointID: number){
        const empty = new DeviceSalesPointRelation();
        empty.deviceID = deviceID;
        empty.salesPointID = salesPointID;
        return empty;
    }
}
