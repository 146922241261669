import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { ITargetAudience, TargetAudience } from './target-audience.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class TargetAudienceDataService extends BaseListableDataService<TargetAudience, ITargetAudience, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'target-audience', ObjectType.TARGET_AUDIENCE, [], []);
  }

}
