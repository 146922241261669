import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IAuditorium, Auditorium } from '../auditorium/auditorium.model';
import { DeviceAuditoriumType, ObjectType } from '../enums/enums';
import { ILookup } from '../lookup.model';
import { PropertyUpdateMode } from '../base.model';

export interface IDeviceAuditoriumRelation extends IBaseRelationModel {
    device_id: number;
    auditorium_id: number;
    type: DeviceAuditoriumType;
    auditorium: IAuditorium;
}

export class DeviceAuditoriumRelation extends BaseRelationModel <IDeviceAuditoriumRelation> {
    constructor(dtm: IDeviceAuditoriumRelation = null) {
        super(dtm || {
            device_id: 0,
            auditorium_id: 0,
            type: DeviceAuditoriumType.Unknown,
            auditorium: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get deviceID() {
        return this.dtm.device_id;
    }
    public set deviceID(value) {
        this.dtm.device_id = value;
    }

    public get auditoriumID() {
        return this.dtm.auditorium_id;
    }
    public set auditoriumID(value) {
        this.dtm.auditorium_id = value;
    }

    public get type() {
        return this.dtm.type;
    }
    public set type(value) {
        this.dtm.type = value;
    }

    public get typeName() {
        return DeviceAuditoriumType[this.dtm.type] ? DeviceAuditoriumType[this.dtm.type]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get typeLookup() { return this.getLookupProperty(() => this.dtm.type, (x) => this.createLookup(this.dtm.type, this.typeName)); }
    public set typeLookup(value) { this.setLookupProperty(() => this.dtm.type, (x) => { this.dtm.type = x; }, value, (item) => item.id); }

    public get auditoriumName() {
        if (this.dtm.auditorium) {
            return this.dtm.auditorium.name;
        }
        return null;
    }


    public get auditorium() { return this.getModelProperty(() => this.dtm.auditorium, (x) => new Auditorium(x), PropertyUpdateMode.Update); }
    public set auditorium(value) { this.setModelProperty(() => this.dtm.auditorium, (x) => this.dtm.auditorium = x, value, PropertyUpdateMode.Update); }

    public update(dtm: IDeviceAuditoriumRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.auditorium, (x) => new Auditorium(x), (x) => this.auditorium = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: IDeviceAuditoriumRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.auditorium;

    }

    public static getInstance(deviceID: number, auditoriumID: number, type: DeviceAuditoriumType){
        const empty = new DeviceAuditoriumRelation();
        empty.deviceID = deviceID;
        empty.auditoriumID = auditoriumID;
        empty.type = type;
        return empty;
    }
}
