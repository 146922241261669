import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { TemplateInstance, ITemplateInstance } from './template-instance.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class TemplateInstanceDataService extends BaseListableDataService<TemplateInstance, ITemplateInstance, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'template-instance', ObjectType.TEMPLATE_INSTANCE, ['template-base'], ['template-base', 'productpricegroups']);
  }

}
