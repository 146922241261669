import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import * as Enumerable from 'linq-es2015';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, share } from 'rxjs/operators';
import { IObjectData } from '../data/base-data.service';
import { ClientDataService } from '../data/client/client-data.service';
import { MediaVersionStatusTechUpload, ObjectType } from '../data/enums/enums';
import { MediaVersionDataService } from '../data/media-version/media-version-data.service';
import { IMediaVersion, MediaVersion } from '../data/media-version/media-version.model';
import { MediaDataService } from '../data/media/media-data.service';
import { Media } from '../data/media/media.model';
import { UploadItem, UploadItemType } from '../data/upload-item/upload-item.model';
import { ProcessApiJobHubService } from '../hub/processapi-job-hub.service';
import { IBlobProgress } from './blob-progress.model';
import { LoadNewMediaResult } from './load-new-media-result.model';
import { ToasterService } from './toaster.service';
import { UploadApiService } from './upload-api/upload-api.service';
import { UploadItemService } from './upload-item.service';
import { XhrUploadService } from './xhr-upload.service';


// import { Subject} from "rxjs/Subject";
@Injectable()
export class MediaVersionUploadService {

  private mediaCreatedRegularSubject = new Subject<Media>();
  public mediaCreatedRegular$ = this.mediaCreatedRegularSubject.asObservable().pipe(share());

  // private mediaCreatedInboxSubject = new Subject<Media>();
  // public mediaCreatedInbox$ = this.mediaCreatedInboxSubject.asObservable().pipe(share());

  public currentMedias: Media[] = [];

  constructor(
    private mediaVersionDataService: MediaVersionDataService,
    private uploadApiService: UploadApiService,
    private mediaDataService: MediaDataService,
    private clientDataService: ClientDataService, private xhrUploadService: XhrUploadService,
    private toaster: ToasterService, private uploadItemsService: UploadItemService,
    private processApiJobHubService: ProcessApiJobHubService) {

  }

  public addNewFiles(fileList: FileList, existingMedia: Media, uploadRelation: KeyValue<ObjectType, number> = null) {

    const clientHash = this.clientDataService.sessionClient.hash;

    const uploadItems = this.uploadItemsService.createUploadItems(fileList, UploadItemType.MediaVersion);

    uploadItems.forEach((uploadItem) => {
      uploadItem.uploadFunction = () => {
        return this.uploadFile(clientHash, uploadItem, existingMedia, uploadRelation);
      };
    });

    this.uploadItemsService.addUploadItems(uploadItems);
  }

  public addNewFilesToNewSingleMedia(fileList: FileList) {

    const clientHash = this.clientDataService.sessionClient.hash;

    const uploadItems = this.uploadItemsService.createUploadItems(fileList, UploadItemType.MediaVersion);

    if (uploadItems.length) {
      const model = new Media();
      model.dtm.name = uploadItems[0].name;
      this.mediaDataService.add(model).pipe(map((x) => {

        const media = new Media(x);

        this.currentMedias.push(media);
        // if (addToInbox) {
        //   this.mediaCreatedInboxSubject.next(media);
        // } else {
        this.mediaCreatedRegularSubject.next(media);
        // }

        const existingMedia = media;

        uploadItems.forEach((uploadItem) => {
          uploadItem.uploadFunction = () => {
            return this.uploadFile(clientHash, uploadItem, existingMedia);
          };
        });

        this.uploadItemsService.addUploadItems(uploadItems);

      })).subscribe();
    }

  }

  private uploadFile(clientHash: string, uploadItem: UploadItem, existingMedia: Media, uploadRelation: KeyValue<ObjectType, number> = null) {

    return this.loadNewMedia(uploadItem, existingMedia, uploadRelation).toPromise()
      .then((mediaAndVersion: LoadNewMediaResult) => {
        const media = mediaAndVersion.media;
        const version = mediaAndVersion.mediaVersion;

        version.status_tech_upload = MediaVersionStatusTechUpload.Uploading;
        return this.uploadFileBlobs(clientHash, media, version).catch((error) => {

          version.status_tech_upload = MediaVersionStatusTechUpload.UploadError;
          this.toaster.error('Upload failed', 'Error!');

          this.mediaVersionDataService.putUploadStatus(version.id, MediaVersionStatusTechUpload.UploadError)
            .toPromise().then((newValue: IObjectData<IMediaVersion>) => {
              version.update(newValue.data);
            });
          throw error;
        });
      });

  }

  private uploadFileBlobs(clientHash: string, media: Media, version: MediaVersion) {

    return this.uploadAllFileBlobAndNext(clientHash, version, version.uploadItem.blobProgresses[0], version.uploadItem.blobProgresses)
      .then((data) => {
        // this.pullVersionProcessData(version, 30, 30);
        this.monitorVersionProcessData(version);
      });

  }

  private uploadAllFileBlobAndNext(
    clientHash: string,
    version: MediaVersion, blobProgress: IBlobProgress,
    allBlobProgress: IBlobProgress[]): Promise<MediaVersion> {

    version.isUploadingCalled = true;
    // this.mediaApiService. clientHash,
    return this.uploadApiService.uploadMediaVersionFileBlob(version.id,
      blobProgress.blobID, version.uploadItem.blobProgresses.length,
      version.uploadItem.mime_type, version.uploadItem.name, blobProgress)
      .toPromise().then((result) => {
        if (result.mediaVersionStatusTechUpload === 0) {
          version.status_tech_upload = MediaVersionStatusTechUpload.Undefined;
        } else {
          version.status_tech_upload = result.mediaVersionStatusTechUpload;
        }

        blobProgress.progress = 100;
        let index = allBlobProgress.indexOf(blobProgress);

        version.upload_progress_current = index + 1;

        if (index > -1) {
          const nextBlob = allBlobProgress[++index];
          if (nextBlob) {
            return this.uploadAllFileBlobAndNext(clientHash, version, nextBlob, allBlobProgress);
          }
        }

        // version.file_url = result.location;
        return version;
      });

  }

  private monitorVersionProcessData(version: MediaVersion) {
    this.processApiJobHubService.jobs$.pipe(debounceTime(2000)).subscribe((jobs) => {

      const validJobs = Enumerable.AsEnumerable(jobs).Where((job) => job.isForMediaVersion() && job.targetID === version.id).ToArray();
      version.updateJobs(validJobs);
      if (validJobs.length) {
        this.mediaVersionDataService.getProcessData(version.id).subscribe((x) => {
          version.updateProcessData(x);
        });
      }

    });
  }

  // private pullVersionProcessData(version: MediaVersion, retryTotal: number, retryCurrent: number) {
  //   if (retryCurrent > 0) {
  //     this.mediaVersionDataService.getProcessData(version.id).delay(retryTotal * 1000 / retryCurrent).subscribe((x) => {
  //       version.updateProcessData(x);

  //       if (!version.hasThumbanils || (!version.isProcessed && !version.isProcessError)) {
  //         this.pullVersionProcessData(version, retryTotal, --retryCurrent);
  //       }
  //     });

  //   }
  // }

  private loadNewMedia(uploadItem: UploadItem, existingMedia: Media, uploadRelation: KeyValue<ObjectType, number> = null): Observable<LoadNewMediaResult> {

    const version = new MediaVersion();
    version.name = uploadItem.name;
    version.mimeType = uploadItem.mime_type;
    version.setUploadItem(uploadItem);

    if (existingMedia) {

      return this.mediaVersionDataService.initUpload(version.dtm, existingMedia.id).pipe(map((x) => {

        x.upload_progress_total = version.uploadItem.blobProgresses.length;
        version.update(x);

        existingMedia.addNewVersion(version);

        const match = this.currentMedias.find((e) => {
          return e === existingMedia;
        });
        if (!match) {
          this.currentMedias.push(existingMedia);
        }

        return new LoadNewMediaResult(existingMedia, version);
      }));
    } else {

      return this.mediaDataService.initUpload({ name: uploadItem.name, mime_type: uploadItem.mime_type }).pipe(map((x) => {

        const media = new Media(x);
        media.uploadRelation = uploadRelation;
        const verDtm = media.versions[0].dtm;
        verDtm.upload_progress_total = version.uploadItem.blobProgresses.length;
        version.update(verDtm, true);
        media.versions[0] = version;

        this.currentMedias.push(media);
        // if (addToInbox) {
        //   this.mediaCreatedInboxSubject.next(media);
        // } else {
        this.mediaCreatedRegularSubject.next(media);
        // }
        return new LoadNewMediaResult(media, version);
      }));
    }
  }

  // public processFile(clientHash: string, model: MediaVersion) {

  //   return this.mediaVersionDataService.putTechStatus(model.id, MediaVersionStatusTech.ProcessStart).toPromise()
  //     .then((value: IObjectData<IMediaVersion>) => {
  //       model.update(value.data);
  //       model.upload_status = UploadStatus.Processing;
  //       return this.mediaApiService.getFileMeta(clientHash, model.hash).toPromise()
  //         .then(meta => {

  //           model.dtm.status_tech_process = MediaVersionStatusTechProcess.Processed;
  //           model.dtm.file = meta.fileUrl;
  //           model.dtm.file_size = meta.length;
  //           model.dtm.playtime_second = meta.duration / 1000;
  //           model.dtm.resolution_x = meta.width;
  //           model.dtm.resolution_y = meta.height;
  //           model.dtm.mime_type = meta.contentType;
  //           model.dtm.encoding = meta.contentEncoding;
  //           model.dtm.bitrate = meta.bitRate;
  //           model.dtm.file_format = meta.format;

  //           model.upload_status = UploadStatus.Processed;

  //           return this.mediaVersionDataService.put(model.id, model.dtm).toPromise()
  //             .then((value: IMediaVersion) => {

  //               model.update(value);
  //               return model;
  //             });
  //         });

  //     });

  // }

}
