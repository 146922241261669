import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Campaign } from '../campaign/campaign.model';
import { CollectionItem, ICollectionItem } from '../collection/collection.model';
import { CollectionType, PublishRequirementStatus, PublishStatus, Status, StatusSystem, ScreenGroupType, ObjectType, CampaignType, FileType } from '../enums/enums';
import { LocationMy } from '../location/location.model';
import { ILookupRestrict, IScreenGroupLookupRestrict } from '../lookup-restrict.model';
import { ILookup } from '../lookup.model';
import { MediaType } from '../media-type/media-type.model';
import { IMediaVersion, MediaVersion } from '../media-version/media-version.model';
import { IMediaCampaignRelation, MediaCampaignRelation } from './media-campaign-relation.model';
import { IMediaLocationRelation, MediaLocationRelation } from './media-location-relation.model';
import { IMediaMediatypeRelation, MediaMediatypeRelation } from './media-mediatype-relation.model';
import { IMediaDeviceChannelRelation, MediaDeviceChannelRelation } from './media-screen-relation.model';
import { IMediaScreenGroupRelation, MediaScreenGroupRelation } from './media-screengroup-relation.model';
import { IMediaTagRelation, MediaTagRelation } from './media-tag-relation.model';
import { IRating, Rating } from '../rating/rating.model';
import * as Enumerable from 'linq-es2015';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ScreenGroup } from '../screen-group/screen-group.model';
import { ITimeRangeRelation, TimeRangeRelation } from '../time-range/time-range-relation.model';
import { KeyValue } from '@angular/common';
import { PropertyUpdateMode } from '../base.model';

export interface IMedia extends IBaseNameModel {
    valid_from: string;
    valid_to: string;
    rating_id: number;
    rating: IRating;
    status_publish: PublishStatus;
    status_publish_requirement: PublishRequirementStatus;

    mediaVersions: IMediaVersion[];
    // campaignMedia: IMediaCampaignRelation[]; // hide
    mediaMediaTypes: IMediaMediatypeRelation[];
    mediaTags: IMediaTagRelation[];
    // mediaScreengroups: IMediaScreenGroupRelation[]; // hide
    mediaDeviceChannels: IMediaDeviceChannelRelation[];
    mediaLocations: IMediaLocationRelation[];
    timerangeRelations: ITimeRangeRelation[];

    // fakes, filled from mediaScreengroups, not really in response
    mediaLocationScreengroups: IMediaScreenGroupRelation[];
    mediaRegularScreengroups: IMediaScreenGroupRelation[];

    // fakes, filled from campaignMedia, not really in response
    regularCampaignMedia: IMediaCampaignRelation[];
    bookingCampaignMedia: IMediaCampaignRelation[];
}

interface IMediaReal extends IMedia {
    mediaScreengroups: IMediaScreenGroupRelation[]; // unhide
    campaignMedia: IMediaCampaignRelation[]; // unhide
}

export interface IMediaInitUpload {
    name: string;
    mime_type: string;
}

export class Media extends BaseNameModel<IMedia> {
    constructor(dtm: IMedia = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            rating_id: 0,
            rating: null,
            name: '',
            valid_from: null,
            valid_to: null,
            status: Status.Active,
            status_system: StatusSystem.Default,
            status_publish: PublishStatus.UnPublished,
            status_publish_requirement: PublishRequirementStatus.NeedsEditing,

            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null,
            mediaVersions: [],
            // campaignMedia: [],
            mediaMediaTypes: [],
            mediaTags: [],
            // mediaScreengroups: [],
            mediaDeviceChannels: [],
            mediaLocations: [],
            timerangeRelations: null,

            mediaLocationScreengroups: [],
            mediaRegularScreengroups: [],

            regularCampaignMedia: [],
            bookingCampaignMedia: []
        });
        this.update(this.dtm, true, true);
    }

    public get publish_status() {
        return this.dtm.status_publish;
    }
    public set publish_status(value) {
        this.dtm.status_publish = value;
    }

    public get publish_requirement_status() {
        return this.dtm.status_publish_requirement;
    }
    public get is_ready_for_publish() {
        return this.publish_requirement_status === PublishRequirementStatus.ReadyForPublish;
    }

    public get statusName() {
        return Status[this.dtm.status] ? Status[this.dtm.status]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get visualActive() {
        if (!this.versions || this.versions.length === 0) {
            return false;
        } else {
            if (!this.validVersions || this.validVersions.length === 0) {
                return false;
            } else {
                return this.isActive;
            }
        }
    }

    public get visualStatusName() {
        if (!this.versions || this.versions.length === 0) {
            return 'No Versions';
        } else {
            if (!this.validVersions || this.validVersions.length === 0) {
                return 'No valid versions';
            } else {
                return this.statusName;
            }
        }
    }

    public get valid_from() {
        let value = this.dtm.valid_from;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set valid_from(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_from = value;
    }

    public get valid_to() {
        let value = this.dtm.valid_to;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set valid_to(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_to = value;
    }

    public get versions() { return this.getModelArray(() => this.dtm.mediaVersions, (x) => new MediaVersion(x), PropertyUpdateMode.Update); }
    public set versions(value) { this.setModelArray(() => this.dtm.mediaVersions, (x) => this.dtm.mediaVersions = x, value, PropertyUpdateMode.Update); }

    public get campaign_lookups() { return this.getLookupArray(() => this.dtm.regularCampaignMedia, (x) => this.createLookup(x.campaign_id, new Campaign(x.campaign).displayName)); }
    public set campaign_lookups(value) { this.setLookupArray(() => this.dtm.regularCampaignMedia, (x) => this.dtm.regularCampaignMedia = x, value, (item) => MediaCampaignRelation.getInstance(this.id, item.id).dtm); }

    // public get booking_lookups() { return this.getLookupArray(() => this.dtm.bookingCampaignMedia, (x) => this.createLookup(x.campaign_id, new Campaign(x.campaign).displayName)); }
    // public set booking_lookups(value) { this.setLookupArray(() => this.dtm.bookingCampaignMedia, (x) => this.dtm.bookingCampaignMedia = x, value, (item) => MediaCampaignRelation.getInstance(this.id, item.id).dtm); }

    public get mediaBookings() { return this.getModelArray(() => this.dtm.bookingCampaignMedia, (x) => new MediaCampaignRelation(x), PropertyUpdateMode.Update); }
    public set mediaBookings(value) { this.setModelArray(() => this.dtm.bookingCampaignMedia, (x) => this.dtm.bookingCampaignMedia = x, value, PropertyUpdateMode.Update); }

    public get campaigns_string() {
        if (this.campaign_lookups && this.campaign_lookups.length) {
            return Enumerable.AsEnumerable(this.campaign_lookups)
                .Select((x) => x.name).Distinct().ToArray().join(', ');
        }
        return '';
    }
    public get mediatype_lookup() {
        if (!this._mediatypeLookup && this.dtm.mediaMediaTypes && this.dtm.mediaMediaTypes.length) {
            const match = this.dtm.mediaMediaTypes[0];
            this._mediatypeLookup = { id: match.type_id, name: match.mediaType.name };
        }
        return /*first lookup*/ this._mediatypeLookup;
    }
    public set mediatype_lookup(value) {

        this.dtm.mediaMediaTypes.length = 0;

        if (value) {
            const empty = new MediaMediatypeRelation();
            empty.media_id = this.id;
            empty.type_id = value.id;

            // empty.mediaType = new MediaType();
            // empty.mediaType.name = value.name;
            // empty.dtm.mediaType = empty.mediaType.dtm;

            this.dtm.mediaMediaTypes.push(empty.dtm);
        }

        this._mediatypeLookup = value;
    }

    public get mediatype_lookups() { return this.getLookupArray(() => this.dtm.mediaMediaTypes, (x) => this.createLookup(x.type_id, x.mediaType?.name)); }
    public set mediatype_lookups(value) { this.setLookupArray(() => this.dtm.mediaMediaTypes, (x) => this.dtm.mediaMediaTypes = x, value, (item) => MediaMediatypeRelation.getInstance(this.id, item.id).dtm); }

    public get mediatypesString() {
        if (this.mediatype_lookups && this.mediatype_lookups.length) {
            return Enumerable.AsEnumerable(this.mediatype_lookups)
                .Select((x) => x.name).Distinct().ToArray().join(', ');
        }
        return '';
    }

    public get media_display_type() {
        if (this.mediatypesString && this.mediatypesString.length) {
            return this.mediatypesString;
        }
        if (this.isImage) {
            return 'Image';
        }
        if (this.isVideo) {
            return 'Video';
        }

        return 'Unknown';
    }

    public get display_name() {
        return this.name;

        // let ret = `${this.campaigns_string} ${this.name}`;
        // // remove double spaces
        // ret = ret.replace(/  +/g, ' ');
        // return ret.trim();
    }

    public get display_subName() {
        let ret = `${this.mediatypesString} ${this.tagsString}`;
        if (this.campaigns_string && this.campaigns_string.length) {
            ret = `${ret} ${this.campaigns_string}`;
        }

        // remove double spaces
        ret = ret.replace(/  +/g, ' ');
        return ret.trim();
    }


    public get tag_lookups() { return this.getLookupArray(() => this.dtm.mediaTags, (x) => this.createLookup(x.tag_id, x.tag?.name)); }
    public set tag_lookups(value) { this.setLookupArray(() => this.dtm.mediaTags, (x) => this.dtm.mediaTags = x, value, (item) => MediaTagRelation.getInstance(this.id, item.id).dtm); }


    public get tagsString() {
        if (this.tag_lookups && this.tag_lookups.length) {
            return Enumerable.AsEnumerable(this.tag_lookups)
                .Select((x) => x.name).Distinct().ToArray().join(', ');
        }
        return '';
    }

    public get infoString() {
        let info = '';
        if(this.hasVideo){  

            var originalVideosWithFPS = Enumerable.AsEnumerable(this.versions)
            .SelectMany((x) => x.files).Where(x=>x.isVideo && x.type == FileType.Original).Select(x=>  x.fps).Distinct().ToArray(); //  && x.fps > 0

            if(originalVideosWithFPS.length > 0){
                info = 'ofps:' + originalVideosWithFPS.join(', ');
            }else{
                info = 'ofps: -';
            }


            var convertedVideosWithFPS = Enumerable.AsEnumerable(this.versions)
                .SelectMany((x) => x.files).Where(x=>x.isVideo && x.type == FileType.Converted).Select(x=>  x.fps).Distinct().ToArray(); //  && x.fps > 0

            if(convertedVideosWithFPS.length > 0){
                info += ' cfps:' + convertedVideosWithFPS.join(', ');
            }else{
                info += ' cfps: -';
            }
        }
   
        return info;
    }

    public get allTags() {
        if (!this._allTags) {
            const tags: string[] = [];
            if (this.tag_lookups && this.tag_lookups.length) {
                tags.push(...Enumerable.From(this.tag_lookups).Select((x) => x.name).ToArray());
            }
            if (this.versions && this.versions.length) {
                const versionTags = Enumerable.AsEnumerable(this.versions).SelectMany((x) => x.tag_lookups)
                    .Select((x) => x.name).ToArray();
                tags.push(...versionTags);
            }
            if (tags.length) {
                this._allTags = Enumerable.AsEnumerable(tags).ToArray();
            }
        }

        return /*fix*/ this._allTags;
    }

    public get allTagsString() {
        if (this.allTags && this.allTags.length) {
            return Enumerable.AsEnumerable(this.allTags).GroupBy((t) => t)
                .Select((g) => g.length <= 1 ? `${g.key}` : `${g.key} x${g.length}`).OrderBy((x) => x).Distinct().ToArray().join(', ');
        }
        return '';
    }

    public get allMediaTypesAndTagsString() {
        if (this.allTags && this.allTags.length) {
            const mediaTypes = Enumerable.AsEnumerable(this.mediatype_lookups)
                .Select((x) => x.name).Distinct().ToArray();
            const tags = Enumerable.AsEnumerable(this.allTags).GroupBy((t) => t)
                .Select((g) => g.length <= 1 ? `${g.key}` : `${g.key} x${g.length}`).OrderBy((x) => x).Distinct().ToArray();
            return mediaTypes.concat(tags).join(', ');
        }
        return '';
    }
    public get allDimentions() {
        if (!this._allDimentions && this.versions && this.versions.length) {
            this._allDimentions = Enumerable.AsEnumerable(this.versions).Where((x) => !!x.fileWidth && !!x.fileHeight).Select((x) => {
                return {
                    width: x.fileWidth,
                    height: x.fileHeight
                };
            }).Distinct((x) => `${x.width}x${x.height}`).ToArray();
        }

        return /*fix*/ this._allDimentions;
    }

    public get locationLookups() { return this.getLookupArray(() => this.dtm.mediaLocations, (x) => this.createRestrictLookup(x.location_id, x.location?.name, x.is_restricted === 1)); }
    public set locationLookups(value) { this.setLookupArray(() => this.dtm.mediaLocations, (x) => this.dtm.mediaLocations = x, value, (item) => MediaLocationRelation.getInstance(this.id, item.id, item.restrict).dtm); }


    public get locationScreengroupLookups() {
        if (!this._locationScreengroupLookups && this.dtm.mediaLocationScreengroups) {
            this._locationScreengroupLookups = Enumerable.AsEnumerable(this.dtm.mediaLocationScreengroups)
                .Where((x) => x.screengroup.type === ScreenGroupType.Building).Select((x) => {
                    return {
                        id: x.screengroup_id,
                        name: x.screengroup.name,
                        type: x.screengroup.type,
                        acronym: x.screengroup.acronym,
                        restrict: x.is_restricted > 0
                    };
                }).ToArray();
        }
        return /*where lookup*/ this._locationScreengroupLookups;
    }
    public set locationScreengroupLookups(values) {
        if (!this.dtm.mediaLocationScreengroups) {
            this.dtm.mediaLocationScreengroups = [];
        }
        this.dtm.mediaLocationScreengroups.length = 0;
        values = Enumerable.AsEnumerable(values).Where((x) => x.type === ScreenGroupType.Building).ToArray();

        values.forEach((x) => {
            const empty = new MediaScreenGroupRelation();
            empty.media_id = this.id;
            empty.screengroup_id = x.id;
            empty.is_restricted = x.restrict;

            // empty.screengroup.name = x.name;
            // empty.screengroup.type = x.type;
            // empty.screengroup.acronym = x.acronym;
            // empty.dtm.screengroup = empty.screengroup.dtm;

            this.dtm.mediaLocationScreengroups.push(empty.dtm);
        });
        this._locationScreengroupLookups = values;
    }
    public get regularScreengroupLookups() {
        if (!this._regularScreengroupLookups && this.dtm.mediaRegularScreengroups) {
            this._regularScreengroupLookups = Enumerable.AsEnumerable(this.dtm.mediaRegularScreengroups)
                .Where((x) => x.screengroup.type !== ScreenGroupType.Building).Select((x) => {
                    return {
                        id: x.screengroup_id,
                        name: x.screengroup.name,
                        type: x.screengroup.type,
                        acronym: x.screengroup.acronym,
                        restrict: x.is_restricted > 0
                    };
                }).ToArray();
        }
        return /*where lookup*/ this._regularScreengroupLookups;
    }
    public set regularScreengroupLookups(values) {
        if (!this.dtm.mediaRegularScreengroups) {
            this.dtm.mediaRegularScreengroups = [];
        }
        this.dtm.mediaRegularScreengroups.length = 0;
        values = Enumerable.AsEnumerable(values).Where((x) => x.type !== ScreenGroupType.Building).ToArray();

        values.forEach((x) => {
            const empty = new MediaScreenGroupRelation();
            empty.media_id = this.id;
            empty.screengroup_id = x.id;
            empty.is_restricted = x.restrict;

            // empty.screengroup.name = x.name;
            // empty.screengroup.type = x.type;
            // empty.screengroup.acronym = x.acronym;
            // empty.dtm.screengroup = empty.screengroup.dtm;

            this.dtm.mediaRegularScreengroups.push(empty.dtm);
        });
        this._regularScreengroupLookups = values;
    }

    public get screengroupDisplayNames() {
        if (!this._screengroupDisplayNames) {
            let groups: IScreenGroupLookupRestrict[] = [];
            if (this.locationScreengroupLookups) {
                groups = this.locationScreengroupLookups;
            }
            if (this.regularScreengroupLookups) {
                groups = groups.concat(this.regularScreengroupLookups);
            }

            const names = Enumerable.AsEnumerable(groups)
                .OrderBy((x) => ScreenGroup.getTypeOrder(x.type)).Select((x) => `${x.restrict ? ' - ' : ''}${x.name}`).ToArray();
            if (names.length) {
                this._screengroupDisplayNames = names.join(', ').trim();
            }
        }
        return /*fix*/ this._screengroupDisplayNames;
    }

    public get devicechannel_lookups() { return this.getLookupArray(() => this.dtm.mediaDeviceChannels, (x) => this.createRestrictLookup(x.device_channel_id, x.deviceChannel?.name, x.is_restricted === 1)); }
    public set devicechannel_lookups(value) { this.setLookupArray(() => this.dtm.mediaDeviceChannels, (x) => this.dtm.mediaDeviceChannels = x, value, (item) => MediaDeviceChannelRelation.getInstance(this.id, item.id, item.restrict).dtm); }

    public get timeRangeLookups() { return this.getLookupArray(() => this.dtm.timerangeRelations, (x) => this.createLookup(x.timerange_id, x.timerange?.name)); }
    public set timeRangeLookups(value) { this.setLookupArray(() => this.dtm.timerangeRelations, (x) => this.dtm.timerangeRelations = x, value, (item) => TimeRangeRelation.getInstance(this.id, item.id).dtm); }

    public get orderedVersions() {
        if (this.versions && this.versions.length) {
            return Enumerable.AsEnumerable(this.versions).OrderBy((x) => x.isSuccess ? 0 : 1)
                .ThenBy((x) => x.isPortrait ? 0 : 1).ToArray();
        }
        return [];
    }

    // public get notMonitoredVersions() {
    //     if (this.versions && this.versions.length) {
    //         return Enumerable.AsEnumerable(this.versions).Where((x) => !x.jobsSub || x.jobsSub.closed).ToArray();
    //     }
    //     return [];
    // }

    public get version() {
        return this.orderedVersions[0];
    }

    public get validVersions() {
        if (this.versions && this.versions.length) {
            return Enumerable.AsEnumerable(this.versions).Where((x) => x.isSuccess && x.isStatusActive).ToArray();
        }
        return [];
    }

    public get isActive() {
        return this.status === Status.Active;
    }

    public get hasUploadingVersions() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isUploading) > 0;
    }

    public get isVideo() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isVideo) === this.versions.length;
    }

    public get hasVideo() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isVideo) > 0;
    }

    public get hasLandscapeVideo() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isVideo && !x.isPortrait) > 0;
    }

    public get isImage() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isImage) === this.versions.length;
    }

    public get hasImage() {
        return this.versions && this.versions.length > 0 && Enumerable.AsEnumerable(this.versions).Count((x) => x.isImage) > 0;
    }
    public get isSelected() {
        return this._isSelected;
    }
    public set isSelected(value) {
        this._isSelected = value;
    }

    public get badgeText() {
        if (this.versions) {

            if (this.versions.length !== this.validVersions.length) {
                return `${this.versions.length}/${this.validVersions.length}`;
            }

            return `${this.versions.length}`;
        }

        return '';
    }

    // private versionAddedSubject = new Subject<MediaVersion>();
    // public versionAdded$ = this.versionAddedSubject.asObservable();
    public versionAdded$ = new Subject<MediaVersion>();

    private dateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    private _mediatypeLookup: ILookup;

    private _allTags: string[] = [];

    private _allDimentions: Array<{ height: number, width: number }> = [];

    private _locationScreengroupLookups: IScreenGroupLookupRestrict[];

    private _regularScreengroupLookups: IScreenGroupLookupRestrict[];

    private _screengroupDisplayNames: string;

    private _devicechannelLookups: ILookupRestrict[];

    private _isSelected = false;

    public uploadRelation: KeyValue<ObjectType, number>; // for matching uploads

    public addNewVersion(version: MediaVersion) {
        this.versions.unshift(version);
        this.versionAdded$.next(version);
    }
    public update(dtm: IMedia, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        // create fake dtm props
        if ((this.dtm as IMediaReal).mediaScreengroups) {
            // get other than building types
            this.dtm.mediaRegularScreengroups = Enumerable.AsEnumerable((this.dtm as IMediaReal).mediaScreengroups)
                .Where((x) => x.screengroup.type !== ScreenGroupType.Building).ToArray();

            // get building types
            this.dtm.mediaLocationScreengroups = Enumerable.AsEnumerable((this.dtm as IMediaReal).mediaScreengroups)
                .Where((x) => x.screengroup.type === ScreenGroupType.Building).ToArray();

        }

        if ((this.dtm as IMediaReal).campaignMedia) {
            // get other than booking types
            this.dtm.regularCampaignMedia = Enumerable.AsEnumerable((this.dtm as IMediaReal).campaignMedia)
                .Where((x) => x.campaign.type_id !== CampaignType.Booking).ToArray();

            // get booking types
            this.dtm.bookingCampaignMedia = Enumerable.AsEnumerable((this.dtm as IMediaReal).campaignMedia)
                .Where((x) => x.campaign.type_id === CampaignType.Booking).ToArray();
        }


        this.updateModelArrayProperty(() => this.dtm.mediaVersions, (x) => new MediaVersion(x), (x) => this.versions = x, forceReplaceDtm);
        this.updateModelArrayPropertyWitoutID(() => this.dtm.bookingCampaignMedia, (x) => new MediaCampaignRelation(x), (x) => this.mediaBookings = x, forceReplaceDtm);


    }

    protected clearLocalProps() {
        super.clearLocalProps();

        this._mediatypeLookup = null;
        this._devicechannelLookups = null;
        this._locationScreengroupLookups = null;
        this._regularScreengroupLookups = null;
        this._allTags = null;
        this._allDimentions = null;


        this._screengroupDisplayNames = null;
    }

    public cleanForSave(dtm: IMedia, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.rating;

        this.cleanDTMs(MediaVersion, dtm.mediaVersions, saver);
        this.cleanDTMs(MediaCampaignRelation, dtm.regularCampaignMedia, saver);
        this.cleanDTMs(MediaCampaignRelation, dtm.bookingCampaignMedia, saver);
        this.cleanDTMs(MediaDeviceChannelRelation, dtm.mediaDeviceChannels, saver);
        this.cleanDTMs(MediaLocationRelation, dtm.mediaLocations, saver);
        this.cleanDTMs(MediaMediatypeRelation, dtm.mediaMediaTypes, saver);
        this.cleanDTMs(MediaScreenGroupRelation, dtm.mediaLocationScreengroups, saver);
        this.cleanDTMs(MediaScreenGroupRelation, dtm.mediaRegularScreengroups, saver);
        this.cleanDTMs(MediaTagRelation, dtm.mediaTags, saver);
        this.cleanDTMs(TimeRangeRelation, dtm.timerangeRelations, saver);

        // refill actual value
        if (dtm.mediaRegularScreengroups || dtm.mediaLocationScreengroups) {
            if (!dtm.mediaRegularScreengroups) {
                dtm.mediaRegularScreengroups = [];
            }
            if (!dtm.mediaLocationScreengroups) {
                dtm.mediaLocationScreengroups = [];
            }

            (dtm as IMediaReal).mediaScreengroups = dtm.mediaLocationScreengroups.concat(dtm.mediaRegularScreengroups);
        } else {
            (dtm as IMediaReal).mediaScreengroups = null;
        }

        if (dtm.regularCampaignMedia || dtm.bookingCampaignMedia) {
            if (!dtm.regularCampaignMedia) {
                dtm.regularCampaignMedia = [];
            }
            if (!dtm.bookingCampaignMedia) {
                dtm.bookingCampaignMedia = [];
            }

            (dtm as IMediaReal).campaignMedia = dtm.regularCampaignMedia.concat(dtm.bookingCampaignMedia);
        } else {
            (dtm as IMediaReal).campaignMedia = null;
        }

        // cleanup fakes
        delete dtm.mediaRegularScreengroups;
        delete dtm.mediaLocationScreengroups;

        delete dtm.regularCampaignMedia;
        delete dtm.bookingCampaignMedia;
    }
}
