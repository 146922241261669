import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { ITag, Tag } from './tag.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class TagDataService extends BaseListableDataService<Tag, ITag, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'tag', ObjectType.TAG, ["devices"], ["devices"]);
  }

}
