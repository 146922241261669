import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { IDeviceBase } from '../device/device.base.model';

// tslint:disable-next-line:no-empty-interface
export interface IChannelBase extends IBaseNameModel {

}

export class ChannelBase<IT extends IDeviceBase> extends BaseNameModel<IT> {
    constructor(dtm: IT) {
        super(dtm);
    }
}
