import { IBaseNameModel, BaseNameModel } from '../base-name.model';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceBase extends IBaseNameModel {
}

export class DeviceBase<IT extends IDeviceBase> extends BaseNameModel<IT> {
    constructor(dtm: IT) {
        super(dtm);
    }
}
