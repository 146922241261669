import { Status, StatusSystem, TimeRangeType, ObjectType } from '../enums/enums';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { ILookup } from '../lookup.model';
import * as moment from 'moment';

export interface ITimeRangeDays {
    mon: number;
    tue: number;
    wed: number;
    thu: number;
    fri: number;
    sat: number;
    sun: number;
    nat_hol: number;
    sch_vac: number;
}

export interface ITimeRange extends IBaseNameModel {
    type: TimeRangeType;
    start: string;
    end: string;
    weekdays: ITimeRangeDays;
}

export class TimeRange extends BaseNameModel<ITimeRange> {
    constructor(dtm: ITimeRange = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            type: TimeRangeType.Generic,
            start: null,
            end: null,
            weekdays: {
                mon: 0,
                tue: 0,
                wed: 0,
                thu: 0,
                fri: 0,
                sat: 0,
                sun: 0,
                nat_hol: 0,
                sch_vac: 0
            },
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);

        this.weekdayNames = moment.weekdaysShort(false);
    }

    public get start() {
        return this.dtm.start;
    }
    public set start(value) {
        this.dtm.start = value;
    }

    public get end() {
        return this.dtm.end;
    }
    public set end(value) {
        this.dtm.end = value;
    }

    public get type() {
        return this.dtm.type;
    }
    public set type(value) {
        this.dtm.type = value;
    }
    public get typeName() {
        return TimeRangeType[this.type] ? TimeRangeType[this.type]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get type_lookup() { return this.getLookupProperty(() => this.dtm.type, (x) => this.createLookup(this.dtm.type, this.typeName)); }
    public set type_lookup(value) { this.setLookupProperty(() => this.dtm.type, (x) => { this.dtm.type = x; }, value, (item) => item.id); }

    public get usedInMonday() {
        return this.dtm.weekdays ? this.dtm.weekdays.mon === 1 : false;
    }
    public set usedInMonday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.mon = value ? 1 : 0;
    }

    public get usedInTuesday() {
        return this.dtm.weekdays ? this.dtm.weekdays.tue === 1 : false;
    }
    public set usedInTuesday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.tue = value ? 1 : 0;
    }

    public get usedInWednesday() {
        return this.dtm.weekdays ? this.dtm.weekdays.wed === 1 : false;
    }
    public set usedInWednesday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.wed = value ? 1 : 0;
    }

    public get usedInThursday() {
        return this.dtm.weekdays ? this.dtm.weekdays.thu === 1 : false;
    }
    public set usedInThursday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.thu = value ? 1 : 0;
    }

    public get usedInFriday() {
        return this.dtm.weekdays ? this.dtm.weekdays.fri === 1 : false;
    }
    public set usedInFriday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.fri = value ? 1 : 0;
    }

    public get usedInSaturday() {
        return this.dtm.weekdays ? this.dtm.weekdays.sat === 1 : false;
    }
    public set usedInSaturday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.sat = value ? 1 : 0;
    }

    public get usedInSunday() {
        return this.dtm.weekdays ? this.dtm.weekdays.sun === 1 : false;
    }
    public set usedInSunday(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.sun = value ? 1 : 0;
    }

    public get usedInNationalHolidays() {
        return this.dtm.weekdays ? this.dtm.weekdays.nat_hol === 1 : false;
    }
    public set usedInNationalHolidays(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.nat_hol = value ? 1 : 0;
    }

    public get usedInSchoolVacations() {
        return this.dtm.weekdays ? this.dtm.weekdays.sch_vac === 1 : false;
    }
    public set usedInSchoolVacations(value) {
        if (!this.dtm.weekdays) { this.dtm.weekdays = {} as any as ITimeRangeDays; }
        this.dtm.weekdays.sch_vac = value ? 1 : 0;
    }

    private weekdayNames: string[] = [];

    public get weekdaysName() {
        const ret: string[] = [];

        if (this.usedInMonday) {
            ret.push(this.weekdayNames[1]);
        }
        if (this.usedInTuesday) {
            ret.push(this.weekdayNames[2]);
        }
        if (this.usedInWednesday) {
            ret.push(this.weekdayNames[3]);
        }
        if (this.usedInThursday) {
            ret.push(this.weekdayNames[4]);
        }
        if (this.usedInFriday) {
            ret.push(this.weekdayNames[5]);
        }
        if (this.usedInSaturday) {
            ret.push(this.weekdayNames[6]);
        }
        if (this.usedInSunday) {
            ret.push(this.weekdayNames[0]);
        }

        return ret.join(',');
    }

    public get specialdaysName() {
        const ret: string[] = [];

        if (this.usedInNationalHolidays) {
            ret.push('National Holidays');
        }
        if (this.usedInSchoolVacations) {
            ret.push('School Vacations');
        }

        return ret.join(', ');
    }

    public update(dtm: ITimeRange, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);


    }

    public cleanForSave(dtm: ITimeRange, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

    }
}
