import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogData } from '../../shared/base/dialog-data.base';
import { UploadSummaryDialogComponent } from '../../shared/components/upload-summary-dialog/upload-summary-dialog.component';

@Injectable()
export class GlobalDialogService {
  private _isUploadSummaryDialogOpen = false;

  constructor(private dialog: MatDialog, private overlay: Overlay) {

  }

  public openUploadSummaryDialog() {

    if (!this._isUploadSummaryDialogOpen) {
      const conf = new MatDialogConfig<DialogData<any, any>>();
      conf.width = '362px';
      conf.closeOnNavigation = false;
      conf.hasBackdrop = false;
      conf.id = 'upload-summary-dialog-container';
      conf.panelClass = 'upload-summary-dialog-container';
      // 'position' doesn't work propely, using custom style
      conf.position = {
        right: '24px',
        bottom: '24px',
        top: 'auto',
        left: 'auto'
      };
      conf.scrollStrategy = this.overlay.scrollStrategies.noop();

      const dialogRef = this.dialog.open(UploadSummaryDialogComponent, conf);
      dialogRef.afterOpened().subscribe(() => {
        this._isUploadSummaryDialogOpen = true;
        // TODO: remove this if the feature is added
        // LINK: https://github.com/angular/material2/issues/8706
        // document.documentElement.classList.remove('cdk-global-scrollblock');
      });
      dialogRef.afterClosed().subscribe((value) => {
        this._isUploadSummaryDialogOpen = false;
      });
    }
  }
}
