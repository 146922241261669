import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { ObjectType } from '../enums/enums';
import { ISalesPoint, SalesPoint } from './sales-point.model';

@Injectable()
export class SalesPointDataService extends BaseListableDataService<SalesPoint, ISalesPoint, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'salespoint', ObjectType.SALESPOINT, ['screengroups'], ['screengroups']);
  }

}
