import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData, IObjectData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { Device, IDevice } from './device.model';
import { IDeviceLookup, ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { DeviceDeviceRelationType, DeviceType, ObjectType } from '../enums/enums';
import { IPlaylistItem } from '../playlist/playlist-item.model';
import { IPlaylistCacheInstanceContent } from '../playlist/playlist-cache-instance-content.model';
import * as Enumerable from 'linq-es2015';
import { environment } from 'environments/environment';
import { DeviceProgram, IDeviceProgramResult } from './device-program.model';

interface IPlaylistInstancesItemNew {
  id: number;
  content: IPlaylistCacheInstanceContent[];
}

interface IPlaylistSequenceItemNew {
  id: number;
  playlist_cache_id: number;
  playlist_cache_instance_id: number;
  start: string;
  stop: string;
}

interface IPlaylistItemNew {
  instances: IPlaylistInstancesItemNew[];
  sequence: IPlaylistSequenceItemNew[];
}

@Injectable()
export class DeviceDataService extends BaseListableDataService<Device, IDevice, IDeviceLookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'device', ObjectType.DEVICE, ['channels', 'location', 'screengroups', 'devices', 'auditoriums', 'timeranges', 'tags'],
      ['channels', 'screengroups', 'devices', 'extContentSources', 'auditoriums', 'timeranges', 'salespoints', 'tags']);

  }

  public typelookup$: Observable<ILookup[]>;
  public masterSlaveDevicesLookup$: Observable<ILookup[]>;
  public txTunerDevicesLookup$: Observable<ILookup[]>;
  public rxSignageDevicesLookup$: Observable<ILookup[]>;
  public contentSourceDevicesLookup$: Observable<ILookup[]>;
  public lookupForTuners$: Observable<ILookup[]>;

  public lookupItemsOnlyAvailable$: Observable<ILookup[]>;

  public resetCachedObservables() {
    super.resetCachedObservables();
  }

  // override lookupItems to get the right name from groups
  public buildLookupItems() {
    super.buildLookupItems();

    this.typelookup$ = this.getTypes().pipe(share());

    this.lookupItemsOnlyAvailable$ = this.getLookupOnlyAvailable().pipe(share());

    this.masterSlaveDevicesLookup$ = this.getDevicesForRelation(DeviceDeviceRelationType.Master_Slave).pipe(share());

    this.txTunerDevicesLookup$ = this.getDevicesForRelation(DeviceDeviceRelationType.Tx_Tuner).pipe(share());

    this.rxSignageDevicesLookup$ = this.getDevicesForRelation(DeviceDeviceRelationType.Rx_SignageMonitor).pipe(share());

    this.contentSourceDevicesLookup$ = this.getDevicesForRelation(DeviceDeviceRelationType.Content_Source).pipe(share());


    this.lookupForTuners$ = this.getLookup(`type_id=${DeviceType.Tuner}`).pipe(share());

  }

  public getLookupOnlyAvailable() {
    return super.getLookup('max_channels_not_reached=1');
  }

  public getPlaylist(deviceID: number): Observable<IPlaylistItem[]> {
    return this.getPlaylistData(deviceID).pipe(map((x) => x)
      , tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to get ${this.resourceDisplayName} playlist data`, 'Error!');
      }));
  }

  public cleanPlaylist(device: Device): Observable<any> {

    if (device.optionWebPlayerVersionCurrent && device.optionWebPlayerVersionCurrent.toLowerCase() === 'v2') {

      return this.cleanPlaylistByID(device.id);
      
    } else {
      return this.generateV3PlaylistData(device.dtm.deviceChannels[0].id).pipe(tap((value) => {
        this.toastr.success(`${this.resourceDisplayName} Generate playlist`, 'Success!');
      }, (error) => {
        this.toastr.error(`Failed to generate ${this.resourceDisplayName} playlist`, 'Error!');
      }, () => {
        this.resetCachedObservables();
      }));

    }



  }

  public cleanPlaylistByID(deviceID: number): Observable<any> {


    return this.cleanPlaylistData(deviceID).pipe(tap((value) => {
      this.toastr.success(`${this.resourceDisplayName} clean playlist`, 'Success!');
    }, (error) => {
      this.toastr.error(`Failed to clean ${this.resourceDisplayName} playlist`, 'Error!');
    }, () => {
      this.resetCachedObservables();
    }));


  }

  // public resetCache(id: number): Observable<any> {
  //   return this.resetCacheData(id).pipe(tap((value) => {
  //     this.toastr.success(`${this.resourceDisplayName} reset cache`, 'Success!');
  //   }, (error) => {
  //     this.toastr.error(`Failed to reset ${this.resourceDisplayName} cache`, 'Error!');
  //   }, () => {
  //     this.resetCachedObservables();
  //   }));
  // }

  // public getProgram(deviceID: number): Observable<IPlaylistItem[]> {
  //   return this.getProgramData(deviceID).pipe(map((x) => x)
  //     , tap((value) => {
  //       // comment
  //     }, (error) => {
  //       this.toastr.error(`Failed to get ${this.resourceDisplayName} program data`, 'Error!');
  //     }));
  // }

  protected getPlaylistData(deviceID: number): Observable<IPlaylistItem[]> {
    const url = `${this.apiBaseUrl}/${this.resourceName}/${deviceID}/playlist`;
    return this.http.get<IPlaylistItemNew>(url, {
      headers: this.headers
    }).pipe(map((items) => {
      const mapped: IPlaylistItem[] = [];

      items.sequence.forEach((sequenceItem) => {
        const instance = Enumerable.AsEnumerable(items.instances).FirstOrDefault((x) => x.id === sequenceItem.playlist_cache_instance_id);
        if (instance) {
          if (instance.content && instance.content.length > 0) {
            instance.content.forEach((content) => {
              mapped.push({
                id: sequenceItem.id,
                start: sequenceItem.start,
                stop: sequenceItem.stop,
                playlistCacheInstanceContent: content
              });
            });
          } else {
            console.error('Instance with no content found: ' + sequenceItem.playlist_cache_instance_id);
          }
        } else {
          console.error('Instance not found: ' + sequenceItem.playlist_cache_instance_id);
        }

      });

      return mapped;
    }), catchError((err, caught) => this.handleError(err, caught)));
  }

  // protected getProgramData(deviceID: number): Observable<IPlaylistItem[]> {
  //   const url = `${this.apiBaseUrl}/${this.resourceName}/${deviceID}/program`;
  //   return this.http.get<IPlaylistItem[]>(url, {
  //     headers: this.headers
  //   }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  // }

  protected cleanPlaylistData(id: number): Observable<any> {
    const url = `${this.apiBaseUrl}/${this.resourceName}/${id}/playlist-clean`;
    return this.http.delete(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  protected generateV3PlaylistData(id: number): Observable<any> {
    // TODO: add a proper way
    const url = `${environment.PLAYLIST_TEST_URL}/generate?deviceChannelID=${id}`;
    return this.http.get(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  // protected resetCacheData(id: number): Observable<any> {
  //   const url = `${this.apiBaseUrl}/${this.resourceName}/${id}/cache-reset`;
  //   return this.http.delete(url, {
  //     headers: this.headers
  //   }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  // }

  public getDevicesForRelation(relationType: DeviceDeviceRelationType): Observable<ILookup[]> {
    return this.getDevicesForRelationData(relationType).pipe(map((x) => x.data)
      , tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to get devices for relation`, 'Error!');
      }));
  }

  protected getDevicesForRelationData(relationType: DeviceDeviceRelationType): Observable<IListData<ILookup>> {

    // will get devices with type that is from the right side of relation_type
    const url = `${this.apiBaseUrl}/${this.resourceName}/lookup?relation_type=${relationType}`;
    return this.http.get<IListData<ILookup>>(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  public changeDeviceType(deviceID: number, deviceType: DeviceType, alertSucces = false): Observable<IDevice> {
    return this.patch(deviceID, { type_id: deviceType } as IDevice);
  }


  public getDevicePrograms(id: number): Observable<DeviceProgram[]> {

    // will get devices with type that is from the right side of relation_type
    const url = `${this.apiBaseUrl}/${this.resourceName}/${id}/programs`;
    return this.http.get<IDeviceProgramResult>(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)), map((x) => {

      const items: DeviceProgram[] = [];
      const schedules = Enumerable.AsEnumerable(x.schedule);
      x.programs.forEach((program) => {
        items.push(new DeviceProgram(program, schedules.FirstOrDefault((schedule) => schedule.program_id === program.id)));
      });
      return items;
    }),
      tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to get devices for relation`, 'Error!');
      }));
  }

}
