import { Injectable } from '@angular/core';
import * as Enumerable from 'linq-es2015';
import { Observable, Subject } from 'rxjs';
import { map, share, debounceTime } from 'rxjs/operators';

import { IObjectData } from '../data/base-data.service';
import { ResourceStatusTechUpload, ResourceMainType, ObjectType } from '../data/enums/enums';
import { ResourceDataService } from '../data/resource/resouce-data.service';
import { IResource, Resource } from '../data/resource/resource.model';
import { UploadItem, UploadItemType } from '../data/upload-item/upload-item.model';
import { IBlobProgress } from './blob-progress.model';
import { ToasterService } from './toaster.service';
import { UploadApiService } from './upload-api/upload-api.service';
import { UploadItemService } from './upload-item.service';
import { KeyValue } from '@angular/common';
import { ProcessApiJobHubService } from '../hub/processapi-job-hub.service';

// import { Subject} from "rxjs/Subject";
@Injectable()
export class ResourceUploadService {

  private resourceCreatedSubject = new Subject<Resource>();
  public resourceCreated$ = this.resourceCreatedSubject.asObservable().pipe(share());

  public currentResources: Resource[] = [];

  constructor(
    private resourceDataService: ResourceDataService,
    private uploadApiService: UploadApiService,
    private toaster: ToasterService, private uploadItemsService: UploadItemService,
    private processApiJobHubService: ProcessApiJobHubService) {

    // private clientDataService: ClientDataService, private xhrUploadService: XhrUploadService,
  }

  public addNewFiles(fileList: FileList, mainType: ResourceMainType, resourceTypeID: number = 0, uploadRelation: KeyValue<ObjectType, number> = null) {
    // const clientHash = this.clientDataService.sessionClient.hash;

    const uploadItems = this.uploadItemsService.createUploadItems(fileList, UploadItemType.Resource);

    uploadItems.forEach((uploadItem) => {
      uploadItem.uploadFunction = () => {
        return this.uploadFile(uploadItem, mainType, resourceTypeID, uploadRelation);
      };
    });

    this.uploadItemsService.addUploadItems(uploadItems);

  }

  private uploadFile(uploadItem: UploadItem, mainType: ResourceMainType, resourceTypeID: number = 0, uploadRelation: KeyValue<ObjectType, number> = null) {

    return this.loadNewResource(uploadItem, mainType, resourceTypeID, uploadRelation).toPromise()
      .then((resource: Resource) => {

        resource.status_tech_upload = ResourceStatusTechUpload.Uploading;
        return this.uploadFileBlobs(resource).catch((error) => {

          console.error(error);
          resource.status_tech_upload = ResourceStatusTechUpload.UploadError;
          this.toaster.error('Upload failed', 'Error!');

          this.resourceDataService.putUploadStatus(resource.id, ResourceStatusTechUpload.UploadError)
            .toPromise().then((newValue: IObjectData<IResource>) => {
              resource.update(newValue.data);
            });
          throw error;
        });

      });

  }

  private uploadFileBlobs(resource: Resource) {

    return this.uploadAllFileBlobAndNext(resource, resource.uploadItem.blobProgresses[0], resource.uploadItem.blobProgresses)
      .then((data) => {
        // this.pullResourceProcessData(resource, 20, 20);
        this.monitorResourceProcessData(resource);
      });
  }

  // clientHash: string,
  private uploadAllFileBlobAndNext(
    resource: Resource, blobProgress: IBlobProgress,
    allBlobProgress: IBlobProgress[]): Promise<Resource> {

    resource.isUploadingCalled = true;
    // this.mediaApiService. clientHash,
    return this.uploadApiService.uploadResourceFileBlob(resource.id,
      blobProgress.blobID, resource.uploadItem.blobProgresses.length,
      resource.uploadItem.mime_type, resource.uploadItem.name, blobProgress)
      .toPromise().then((result) => {
        if (result.resourceStatusTechUpload === 0) {
          resource.status_tech_upload = ResourceStatusTechUpload.Undefined;
        } else {
          resource.status_tech_upload = result.resourceStatusTechUpload;
        }

        blobProgress.progress = 100;
        let index = allBlobProgress.indexOf(blobProgress);

        resource.upload_progress_current = index + 1;

        if (index > -1) {
          const nextBlob = allBlobProgress[++index];
          if (nextBlob) {
            return this.uploadAllFileBlobAndNext(resource, nextBlob, allBlobProgress);
          }
        }

        return resource;
      });

  }

  private monitorResourceProcessData(resouce: Resource) {
    this.processApiJobHubService.jobs$.pipe(debounceTime(2000)).subscribe((jobs) => {
      const validJobs = Enumerable.AsEnumerable(jobs).Where((job) => job.isForResource() && job.targetID === resouce.id).ToArray();
      resouce.updateJobs(validJobs);
      if (validJobs.length) {
        this.resourceDataService.getProcessData(resouce.id).subscribe((x) => {
          resouce.updateProcessData(x);
        });
      }
    });
  }

  // private pullResourceProcessData(resource: Resource, retryTotal: number, retryCurrent: number) {
  //   if (retryCurrent > 0) {
  //     this.resourceDataService.getProcessData(resource.id).pipe(delay(retryTotal * 1000 / retryCurrent)).subscribe((x) => {
  //       resource.updateProcessData(x);
  //       if (!resource.hasThumbanils || (!resource.isProcessed && !resource.isProcessError)) {
  //         this.pullResourceProcessData(resource, retryTotal, --retryCurrent);
  //       }
  //     });

  //   }
  // }

  private loadNewResource(
    uploadItem: UploadItem, mainType: ResourceMainType,
    resourceTypeID: number = 0, uploadRelation: KeyValue<ObjectType, number> = null): Observable<Resource> {

    const resource = new Resource();
    resource.name = uploadItem.name;
    resource.mimeType = uploadItem.mime_type;
    resource.type = mainType;
    resource.uploadRelation = uploadRelation;
    resource.setUploadItem(uploadItem);

    return this.resourceDataService.init({
      name: resource.name,
      type_id: resource.type,
      resource_type_id: resourceTypeID,
      event_id: uploadRelation && uploadRelation.key === ObjectType.EVENT ? uploadRelation.value : null,
      adspace_id: uploadRelation && uploadRelation.key === ObjectType.AD_SPACE ? uploadRelation.value : null,
    }).pipe(map((x) => {
      x.upload_progress_total = resource.uploadItem.blobProgresses.length;
      resource.update(x);

      this.currentResources.push(resource);

      this.resourceCreatedSubject.next(resource);
      return resource;
    }));
  }
}
