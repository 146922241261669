import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { SpotApp, ISpotApp } from './spot-app.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class SpotAppDataService extends BaseListableDataService<SpotApp, ISpotApp, ILookup> {

    constructor(http: HttpClient, toastr: ToasterService) {
        super(http, toastr, 'spot-app', ObjectType.SPOT_APP, [], []);

    }

}
