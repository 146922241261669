
import { Status, StatusSystem, PropertyIntentID, ObjectType } from '../enums/enums';
import { IBaseRelatedConfModel, BaseRelatedConfModel } from '../base-relatedconf.model';
import { PropertySet } from '../property/property-set.model';
import * as Enumerable from 'linq-es2015';

export interface IAuditorium extends IBaseRelatedConfModel {
    description: string;
    ext_id: string;
    // location_id: number;
    // location: ILocationMy;
}

export class Auditorium extends BaseRelatedConfModel<IAuditorium> {
    constructor(dtm: IAuditorium = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            description: '',
            ext_id: '',
            // location_id: 0,
            // location: null,
            related_conf_base_id: 0,
            relatedConfBase: null,
            property_set: null,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get description() {
        return this.dtm.description;
    }
    public set description(value) {
        this.dtm.description = value;
    }
    
    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    private _titleProperty: PropertySet;
    public get titleProperty() {
        if (!this._titleProperty && this.propertySet) {
            this._titleProperty = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.GeneralTitle || x.intent.id === PropertyIntentID.AuditoriumName);
        }
        return /*fix*/ this._titleProperty;
    }

    private _locationProperty: PropertySet;
    public get locationProperty() {
        if (!this._locationProperty && this.propertySet) {
            this._locationProperty = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.AuditoriumLocation);
        }
        return /*fix*/ this._locationProperty;
    }

    private _locationName: string;
    public get locationName() {
        if (this.locationProperty && !this._locationName) {
            this._locationName = Enumerable.AsEnumerable(this._locationProperty.values)
                .Where((x) => !!x.firstContentDataName).Select((x) => x.firstContentDataName).ToArray().join(', ');
        }
        return /*fix*/ this._locationName;
    }

    public update(dtm: IAuditorium, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    protected clearLocalProps() {
        super.clearLocalProps();

        // this._locationLookup = null;
        this._titleProperty = null;
        this._locationProperty = null;
        this._locationName = null;
    }

    public cleanForSave(dtm: IAuditorium, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
    }
}
