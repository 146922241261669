import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ITimeRange, TimeRange } from './time-range.model';
import { ObjectType } from '../enums/enums';
import { PropertyUpdateMode } from '../base.model';

export interface ITimeRangeRelation extends IBaseRelationModel {
    object_id: number;
    object_type_id: ObjectType;
    timerange_id: number;
    timerange: ITimeRange;
}

export class TimeRangeRelation extends BaseRelationModel<ITimeRangeRelation> {
    constructor(dtm: ITimeRangeRelation = null) {
        super(dtm || {
            object_id: 0,
            object_type_id: ObjectType.UNKNOWN,
            timerange_id: 0,
            timerange: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get objectID() {
        return this.dtm.object_id;
    }
    public set objectID(value) {
        this.dtm.object_id = value;
    }

    public get objectTypeID() {
        return this.dtm.object_type_id;
    }
    public set objectTypeID(value) {
        this.dtm.object_type_id = value;
    }

    public get timeRangeID() {
        return this.dtm.timerange_id;
    }
    public set timeRangeID(value) {
        this.dtm.timerange_id = value;
    }



    public get timeRange() { return this.getModelProperty(() => this.dtm.timerange, (x) => new TimeRange(x), PropertyUpdateMode.Update); }
    public set timeRange(value) { this.setModelProperty(() => this.dtm.timerange, (x) => this.dtm.timerange = x, value, PropertyUpdateMode.Update); }


    public update(dtm: ITimeRangeRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.timerange, (x) => new TimeRange(x), (x) => this.timeRange = x, forceReplaceDtm);

    }

    public cleanForSave(dtm: ITimeRangeRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        if (saver === ObjectType.DEVICE || saver === ObjectType.LOCATION || saver === ObjectType.MEDIA) { // delete on device, location, media
            delete dtm.timerange;
        }
    }

    public static getInstance(objectID: number, timerangeID: number){ // , objectType: ObjectType) {
        const empty = new TimeRangeRelation();
        empty.timeRangeID = timerangeID;
        empty.objectID = objectID;
        // empty.objectTypeID = objectType;
        return empty;
    }
}
