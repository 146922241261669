import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookupRestrict } from '../lookup-restrict.model';
import { ToasterService } from '../../services/toaster.service';

import { IAdSpace, AdSpace } from './ad-space.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class AdSpaceDataService extends BaseListableDataService<AdSpace, IAdSpace, ILookupRestrict> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'aceofspades', ObjectType.AD_SPACE, ['spotApp', 'resources'], ['spotApp', 'resources']);
    // "adspace" in request urls will trigger some ad blockers so we use "aceofspades"
    this.resourceDisplayName = "Adspace"
  }

}
