import { BaseStatusModel, IBaseStatusModel } from './base-status.model';

export interface IBaseNameModel extends IBaseStatusModel {
    name: string;
}

export abstract class BaseNameModel<IT extends IBaseNameModel> extends BaseStatusModel<IT> {
    constructor(dtm: IT) {
        super(dtm);

    }

    public get name() {
        return this.dtm.name;
    }
    public set name(value) {
        this.dtm.name = value;
    }

}
