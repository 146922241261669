import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { BaseNameModel, IBaseNameModel } from '../base-name.model';

export interface ISystemLanguage extends IBaseNameModel {
    language_code: string;
    is_default: number;
    ext_id: string;
}

export class SystemLanguage extends BaseNameModel<ISystemLanguage> {
    constructor(dtm: ISystemLanguage = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            language_code: '',
            ext_id: '',
            is_default: 0,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }

    public get code() {
        return this.dtm.language_code;
    }
    public set code(value) {
        this.dtm.language_code = value;
    }

    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    public get is_default() {
        return this.dtm.is_default > 0;
    }
    public set is_default(value) {
        this.dtm.is_default = value ? 1 : 0;
    }

    // public get isSystemObject() {
    //     return true;
    // }

    public update(dtm: ISystemLanguage, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: ISystemLanguage, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
    }
}
