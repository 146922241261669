import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObjectData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { MediaVersionStatusTechProcess, MediaVersionStatusTechUpload, ObjectType } from '../enums/enums';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IMediaVersion, IMediaVersionInitUpload, MediaVersion } from './media-version.model';

@Injectable()
export class MediaVersionDataService extends BaseListableDataService<MediaVersion, IMediaVersion, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'media-version', ObjectType.MEDIA_VERSION, ['tags', 'target-audience'], ['tags', 'target-audience']);
  }

  public initUpload(data: IMediaVersionInitUpload, mediaID: number): Observable<IMediaVersion> {
    return this.initUploadData(data, mediaID).pipe(map((x) => x.data),
      tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to init upload ${this.resourceDisplayName}`, 'Error!');
      }, () => {
        // comment
      }));
  }

  // public put(id: number, dtm: IMediaVersion, alertSucces = false): Observable<IMediaVersion> {
  //   return super.put(id, dtm, alertSucces);
  // }

  public getProcessData(id: number): Observable<IMediaVersion> {
    return super.get(id);
  }

  public putUploadStatus(id: number, status: MediaVersionStatusTechUpload): Observable<IObjectData<IMediaVersion>> {

    return this.http.patch<IObjectData<IMediaVersion>>(`${this.apiBaseUrl}/${this.resourceName}/${id}`, { status_tech_upload: status }, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  public putProcessStatus(id: number, status: MediaVersionStatusTechProcess): Observable<IObjectData<IMediaVersion>> {

    return this.http.patch<IObjectData<IMediaVersion>>(`${this.apiBaseUrl}/${this.resourceName}/${id}`, { status_tech_process: status }, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  private initUploadData(data: any, parentId: number): Observable<IObjectData<IMediaVersion>> {
    let query = '';
    if (parentId && parentId > 0) {
      query = `${query}&parent_id=${parentId}`;
    }

    // if (addToInbox) {
    //   query = `${query}&add_to_inbox=1`;
    // }

    query = this.fixQueryStringStart(query);

    return this.http.post<IObjectData<IMediaVersion>>(`${this.apiBaseUrl}/${this.resourceName}/init-upload${query}`, data, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }
}
