import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from '../base-data.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from '../../services/toaster.service';

interface ILogin {
  access_token: string;
}

@Injectable()
export class AuthDataService extends BaseDataService {

  constructor(private http: HttpClient, toastr: ToasterService) {
    super(toastr);
  }

  public login(password: string, username: string): Observable<string> {

    // return Observable.of(true).delay(1000);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      password,
      username
    });

    return this.http.post<ILogin>(`${this.apiBaseUrl}/auth/login`, null, {
      headers
    }).pipe(map((res) => {
      return res.access_token;
    }), catchError((err, caught) => this.handleError(err, caught)));
  }

  public logout(): Observable<boolean> {

    return this.http.post(`${this.apiBaseUrl}/auth/logout`, null, {
      headers: this.headers
    }).pipe(map((res: Response) => true), catchError((err, caught) => this.handleError(err, caught)));
  }

  public forgot(email: string): Observable<boolean> {

    // return Observable.of(true).delay(1000);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<boolean>(`${this.apiBaseUrl}/password/reset-password`, {
      email
    }, {
      headers
    }).pipe(map((res) => {
      return res;
    }), catchError((err, caught) => this.handleError(err, caught)));
  }

  public change(token: string, password: string, passwordConfirm: string): Observable<boolean> {

    // return Observable.of(true).delay(1000);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'password-reset-token': token
    });

    return this.http.post<boolean>(`${this.apiBaseUrl}/password/set-password`, {
      password,
      password_confirmation: passwordConfirm
    }, {
      headers
    }).pipe(map((res) => {
      return res;
    }), catchError((err, caught) => this.handleError(err, caught)));
  }

}
