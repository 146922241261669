import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ObjectType } from '../enums/enums';
import { Device, IDevice } from '../device/device.model';
import { PropertyUpdateMode } from '../base.model';

export interface IScreenGroupDeviceRelation extends IBaseRelationModel {
    device_id: number;
    screengroup_id: number;
    device: IDevice;
}

export class ScreenGroupDeviceRelation extends BaseRelationModel <IScreenGroupDeviceRelation> {
    constructor(dtm: IScreenGroupDeviceRelation = null) {
        super(dtm || {
            device_id: 0,
            screengroup_id: 0,
            device: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get device_id() {
        return this.dtm.device_id;
    }
    public set device_id(value) {
        this.dtm.device_id = value;
    }

    public get screengroup_id() {
        return this.dtm.screengroup_id;
    }
    public set screengroup_id(value) {
        this.dtm.screengroup_id = value;
    }

    public get deviceName() {
        if (this.dtm.device) {
            return this.dtm.device.name;
        }
        return null;
    }

    public get device() { return this.getModelProperty(() => this.dtm.device, (x) => new Device(x), PropertyUpdateMode.Update); }
    public set device(value) { this.setModelProperty(() => this.dtm.device, (x) => this.dtm.device = x, value, PropertyUpdateMode.Update); }

    public update(dtm: IScreenGroupDeviceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.device, (x) => new Device(x), (x) => this.device = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: IScreenGroupDeviceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.device;

    }

    public static getInstance(screengroup_id: number, device_id: number){
        const empty = new ScreenGroupDeviceRelation();
        empty.screengroup_id = screengroup_id;
        empty.device_id = device_id;
        return empty;
    }
}
