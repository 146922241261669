import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ILocationMy, LocationMy } from '../location/location.model';
import { ObjectType } from '../enums/enums';

export enum LocationMode { AllAllowed = 0, AllowSelected = 1, RestrictSelected = 2 }

export interface IMediaLocationRelation extends IBaseRelationModel {
    media_id: number;
    location_id: number;
    is_restricted: number;
    location: ILocationMy;
}

export class MediaLocationRelation extends BaseRelationModel <IMediaLocationRelation> {
    constructor(dtm: IMediaLocationRelation = null) {
        super(dtm || {
            media_id: 0,
            location_id: 0,
            is_restricted: 0,
            location: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get location_id() {
        return this.dtm.location_id;
    }
    public set location_id(value) {
        this.dtm.location_id = value;
    }

    public get is_restricted() {
        return this.dtm.is_restricted === 1;
    }
    public set is_restricted(value) {
        this.dtm.is_restricted = value ? 1 : 0;
    }

    public update(dtm: IMediaLocationRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IMediaLocationRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.location;
    }

    public static getInstance(media_id: number, location_id: number, is_restricted: boolean){
        const empty = new MediaLocationRelation();
        empty.media_id = media_id;
        empty.location_id = location_id;
        empty.is_restricted = is_restricted;
        return empty;
    }
}
