import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { IBlobProgress } from './blob-progress.model';

@Injectable()
export class XhrUploadService {
  constructor() {
    // this.progress$ = Observable.create(observer => {
    //   this.progressObserver = observer
    // }).pipe(share());
  }

  private progress: number;
  private progressSubject = new Subject<IBlobProgress>();
  public progress$ = this.progressSubject.asObservable();

  public makeFileRequest<T>(url: string, formData: FormData, headers: HttpHeaders, blob: IBlobProgress): Observable<T> {

    const promise = new Promise<T>((resolve, reject) => {
      this.runRequest(url, formData, headers, blob, resolve, reject, 10);
    });

    return from(promise).pipe(share());
  }

  private runRequest<T>(
    url: string, formData: FormData, headers: HttpHeaders, blob: IBlobProgress,
    resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void, retries: number) {
    const xhr: XMLHttpRequest = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.response));
        } else if (xhr.status === 408 && retries--) { // 408 -  Request Timeout
          this.runRequest(url, formData, headers, blob, resolve, reject, retries);
        } else {
          reject(xhr.response);
        }
      }
    };

    xhr.upload.onprogress = (event) => {
      this.progress = Math.round(event.loaded / event.total * 100);

      blob.progress = this.progress;
      this.progressSubject.next(blob);
    };

    xhr.open('POST', url, true);

    // You must call setRequestHeader()after open(), but before send()
    headers.keys().forEach((key) => {
      const value = headers.get(key);
      xhr.setRequestHeader(key, value);
    });
    xhr.send(formData);
  }
}
