import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { IScreenMy, ScreenMy } from './screen.model';
import { ToasterService } from '../../services/toaster.service';
import * as Enumerable from 'linq-es2015';
import { map, share } from 'rxjs/operators';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ScreenDataService extends BaseListableDataService<ScreenMy, IScreenMy, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'screen', ObjectType.SCREEN, ['screengroups'], ['screengroups']);
  }

  // override lookupItems to get the right name from groups
  public buildLookupItems() {
    super.buildLookupItems();

    this.lookupItems$ = this.getPage(null, null, null, null).pipe(map((res) => {
      return Enumerable.AsEnumerable(res.data).Select((item) => {
        const model = new ScreenMy(item);
        return { id: model.id, name: model.display_name};
      }).ToArray();
    }), share());

  }

}
