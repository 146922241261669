import { EventBase, IEventBase } from './event.model';
import { RegularEventShow } from '../show/regular-event-show.model';

export class RegularEvent extends EventBase<RegularEventShow> {
    constructor(dtm: IEventBase = null) {
        super(dtm, (x) => new RegularEventShow(x));
    }

    protected clearLocalProps() {
        super.clearLocalProps();

    }

}
