import { HttpClient } from '@angular/common/http';
import { BaseDetailDataService } from './base-detail-data.service';
import { ILookup } from './lookup.model';
import { ToasterService } from '../services/toaster.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, map, share, switchMap, tap } from 'rxjs/operators';

import { IListData } from './base-data.service';
import { FilterMy } from './filter/filter.model';
import { SortMy } from './sort/sort.model';
import { DataServiceType, ObjectType } from './enums/enums';
import { BaseIdModel, IBaseIdModel } from './base-id.model';
import { BaseModel } from './base.model';
import * as Enumerable from 'linq-es2015';

export interface ILookupable<Lmodel> {
    lookupItems$: Observable<Lmodel[]>;
}

export class BaseListableDataService<Tmodel extends BaseModel<Tdtm>, Tdtm, Lmodel extends ILookup>
    extends BaseDetailDataService<Tmodel, Tdtm> implements ILookupable<Lmodel> {
    // public items$ = this.itemsSubject.asObservable();

    constructor(
        http: HttpClient, toastr: ToasterService, resourceName: string, objectType: ObjectType,
        public listIncludes: string[], detailIncludes: string[], dataServiceType: DataServiceType = 'default') {
        super(http, toastr, resourceName, objectType, detailIncludes, dataServiceType);

        this.buildLookupItems();
    }
    // public items: Tmodel[] = [];

    protected requiredfilters: FilterMy[];

    public items$ = new BehaviorSubject<Tmodel[]>([]);

    public lookupItems$: Observable<Lmodel[]>;

    public relatedConfBaseLookup$: Observable<ILookup[]>;

    protected resetCachedObservables() {
        super.resetCachedObservables();
        this.buildLookupItems();

    }
    protected buildLookupItems() {
        this.lookupItems$ = this.getLookup().pipe(share());
        this.relatedConfBaseLookup$ = this.getPropertySet().pipe(share());
    }

    public getPropertySet(): Observable<ILookup[]> {
        return this.getPropertySetData().pipe(map((x) => x.data)
            , tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} property set data`, 'Error!');
            }));
    }

    protected getPropertySetData(): Observable<IListData<ILookup>> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/get-related-conf-set`;
        return this.http.get<IListData<ILookup>>(url, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    public search(terms: Observable<string>, query = '', minTermLength = 1, loadStarted: () => void = null): Observable<Lmodel[]> {

        return terms.pipe(debounceTime(400),
            distinctUntilChanged(),
            tap(() => { if (loadStarted) { loadStarted(); } }),
            switchMap((term) => {

                if (term && term !== '*Clear') {
                    let realTerm = term;
                    if (term === '*All') {
                        realTerm = '';
                    }

                    if (term.length >= minTermLength) {
                        // if (onStartedLoading)
                        //     onStartedLoading();
                        return this.getLookupDatas(null, null, null, null, null, realTerm, query).pipe(map((x) => x.data),
                            tap((value) => {
                                // comment
                            }, (error) => {
                                this.toastr.error(`Failed search ${this.resourceDisplayName} data`, 'Error!');
                            }));
                    }
                }
                return of([]);
            }));

    }

    public searchPaged(
        termStream: Observable<string>, pageIndexStream: Observable<number>,
        pageSize: number, query = '', minTermLength = 1, loadStarted: () => void = null): Observable<IListData<Lmodel>> {

        const joint = combineLatest([termStream, pageIndexStream]);

        return joint.pipe(debounceTime(400), distinctUntilChanged(),
            tap(() => { if (loadStarted) { loadStarted(); } }),
            switchMap((joined) => {
                const term = joined[0];
                const pageNr = joined[1] + 1;

                if (term && term !== '*Clear') {
                    let realTerm = term;
                    if (term === '*All') {
                        realTerm = '';
                    }

                    if (term.length >= minTermLength) {
                        return this.getLookupDatas(pageNr, pageSize, null, null, null, realTerm, query).pipe(tap((value) => {
                            // comment
                        }, (error) => {
                            this.toastr.error(`Failed search ${this.resourceDisplayName} data`, 'Error!');
                        }));
                    }
                }

                return of({ totalCount: 0, data: [], filter: [] });
            }));
    }

    public getLookup(query: string = '', pageNr: number = 0, pageSize: number = 0): Observable<Lmodel[]> {
        return this.getLookupDatas(pageNr, pageSize, null, null, null, null, query).pipe(map((x) => x.data),
            tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed search ${this.resourceDisplayName} data`, 'Error!');
            }));
    }

    public getPage(
        pageNr: number, pageSize: number,
        filters: FilterMy[], sorts: SortMy[], search = '', query = '',
        subResourceName = '', listIncludesAdd: string[] = []): Observable<IListData<Tdtm>> {
        let includes = this.listIncludes;
        if (listIncludesAdd) {
            includes = this.listIncludes.concat(listIncludesAdd);
        }
        return this.getDatas(pageNr, pageSize, filters, sorts, includes, search, query, subResourceName)
            .pipe(tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} paged data`, 'Error!');
            }));
    }

    // protected getLookupDatas2(term: string, query: string, pageNr: number, pageSize: number): Observable<IListData<Lmodel>> {
    //     let addQuery = '';

    //     if (term && term.length) {
    //         addQuery = `&search=${term}`;
    //     }

    //     if (query && query.length) {
    //         addQuery = `${addQuery}&${query}`;
    //     }

    //     if (pageSize) {
    //         const top = pageSize;
    //         let skip = 0;
    //         if (pageNr > 0) {
    //             skip = ((pageNr - 1) * pageSize);
    //         }
    //         addQuery = `${addQuery}&$skip=${skip}&$top=${top}`;
    //     }

    //     addQuery = this.fixQueryStringStart(addQuery);

    //     return this.getLookupDatas(addQuery);
    // }

    // protected getLookupDatas(addQuery: string): Observable<IListData<Lmodel>> {
    //     const url = `${this.apiBaseUrl}/${this.resourceName}/lookup${addQuery}`;
    //     return this.http.get<IListData<Lmodel>>(url, {
    //         headers: this.headers
    //     }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    // }

    protected getLookupDatas(
        pageNr: number, pageSize: number,
        filters: FilterMy[], sorts: SortMy[], includes: string[],
        search = '', query = '', subResourceName = ''): Observable<IListData<Lmodel>> {

        let pageQuery = '';
        if (pageNr !== null && pageSize != null) {
            let skip = 0;
            if (pageNr > 0) {
                skip = ((pageNr - 1) * pageSize);
            }
            if (skip > 0 || pageSize > 0) {
                pageQuery = `?$skip=${skip}&$top=${pageSize}`;
            }
        }

        const filterQuery = this.getFilterQueryString('&', filters);

        const includeQuery = this.getIncludeQueryString('&', includes);

        const sortQuery = this.getSortQueryString('&', sorts);

        // &$include[]=screengroups

        let searchQuery = '';
        if (search && search.length) {
            searchQuery = `&search=${search}`;
        }
        if (!query) {
            query = '';
        } else {
            query = query.trim();
            if (query.indexOf('?') === 0 || query.indexOf('&') === 0) {
                query = query.substr(1);
            }
            query = `&${query}`;
        }

        let addQuery = `${pageQuery}${searchQuery}${includeQuery}${filterQuery}${sortQuery}${query}`;
        addQuery = this.fixQueryStringStart(addQuery);

        let subResource = '';
        if (subResourceName && subResourceName.length) {
            subResource = '/' + subResourceName;
        }

        const url = `${this.apiBaseUrl}/${this.resourceName}${subResource}/lookup${addQuery}`;
        return this.http.get<IListData<Lmodel>>(url.replace(/\?$/, '').replace(/&$/, ''), {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected getDatas(
        pageNr: number, pageSize: number,
        filters: FilterMy[], sorts: SortMy[], includes: string[],
        search = '', query = '', subResourceName = ''): Observable<IListData<Tdtm>> {


        if (this.requiredfilters) {
            if (!filters) {
                filters = [];
            }

            this.requiredfilters.forEach(requiredfilter => {
                var match = Enumerable.from(filters).FirstOrDefault(x => x.key === requiredfilter.key);
                if (match) {
                    filters.splice(filters.indexOf(match), 1);
                }
                filters.push(requiredfilter);
            });
        }

        let pageQuery = '';
        if (pageNr !== null && pageSize != null) {
            let skip = 0;
            if (pageNr > 0) {
                skip = ((pageNr - 1) * pageSize);
            }
            pageQuery = `?$skip=${skip}&$top=${pageSize}`;
        }

        const filterQuery = this.getFilterQueryString('&', filters);

        const includeQuery = this.getIncludeQueryString('&', includes);

        const sortQuery = this.getSortQueryString('&', sorts);

        // &$include[]=screengroups

        let searchQuery = '';
        if (search && search.length) {
            searchQuery = `&$search=${search}`;
        }
        if (!query) {
            query = '';
        } else {
            query = query.trim();
            if (query.indexOf('?') === 0 || query.indexOf('&') === 0) {
                query = query.substr(1);
            }
            query = `&${query}`;
        }

        let addQuery = `${pageQuery}${searchQuery}${includeQuery}${filterQuery}${sortQuery}${query}`;
        addQuery = this.fixQueryStringStart(addQuery);

        let subResource = '';
        if (subResourceName && subResourceName.length) {
            subResource = '/' + subResourceName;
        }

        const url = `${this.apiBaseUrl}/${this.resourceName}${subResource}${addQuery}`;
        return this.http.get<IListData<Tdtm>>(url.replace(/\?$/, '').replace(/&$/, ''), {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    // protected getAllDatas(includes: string[], query: string): Observable<IListData<Tdtm>> {

    //     let addQuery = this.getIncludeQueryString('?', includes);

    //     if (query && query.length) {
    //         addQuery = `${addQuery}&${query}`;
    //     }

    //     addQuery = this.fixQueryStringStart(addQuery);

    //     const url = `${this.apiBaseUrl}/${this.resourceName}${addQuery}`;

    //     return this.http.get<IListData<Tdtm>>(url, {
    //         headers: this.headers
    //     }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    // }

    public getTypes(): Observable<ILookup[]> {
        return this.getTypesData().pipe(map((x) => x.data)
            , tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} types data`, 'Error!');
            }));
    }

    protected getTypesData(): Observable<IListData<ILookup>> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/get-types`;
        return this.http.get<IListData<ILookup>>(url, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }
}
