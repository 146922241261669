import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import * as Enumerable from 'linq-es2015';
import { ISystemLanguage, SystemLanguage } from '../language/system-language.model';
import { PropertyUpdateMode } from '../base.model';

export interface IClient extends IBaseNameModel {
    hash: string;
    email: string;
    system_languages: ISystemLanguage[];
}

export class Client extends BaseNameModel<IClient> {
    constructor(dtm: IClient = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            hash: '',
            name: '',
            email: '',
            system_languages: [],
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get hash() {
        return this.dtm.hash;
    }
    public set hash(value) {
        this.dtm.hash = value;
    }

    public get email() {
        return this.dtm.email;
    }
    public set email(value) {
        this.dtm.email = value;
    }


    public get systemLanguages() { return this.getModelArray(() => this.dtm.system_languages, (x) => new SystemLanguage(x), PropertyUpdateMode.Clear); }

    public update(dtm: IClient, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        sessionStorage.setItem('system_languages', JSON.stringify(dtm.system_languages));

    }

    protected clearLocalProps() {
        super.clearLocalProps();

    }

    public cleanForSave(dtm: IClient, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.system_languages;

    }
}
