import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { Channel, IChannel } from './channel.model';
import { DeviceDataService } from '../device/device-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ChannelDataService extends BaseListableDataService<Channel, IChannel, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService, private deviceDataService: DeviceDataService) {
    super(http, toastr, 'channel', ObjectType.CHANNEL, ['devices', 'screengroups'], ['devices', 'screengroups']);
  }

  public resetCachedObservables() {
    super.resetCachedObservables();
    this.deviceDataService.resetCachedObservables();
  }

}
