import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import {
    ChannelScreenGroupRelation,
    IChannelScreenGroupRelation,
} from '../channel/channel-screengroup-relation.model';
import {
    DeviceChannelScreenGroupRelation,
    IDeviceChannelScreenGroupRelation,
} from '../device-channel/device-channel-screengroup-relation.model';
import {
    DeviceScreenGroupRelation,
    IDeviceScreenGroupRelation,
} from '../device/device-screengroup-relation.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { ILocationMy } from '../location/location.model';
import { ILookup, IScreenGroupLookup } from '../lookup.model';
import { ScreenGroup } from '../screen-group/screen-group.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

export interface IScreenMy extends IBaseNameModel {
    channel_name: string;
    hash: string;
    description: string;
    width: number;
    height: number;
    rotation_container: number;
    option_set: string;
    location_id: number;
    location: ILocationMy;
    rotation_physical: number;
    rotation_os: number;
    rotation_accept_media: number;

    screengroupDeviceChannels: IDeviceChannelScreenGroupRelation[];
    screengroupChannels: IChannelScreenGroupRelation[];
    screengroupDevices: IDeviceScreenGroupRelation[];

}

export class ScreenMy extends BaseNameModel<IScreenMy> {
    constructor(dtm: IScreenMy = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            hash: '',
            name: '',
            channel_name: null, // should be null if we want to use 'name' value while creating
            description: '',
            width: 0,
            height: 0,
            rotation_container: 0,
            option_set: '',

            location_id: 0,
            location: null,
            rotation_physical: 0,
            rotation_os: 0,
            rotation_accept_media: 0,
            screengroupDeviceChannels: [],
            screengroupChannels: [],
            screengroupDevices: [],

            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }

    public get display_name() {
        // let acronym = this.group_acronym_name;
        // if (acronym.length)
        //     return `${acronym}-${this.name}`;
        return this.name;
    }

    public get channel_name() {
        return this.dtm.channel_name;
    }
    public set channel_name(value) {
        this.dtm.channel_name = value;
    }

    public get hash() {
        return this.dtm.hash;
    }
    public set hash(value) {
        this.dtm.hash = value;
    }

    public get description() {
        return this.dtm.description;
    }
    public set description(value) {
        this.dtm.description = value;
    }

    public get width() {
        return this.dtm.width;
    }
    public set width(value) {
        this.dtm.width = value;
    }

    public get height() {
        return this.dtm.height;
    }
    public set height(value) {
        this.dtm.height = value;
    }

    public get option_set() {
        return this.dtm.option_set;
    }
    public set option_set(value) {
        this.dtm.option_set = value;
    }

    public get rotation_physical() {
        return this.dtm.rotation_physical;
    }
    public set rotation_physical(value) {
        this.dtm.rotation_physical = value;
    }

    public get rotation_os() {
        return this.dtm.rotation_os;
    }
    public set rotation_os(value) {
        this.dtm.rotation_os = value;
    }

    public get rotation_container() {
        return this.dtm.rotation_container;
    }
    public set rotation_container(value) {
        this.dtm.rotation_container = value;
    }

    public get groups_name() {

        const rets: string[] = [];

        if (this.dtm.screengroupDevices) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDevices).Select((x) => x.screengroup).ToArray();
            const name = ScreenGroup.get_name(items);
            if (name && name.length) {
                rets.push(name);
            }
        }

        if (this.dtm.screengroupDeviceChannels) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDeviceChannels).Select((x) => x.screengroup).ToArray();
            const name = ScreenGroup.get_name(items);
            if (name && name.length) {
                rets.push(name);
            }
        }
        // if (this.dtm.screengroupChannels) {
        //     let items = Enumerable.AsEnumerable(this.dtm.screengroupChannels).Select(x => x.screengroup).ToArray();
        //     let name = ScreenGroup.get_name(items);
        //     if (name && name.length)
        //         rets.push(name);
        // }

        return rets.join(', ');
    }

    public get group_acronym_name() {

        const rets: string[] = [];

        if (this.dtm.screengroupDevices) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDevices).Select((x) => x.screengroup).ToArray();
            const name = ScreenGroup.get_acronym_name(items, true);
            if (name && name.length) {
                rets.push(name);
            }
        }

        if (this.dtm.screengroupDeviceChannels) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDeviceChannels).Select((x) => x.screengroup).ToArray();
            const name = ScreenGroup.get_acronym_name(items, true);
            if (name && name.length) {
                rets.push(name);
            }
        }
        // if (this.dtm.screengroupChannels) {
        //     let items = Enumerable.AsEnumerable(this.dtm.screengroupChannels).Select(x => x.screengroup).ToArray();
        //     let name = ScreenGroup.get_acronym_name(items);
        //     if (name && name.length)
        //         rets.push(name);
        // }

        return rets.join('-');
    }

    public get rotation_accept_media() {
        return this.dtm.rotation_accept_media;
    }
    public set rotation_accept_media(value) {
        this.dtm.rotation_accept_media = value;
    }

    public get locationName() {
        if (this.dtm.location) {
            return this.dtm.location.name;
        }
        return '';
    }

    public get locationLookup() { return this.getLookupProperty(() => this.dtm.location_id, (x) => this.createLookup(this.dtm.location_id, this.locationName)); }
    public set locationLookup(value) { this.setLookupProperty(() => this.dtm.location_id, (x) => { this.dtm.location_id = x; }, value, (item) => item.id); }


    public get screengroupDeviceChannels_lookups() {
        return this.getLookupArray(() => this.dtm.screengroupDeviceChannels, (x) => {
            return {
                id: x.screengroup_id,
                name: x.screengroup.name,
                type: x.screengroup.type,
                acronym: x.screengroup.acronym
            };
        });
    }
    public set screengroupDeviceChannels_lookups(value) { this.setLookupArray(() => this.dtm.screengroupDeviceChannels, (x) => this.dtm.screengroupDeviceChannels = x, value, (item) => DeviceChannelScreenGroupRelation.getInstance(this.id, item.id).dtm); }


    public get screengroupChannels_lookups() {
        return this.getLookupArray(() => this.dtm.screengroupChannels, (x) => {
            return {
                id: x.screengroup_id,
                name: x.screengroup.name,
                type: x.screengroup.type,
                acronym: x.screengroup.acronym
            };
        });
    }
    public set screengroupChannels_lookups(value) { this.setLookupArray(() => this.dtm.screengroupChannels, (x) => this.dtm.screengroupChannels = x, value, (item) => ChannelScreenGroupRelation.getInstance(this.id, item.id).dtm); }


    public get screengroupDevices_lookups() {
        return this.getLookupArray(() => this.dtm.screengroupDevices, (x) => {
            return {
                id: x.screengroup_id,
                name: x.screengroup.name,
                type: x.screengroup.type,
                acronym: x.screengroup.acronym
            };
        });
    }
    public set screengroupDevices_lookups(value) { this.setLookupArray(() => this.dtm.screengroupDevices, (x) => this.dtm.screengroupDevices = x, value, (item) => DeviceScreenGroupRelation.getInstance(this.id, item.id).dtm); }

    public update(dtm: IScreenMy, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IScreenMy, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.location;

        this.cleanDTMs(ChannelScreenGroupRelation, dtm.screengroupChannels, saver);
        this.cleanDTMs(DeviceChannelScreenGroupRelation, dtm.screengroupDeviceChannels, saver);
        this.cleanDTMs(DeviceScreenGroupRelation, dtm.screengroupDevices, saver);
    }

}
