import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILookup, IScreenGroupLookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { IListData } from '../base-data.service';
import { map } from 'rxjs/operators';
import { SortMy } from '../sort/sort.model';
import { FilterMy } from '../filter/filter.model';
import { ScreenGroupDataService } from './screen-group-data.service';
import { ILookupable } from '../base-listable-data.service';

@Injectable()
export class ScreenGroupTypeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor(screenGroupDataService: ScreenGroupDataService) {
        this.lookupItems$ = screenGroupDataService.typelookup$;
    }

}
