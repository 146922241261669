import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IMediaType, MediaType } from '../media-type/media-type.model';
import { ObjectType } from '../enums/enums';

export interface IMediaMediatypeRelation extends IBaseRelationModel {

    media_id: number;
    type_id: number;
    mediaType: IMediaType;
}

export class MediaMediatypeRelation extends BaseRelationModel <IMediaMediatypeRelation> {
    constructor(dtm: IMediaMediatypeRelation = null) {
        super(dtm || {
            media_id: 0,
            type_id: 0,
            mediaType: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get type_id() {
        return this.dtm.type_id;
    }
    public set type_id(value) {
        this.dtm.type_id = value;
    }

    public update(dtm: IMediaMediatypeRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IMediaMediatypeRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.mediaType;
    }

    public static getInstance(media_id: number, type_id: number){
        const empty = new MediaMediatypeRelation();
        empty.media_id = media_id;
        empty.type_id = type_id;
        return empty;
    }
}
