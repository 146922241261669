import { DeviceChannelPinpoint, IDeviceChannelPinpoint } from "./device-channel-pinpoint.model";
import { DeviceChannelSequence, IDeviceChannelSequence } from './device-channel-sequence.model';
import * as Enumerable from 'linq-es2015';

export interface IDeviceChannelSequenceData {
    pinpoints: IDeviceChannelPinpoint[];
    sequences: IDeviceChannelSequence[];
}



export class DeviceChannelSequenceData {
    constructor(dtm: IDeviceChannelSequenceData) {

        if (dtm && dtm.pinpoints) {
            dtm.pinpoints.forEach(pinpointDtm => {
                this.pinpoints.push(new DeviceChannelPinpoint(pinpointDtm))
            });
        }

 
        if (dtm && dtm.sequences) {

            dtm.sequences.forEach((sequence) => {
                const pinpoint = Enumerable.AsEnumerable( this.pinpoints).FirstOrDefault((x) => x.id === sequence.pi);
                if (pinpoint) {
                    this.sequences.push(new DeviceChannelSequence(sequence, pinpoint));
                }

            });


        }

    }


    public pinpoints: DeviceChannelPinpoint[] = [];
    public sequences: DeviceChannelSequence[] = [];




}
