import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class NotAuthGuardService  {
    constructor(private authService: AuthService, private router: Router) {
        // comment
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        return this.checkLogin(route);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): boolean {
        // let url = `/${route.path}`;
        return this.checkLogin(null);
    }

    private checkLogin(root: ActivatedRouteSnapshot): boolean {
        if (!this.authService.isLoggedIn) { return true; }

        if (root && root.queryParams && root.queryParams.redirectUrl) {
            const url = root.queryParams.redirectUrl;
            this.router.navigate([url]);
        } else {
            this.router.navigate(['/']);
        }

        return false;
    }
}
