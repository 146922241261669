import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ITag, Tag } from '../tag/tag.model';
import { ObjectType } from '../enums/enums';

export interface IMediaVersionTagRelation extends IBaseRelationModel {
    media_version_id: number;
    tag_id: number;
    tag: ITag;
}

export class MediaVersionTagRelation extends BaseRelationModel <IMediaVersionTagRelation> {
    constructor(dtm: IMediaVersionTagRelation = null) {
        super(dtm || {
            media_version_id: 0,
            tag_id: 0,
            tag: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get mediaVersion_id() {
        return this.dtm.media_version_id;
    }
    public set mediaVersion_id(value) {
        this.dtm.media_version_id = value;
    }

    public get tag_id() {
        return this.dtm.tag_id;
    }
    public set tag_id(value) {
        this.dtm.tag_id = value;
    }


    public update(dtm: IMediaVersionTagRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IMediaVersionTagRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.tag;
    }

    public static getInstance(mediaVersion_id: number, tag_id: number){
        const empty = new MediaVersionTagRelation();
        empty.mediaVersion_id = mediaVersion_id;
        empty.tag_id = tag_id;
        return empty;
    }

}
