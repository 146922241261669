import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToasterService } from '../toaster.service';
import { tap } from 'rxjs/operators';
import { AuthDataService } from 'app/core/data/auth/auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessApiService {
  private baseUrl = environment.VOOGSTV_PROCESSAPI_URL;
  private apiKey = environment.VOOGSTV_PROCESSAPI_KEY;
  constructor(private http: HttpClient, private toastr: ToasterService, private authDataService: AuthDataService) {

  }

  public reconvertMediaVersion(mediaVersionID: number) {
    const accessToken = this.authDataService.getDomainCookie('access-token');
    const headers = new HttpHeaders({
      'access-token': accessToken,
      'api-key': this.apiKey
    });
    return this.http.post(`${this.baseUrl}/mediaVersion/${mediaVersionID}`, null, {
      headers
    }).pipe(tap((value) => {
      this.toastr.success(`Reconvert task added to the que`, 'Success!');
    }, (error) => {
      this.toastr.error(`Failed to add reconvert task to the que`, 'Error!');
    }));
  }

  public reconvertResource(resourceID: number) {
    const accessToken = this.authDataService.getDomainCookie('access-token');
    const headers = new HttpHeaders({
      'access-token': accessToken,
      'api-key': this.apiKey
    });
    return this.http.post(`${this.baseUrl}/resource/${resourceID}`, null, {
      headers
    }).pipe(tap((value) => {
      this.toastr.success(`Reconvert task added to the que`, 'Success!');
    }, (error) => {
      this.toastr.error(`Failed to add reconvert task to the que`, 'Error!');
    }));
  }

  public reProcessFileMeta(fileID: number) {
    const accessToken = this.authDataService.getDomainCookie('access-token');
    const headers = new HttpHeaders({
      'access-token': accessToken,
      'api-key': this.apiKey
    });

    return this.http.post(`${this.baseUrl}/file/${fileID}`, null, {
      headers
    }).pipe(tap((value) => {
      this.toastr.success(`Reprocess task added to the que`, 'Success!');
    }, (error) => {
      this.toastr.error(`Failed to add Reprocess task to the que`, 'Error!');
    }));
  }
}
