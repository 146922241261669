import { BaseStatusModel, IBaseStatusModel } from '../base-status.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { BaseNameModel, IBaseNameModel } from '../base-name.model';

export interface IUser extends IBaseNameModel {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    password: string;
}

export class User extends BaseNameModel<IUser> {
    constructor(dtm: IUser = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            username: '',
            name: '', // not in use
            email: '',
            first_name: '',
            last_name: '',
            role: '',
            password: '',
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get username() {
        return this.dtm.username;
    }
    public set username(value) {
        this.dtm.username = value;
    }

    public get email() {
        return this.dtm.email;
    }
    public set email(value) {
        this.dtm.email = value;
    }

    public get firstName() {
        return this.dtm.first_name;
    }
    public set firstName(value) {
        this.dtm.first_name = value;
    }

    public get lastName() {
        return this.dtm.last_name;
    }
    public set lastName(value) {
        this.dtm.last_name = value;
    }

    public get role() {
        return this.dtm.role;
    }
    public set role(value) {
        this.dtm.role = value;
    }

    public get password() {
        return this.dtm.password;
    }
    public set password(value) {
        this.dtm.password = value;
    }

    public get name() {
        return this.firstName + ' ' + this.lastName;
    }

    public update(dtm: IUser, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IUser, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

    }


    public static getInstance(id: number, name: string) {
        const empty = new User();
        empty.id = id;
        if (name) {
            const split = name.split(' ');
            empty.firstName = name[0];
            if (split.length > 1) {
                empty.lastName = name[1];
            }
        }
        return empty;
    }
}
