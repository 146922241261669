import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatCommonModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { ToasterService } from '../core/services/toaster.service';
import { UploadItemService } from '../core/services/upload-item.service';
import { BgDetailPageComponent } from './base/detail-page/bg/bg-detail-page.component';
import { FullDetailPageComponent } from './base/detail-page/full/full-detail-page.component';
import { MaxDetailPageComponent } from './base/detail-page/max/max-detail-page.component';
import { ListablePageComponent } from './base/listable-page/listable-page.component';
import { RangeListablePageComponent } from './base/range-listable-page/range-listable-page.component';
import { DateRangeDisplayComponent } from './components/date-range-display/date-range-display.component';
import { FiltersSummaryComponent } from './components/filters-summary/filters-summary.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FormControlFiledErrorDirective } from './components/form-control-filed-error/form-control-filed-error.directive';
import { HelpPopupComponent } from './components/help-popup/help-popup.component';
import { HolderLoaderLiteComponent } from './components/holder-loader-lite/holder-loader-lite.component';
import { ImgLoaderLiteComponent } from './components/img-loader-lite/img-loader-lite.component';
import { ImgLoaderComponent } from './components/img-loader/img-loader.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { ListTitleComponent } from './components/list-title/list-title.component';
import { MainWrapperComponent } from './components/main-wrapper/main-wrapper.component';
import { MediaVersionUploadButtonComponent } from './components/media-version-upload-button/media-version-upload-button.component';
import { MediaVersionUploadContentComponent } from './components/media-version-upload-content/media-version-upload-content.component';
import { MediaVersionUploadIconComponent } from './components/media-version-upload-icon/media-version-upload-icon.component';
import { MediaVersionUploadLinkComponent } from './components/media-version-upload-link/media-version-upload-link.component';
import { MediaVersionUploadZoneComponent } from './components/media-version-upload-zone/media-version-upload-zone.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { MessageBoxService } from './components/message-box/message-box.service';
import { ObjectAutocompleteSelectComponent } from './components/object-autocomplete-select/object-autocomplete-select.component';
import { ObjectDialogSelectComponent } from './components/object-dialog-select/object-dialog-select.component';
import { ObjectDropdownSelectComponent } from './components/object-dropdown-select/object-dropdown-select.component';
import { ResourceImgComponent } from './components/resource-img/resource-img.component';
import { ResourceUploadButtonComponent } from './components/resource-upload-button/resource-upload-button.component';
import { ResourceUploadLinkComponent } from './components/resource-upload-link/resource-upload-link.component';
import { TimeRangeDisplayComponent } from './components/time-range-display/time-range-display.component';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { UploadSummaryDialogComponent } from './components/upload-summary-dialog/upload-summary-dialog.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { VersionImgComponent } from './components/version-img/version-img.component';
import { RangeDateFilterSelectComponent } from './data-components/filters/range-date-filter-select/range-date-filter-select.component';
import { AuditoriumFilterSelectComponent } from './data-components/filters/simple-filter-select/auditorium-filter-select.component';
import { DeviceTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/device-type-filter-select.component';
import { DistributionChannelFilterSelectComponent } from './data-components/filters/simple-filter-select/distribution-channel-filter-select.component';
import { LocationFilterSelectComponent } from './data-components/filters/simple-filter-select/location-filter-select.component';
import { MediaTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/media-type-filter-select.component';
import { OutputGroupFilterSelectComponent } from './data-components/filters/simple-filter-select/output-group-filter-select.component';
import { OutputGroupTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/output-group-type-filter-select.component';
import { ResourceMainTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/resource-type-filter-select.component';
import { SpotTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/spot-type-filter-select.component';
import { StatusFilterSelectComponent } from './data-components/filters/simple-filter-select/status-filter-select.component';
import { TagFilterSelectComponent } from './data-components/filters/simple-filter-select/tag-filter-select.component';
import { TimeRangeTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/timer-range-type-filter-select.component';
import { SingleDateFilterSelectComponent } from './data-components/filters/single-date-filter-select/single-date-filter-select.component';
import { CampaignSelectDialogComponent } from './data-components/paged-select-dialog/campaign-select-dialog.component';
import { ChannelSelectDialogComponent } from './data-components/paged-select-dialog/channel-select-dialog.component';
import { DeviceSelectDialogComponent } from './data-components/paged-select-dialog/device-select-dialog.component';
import { EventSelectDialogComponent } from './data-components/paged-select-dialog/event-select-dialog.component';
import { ExtContentSourceSelectDialogComponent } from './data-components/paged-select-dialog/ext-content-source-select-dialog.component';
import { MediaSelectDialogComponent } from './data-components/paged-select-dialog/media-select-dialog.component';
import { ResourceSelectDialogComponent } from './data-components/paged-select-dialog/resource-select-dialog.component';
import { ScreenGroupSelectDialogComponent } from './data-components/paged-select-dialog/screen-group-select-dialog.component';
// import { FormFieldErrorDirective } from './components/form-field-error/form-field-error.directive';
import { SpotAppSelectDialogComponent } from './data-components/paged-select-dialog/spot-app-select-dialog.component';
import { SpotSelectDialogComponent } from './data-components/paged-select-dialog/spot-select-dialog.component';
import { UserSelectDialogComponent } from './data-components/paged-select-dialog/user-select-dialog.components';
import { AuditoriumSelectDialogComponent } from './data-components/simple-select-dialog/auditorium-select-dialog.component';
import { DistributionChannelSelectDialogComponent } from './data-components/simple-select-dialog/distribution-channel-select-dialog.components';
import { GenreSelectDialogComponent } from './data-components/simple-select-dialog/genre-select-dialog.component';
import { LanguageSelectDialogComponent } from './data-components/simple-select-dialog/language-select-dialog.component';
import { LocationSelectDialogComponent } from './data-components/simple-select-dialog/location-select-dialog.component';
import { MediaTypeSelectDialogComponent } from './data-components/simple-select-dialog/media-type-select-dialog.component';
import { RatingSelectDialogComponent } from './data-components/simple-select-dialog/rating-select-dialog.component';
import { RelatedConfBaseSelectDialogComponent } from './data-components/simple-select-dialog/related-conf-base-select-dialog.component';
import { SpotAppFilterRuleDialogComponent } from './data-components/simple-select-dialog/spot-app-filter-rule-select-dialog.component';
import { TagSelectDialogComponent } from './data-components/simple-select-dialog/tag-select-dialog.component';
import { MomentPipe } from './pipes/moment.pipe';
import { TimeUntilPipe } from './pipes/timeuntil.pipe';
import { PropOrderPipe } from './pipes/prop-order.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { ScheduleTargetTypeNamePipe } from './pipes/schedule-target-type-name.pipe';
import { SpotAppPerRangePlayTargetNamePipe } from './pipes/spot-app-per-range-play-count-target-name.pipe';
import { SpotAppSourcePerRangePlayTargetNamePipe } from './pipes/spot-app-source-per-range-play-count-target-name.pipe';
import { SpotAppFilterTargetTypeNamePipe } from './pipes/spot-app-filter-target-type-name.pipe';
import { SpotAppSourceTargetTypeNamePipe } from './pipes/spot-app-source-target-type-name.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TemplateInstanceFilterTargetTypeNamePipe } from './pipes/template-instance-filter-target-type-name.pipe';
import { TemplateInstanceSourceTargetTypeNamePipe } from './pipes/template-instance-source-target-type-name.pipe';
import { SpotAppTypeNamePipe } from './pipes/spot-app-type-name.pipe';
import { SpotTriggerActionNamePipe } from './pipes/spot-trigger-action-name.pipe';
import { ThumbPipe } from './pipes/thumb.pipe';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DeviceTypeLookupSelectBottomSheetComponent } from './data-components/lookup-select-bottom-sheet/device-type-lookup-select-bottom-sheet.component';
import { ProductSelectDialogComponent } from './data-components/paged-select-dialog/product-select-dialog.component';
import { ProductGroupSelectDialogComponent } from './data-components/paged-select-dialog/product-group-select-dialog.component';
import { TemplateBaseSelectDialogComponent } from './data-components/paged-select-dialog/template-base-select-dialog.component';
import { ResourceTypeSelectDialogComponent } from './data-components/paged-select-dialog/resource-type-select-dialog.component';
import { SalesPointSelectDialogComponent } from './data-components/paged-select-dialog/sales-point-select-dialog.component';
import { ObjectDialogAddComponent } from './components/object-dialog-add/object-dialog-add.component';
import { ProductPriceGroupSelectDialogComponent } from './data-components/paged-select-dialog/product-price-group-select-dialog.component';
import { CampaignTypeFilterSelectComponent } from './data-components/filters/simple-filter-select/campaign-type-filter-select.component';
import { CdkDropList, CdkDrag} from '@angular/cdk/drag-drop';
import { ProductGroupFilterSelectComponent } from './data-components/filters/simple-filter-select/product-group-filter-select.component';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        // CalendarModule, AutoCompleteModule,
        // RlTagInputModule,
        MatSidenavModule, MatButtonModule, MatIconModule, MatCheckboxModule,
        MatDialogModule, MatToolbarModule, MatBottomSheetModule,
        MatListModule, MatChipsModule, MatProgressBarModule, MatMenuModule,
        MatProgressSpinnerModule,
        MatPaginatorModule, MatSortModule, MatTabsModule, MatButtonToggleModule, MatTooltipModule,
        MatCardModule, MatGridListModule, MatFormFieldModule,
        // FlexLayoutModule,
        MatSelectModule,
        MatStepperModule, MatInputModule, MatCommonModule, MatExpansionModule,
        MatAutocompleteModule, MatRadioModule, MatDatepickerModule,
        MatSlideToggleModule, MatSnackBarModule, MatTableModule, MatBadgeModule,
        CdkDropList, CdkDrag,
        RouterModule
    ],
    declarations: [
        ThumbPipe,
        MomentPipe,
        TimeUntilPipe,
        ScheduleTargetTypeNamePipe,
        SpotAppTypeNamePipe,
        UserNameComponent,
        FiltersComponent,
        FiltersSummaryComponent,
        MessageBoxComponent,
        MediaVersionUploadLinkComponent,
        MediaVersionUploadZoneComponent,
        MediaVersionUploadButtonComponent,
        MediaVersionUploadContentComponent,
        ImgLoaderComponent,
        ImgLoaderLiteComponent,
        HolderLoaderLiteComponent,
        VersionImgComponent, ReversePipe, PropOrderPipe,
        SpotAppPerRangePlayTargetNamePipe,
        SpotAppSourcePerRangePlayTargetNamePipe,
        SpotAppSourceTargetTypeNamePipe,
        DurationPipe,
        SpotAppFilterTargetTypeNamePipe,
        TemplateInstanceSourceTargetTypeNamePipe,
        TemplateInstanceFilterTargetTypeNamePipe,
        ListablePageComponent,
        RangeListablePageComponent,
        BgDetailPageComponent,
        FullDetailPageComponent,
        MaxDetailPageComponent,
        ScheduleTargetTypeNamePipe,
        SpotAppTypeNamePipe,
        MediaSelectDialogComponent,
        MediaTypeSelectDialogComponent,
        DistributionChannelSelectDialogComponent,
        SpotAppFilterRuleDialogComponent,
        ResourceUploadLinkComponent,
        ResourceImgComponent,
        UploadSummaryDialogComponent,
        ListCardComponent,
        ListTitleComponent,
        ObjectDialogSelectComponent,
        AuditoriumSelectDialogComponent,
        RatingSelectDialogComponent,
        TagSelectDialogComponent,
        EventSelectDialogComponent,
        CampaignSelectDialogComponent,
        ExtContentSourceSelectDialogComponent,
        ScreenGroupSelectDialogComponent,
        DeviceSelectDialogComponent,
        ResourceSelectDialogComponent,
        MomentPipe,
        MediaTypeFilterSelectComponent,
        TagFilterSelectComponent,
        DistributionChannelFilterSelectComponent,
        AuditoriumFilterSelectComponent,
        LocationFilterSelectComponent,
        DeviceTypeFilterSelectComponent,
        ResourceMainTypeFilterSelectComponent,
        SpotTypeFilterSelectComponent,
        TimeRangeTypeFilterSelectComponent,
        OutputGroupFilterSelectComponent,
        OutputGroupTypeFilterSelectComponent,
        StatusFilterSelectComponent,
        MediaVersionUploadZoneComponent,
        MainWrapperComponent,
        ObjectAutocompleteSelectComponent,
        UserSelectDialogComponent,
        GenreSelectDialogComponent,
        ObjectDropdownSelectComponent,
        LanguageSelectorComponent,
        LocationSelectDialogComponent,
        LanguageSelectDialogComponent,
        RelatedConfBaseSelectDialogComponent,
        ChannelSelectDialogComponent,
        SingleDateFilterSelectComponent,
        TopToolbarComponent,
        RangeDateFilterSelectComponent,
        DateRangeDisplayComponent,
        // FormFieldErrorDirective,
        SpotAppSelectDialogComponent,
        SpotSelectDialogComponent,
        FormControlFiledErrorDirective,
        TimeRangeDisplayComponent,
        SpotTriggerActionNamePipe,
        ResourceUploadButtonComponent,
        MediaVersionUploadIconComponent,
        HelpPopupComponent,
        DeviceTypeLookupSelectBottomSheetComponent,
        ProductSelectDialogComponent,
        ProductGroupSelectDialogComponent,
        TemplateBaseSelectDialogComponent,
        ResourceTypeSelectDialogComponent,
        SalesPointSelectDialogComponent,
        ObjectDialogAddComponent,
        ProductPriceGroupSelectDialogComponent,
        CampaignTypeFilterSelectComponent,
        ProductGroupFilterSelectComponent
    ],
    exports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        // CalendarModule, AutoCompleteModule,
        // RlTagInputModule,
        ThumbPipe,
        MomentPipe,
        TimeUntilPipe,
        ScheduleTargetTypeNamePipe, SpotAppTypeNamePipe,
        ReversePipe, PropOrderPipe,
        SpotAppPerRangePlayTargetNamePipe,
        SpotAppSourcePerRangePlayTargetNamePipe,
        SpotAppSourceTargetTypeNamePipe,
        DurationPipe,
        SpotAppFilterTargetTypeNamePipe,
        TemplateInstanceSourceTargetTypeNamePipe,
        TemplateInstanceFilterTargetTypeNamePipe,
        SpotTriggerActionNamePipe,
        // CircleComponent,
        UserNameComponent,
        FiltersComponent,
        MediaVersionUploadLinkComponent,
        MediaVersionUploadZoneComponent,
        MediaVersionUploadButtonComponent,
        MediaVersionUploadContentComponent,
        ResourceUploadLinkComponent,
        FiltersSummaryComponent, ImgLoaderComponent, ImgLoaderLiteComponent,
        HolderLoaderLiteComponent,
        MatSidenavModule, MatButtonModule, MatIconModule, MatCheckboxModule,
        MatDialogModule, MatToolbarModule, MatBottomSheetModule,
        MatListModule, MatChipsModule, MatProgressBarModule, MatMenuModule,
        MatProgressSpinnerModule,
        MatTabsModule, MatPaginatorModule, MatSortModule, MatButtonToggleModule, MatTooltipModule,
        VersionImgComponent,
        ResourceImgComponent,
        MatCardModule, MatGridListModule, MatFormFieldModule, MatSelectModule, MatStepperModule,
        MatInputModule, MatCommonModule, MatExpansionModule, MatAutocompleteModule, MatRadioModule,
        MatDatepickerModule,
        MatSlideToggleModule, MatSnackBarModule,
        MatTableModule, MatBadgeModule,
        ListablePageComponent,
        RangeListablePageComponent,
        BgDetailPageComponent,
        FullDetailPageComponent,
        MaxDetailPageComponent,
        ListCardComponent,
        ListTitleComponent,
        ObjectDialogSelectComponent,
        ObjectAutocompleteSelectComponent,
        ObjectDropdownSelectComponent,
        AuditoriumSelectDialogComponent,
        RatingSelectDialogComponent,
        TagSelectDialogComponent,
        SpotAppFilterRuleDialogComponent,
        MediaSelectDialogComponent,
        EventSelectDialogComponent,
        CampaignSelectDialogComponent,
        ExtContentSourceSelectDialogComponent,
        ScreenGroupSelectDialogComponent,
        DeviceSelectDialogComponent,
        ResourceSelectDialogComponent,
        MediaTypeFilterSelectComponent,
        TagFilterSelectComponent,
        DistributionChannelFilterSelectComponent,
        AuditoriumFilterSelectComponent,
        LocationFilterSelectComponent,
        DeviceTypeFilterSelectComponent,
        ResourceMainTypeFilterSelectComponent,
        SpotTypeFilterSelectComponent,
        TimeRangeTypeFilterSelectComponent,
        OutputGroupFilterSelectComponent,
        OutputGroupTypeFilterSelectComponent,
        StatusFilterSelectComponent,
        MainWrapperComponent,
        LanguageSelectorComponent,
        LocationSelectDialogComponent,
        LanguageSelectDialogComponent,
        RelatedConfBaseSelectDialogComponent,
        ChannelSelectDialogComponent,
        SingleDateFilterSelectComponent,
        RangeDateFilterSelectComponent,
        TopToolbarComponent,
        DateRangeDisplayComponent,
        // FormFieldErrorDirective,
        SpotAppSelectDialogComponent,
        SpotSelectDialogComponent,
        FormControlFiledErrorDirective,
        TimeRangeDisplayComponent,
        ResourceUploadButtonComponent,
        MediaVersionUploadIconComponent,
        HelpPopupComponent,
        DeviceTypeLookupSelectBottomSheetComponent,
        ProductSelectDialogComponent,
        ProductGroupSelectDialogComponent,
        TemplateBaseSelectDialogComponent,
        ResourceTypeSelectDialogComponent,
        ObjectDialogAddComponent,
        ProductPriceGroupSelectDialogComponent,
        CampaignTypeFilterSelectComponent,
        CdkDropList, CdkDrag,
        ProductGroupFilterSelectComponent
    ], providers: [
        MessageBoxService,
        ToasterService,
        UploadItemService,
    ]
})
export class SharedModule {
    // constructor() {

    // }
}
