import { BaseModel, IBaseModel } from "../base.model";
import { DeviceChannelPinpoint, IDeviceChannelPinpoint } from "./device-channel-pinpoint.model";
import { environment } from 'environments/environment';
import { DeviceChannelPinpointItem } from "./device-channel-pinpoint-item.model";

export interface IDeviceChannelSequence extends IBaseModel {
    dci: number;
    id: number;
    st: string;
    sp: string;
    pi: number;
    ih: string
}



export class DeviceChannelSequence extends BaseModel<IDeviceChannelSequence> {
    constructor(dtm: IDeviceChannelSequence, pinpoint: DeviceChannelPinpoint) {
        super(dtm);
        this.update(this.dtm, true, true);

        this.pinpoint = pinpoint;
        this.firstItem = this.pinpoint.firstItem;
    }


    public get id() {
        return this.dtm.id;
    }
    public get deviceChanneID() {
        return this.dtm.dci;
    }

    public get start() {
        return new Date(this.dtm.st);
    }

    public get stop() {
        return new Date(this.dtm.sp);
    }

    public get pinpointID() {
        return this.dtm.pi;
    }

    public get instanceHash() {
        return this.dtm.ih;
    }

    public pinpoint: DeviceChannelPinpoint;
    public firstItem: DeviceChannelPinpointItem;

    public update(dtm: IDeviceChannelSequence, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }




    // UI props
    public get duration() {
        return this.stop.getTime() - this.start.getTime();
    }

    public get url() {
        // if (this.content && this.content.file) {
        //     return this.content.file.url;
        // }
        if (this.instanceHash && this.instanceHash.length) {
            let mpt = 40;
            if (this.start && this.stop) {
                const mptms = this.stop.getTime() - this.start.getTime();
                if (mptms > 0) {
                    mpt = Math.round(mptms / 1000);
                }
            }
            return `${environment.VOOGSTV_RENDERERV3_URL}/${this.instanceHash}?mpt=${mpt}`;
        }
        return '';
    }


}
