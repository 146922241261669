<mat-card>
    <div mat-card-image>
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACCAYAAABhYU3QAAAAE0lEQVR42mP0NfP9z0AEYCRWIQDUCgOhbVaK7QAAAABJRU5ErkJggg==">
        <div class="over"></div>
    </div>

    <mat-card-content>
        <h2>Houston we have a problem </h2>
        <p>404 Page not found</p>
    </mat-card-content>

    <mat-card-actions>
        <a mat-button href="/">TAKE ME HOME</a>
    </mat-card-actions>
</mat-card>