import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ITagDeviceRelation, TagDeviceRelation } from './tag-device-relation.model';
import { PropertyUpdateMode } from '../base.model';
import { DeviceBase, IDeviceBase } from '../device/device.base.model';
import * as Enumerable from 'linq-es2015';

// tslint:disable-next-line:no-empty-interface
export interface ITag extends IBaseNameModel {
    deviceTags: ITagDeviceRelation[];
}

export class Tag extends BaseNameModel<ITag> {
    constructor(dtm: ITag = null) {
        super(dtm || {
            id: 0,
            name: '',
            client_id: 0,
            deviceTags: [],
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }


    public get tagDeviceRelations() { return this.getModelArray(() => this.dtm.deviceTags, (x) => new TagDeviceRelation(x), PropertyUpdateMode.Clear); }
    public set tagDeviceRelations(value) { this.setModelArray(() => this.dtm.deviceTags, (x) => this.dtm.deviceTags = x, value, PropertyUpdateMode.Clear); }

    public get devices_display_name() {

        let devices: Array<DeviceBase<IDeviceBase>> = [];

        if (this.dtm.deviceTags) {
            devices = Enumerable.AsEnumerable(this.dtm.deviceTags).Select((x) => new DeviceBase<IDeviceBase>(x.device)).ToArray();
        }

        let ret = Enumerable.AsEnumerable(devices).Distinct((x) => x.id).Select((x) => x.name).ToArray().join(', ');

        if (ret.length > 40) {
            ret = ret.slice(0, 39) + '... (' + devices.length + ')';
        }

        return ret;
    }

    public update(dtm: ITag, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: ITag, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

    }
}
