import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem } from '../enums/enums';
import { IFloorMapPoint, FloorMapPoint } from 'app/core/data/floor-map/floor-map-point.model';
import { ILocationMy } from '../location/location.model';
import { PropertyUpdateMode } from '../base.model';

export interface IFloorMap extends IBaseNameModel {
    points: IFloorMapPoint[];
    location_id: number;
    location: ILocationMy;
    map_image_url: string;
    map_image_width: number;
    map_image_height: number;
}

export class FloorMap extends BaseNameModel<IFloorMap> {
    constructor(dtm: IFloorMap = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            points: [],
            location_id: 0,
            location: null,
            map_image_url: null,
            map_image_width: null,
            map_image_height: null,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }


    public get points() { return this.getModelArray(() => this.dtm.points, (x) => new FloorMapPoint(x), PropertyUpdateMode.Clear); }
    public set points(value) { this.setModelArray(() => this.dtm.points, (x) => this.dtm.points = x, value, PropertyUpdateMode.Clear); }

    public get mapImageUrl() {
        return this.dtm.map_image_url;
    }

    public get mapImagWidth() {
        return this.dtm.map_image_width;
    }

    public get mapImageHeight() {
        return this.dtm.map_image_height;
    }

    private _locationName: string = null;
    public get locationName() {
        if (!this._locationName && this.dtm.location) {
            this._locationName = this.dtm.location.name;
        }
        return this._locationName;
    }

    public update(dtm: IFloorMap, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);


    }


    protected clearLocalProps() {
        super.clearLocalProps();

        this._locationName = null;

    }

}
