import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { ResourceMainType } from '../enums/enums';
// import { ResourceDataService } from './resouce-data.service';

// fake service for lookup
@Injectable()
export class ResourceMainTypeDataService implements ILookupable<ILookup> {
    // public lookupItems$: Observable<ILookup[]>;

    // constructor(deviceDataService: ResourceDataService) {
    //     this.lookupItems$ = deviceDataService.typelookup$;
    // }
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: ResourceMainType.Generic, name: 'Generic' });
        lookups.push({ id: ResourceMainType.Background, name: 'Background' });
        lookups.push({ id: ResourceMainType.Logo, name: 'Logo' });
        lookups.push({ id: ResourceMainType.Icon, name: 'Icon' });
        lookups.push({ id: ResourceMainType.Imported, name: 'Imported' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
