import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { ScreenGroupType, Status, StatusSystem, ObjectType } from '../enums/enums';
import { ILookup, IScreenGroupLookup } from '../lookup.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { IScreenGroupDeviceRelation, ScreenGroupDeviceRelation } from './screen-group-device-relation.model';
import { DeviceBase, IDeviceBase } from '../device/device.base.model';
import { PropertyUpdateMode } from '../base.model';

export interface IScreenGroup extends IBaseNameModel {
    type: ScreenGroupType;
    acronym: string;
    screengroupDevices: IScreenGroupDeviceRelation[];
}

export class ScreenGroup extends BaseNameModel<IScreenGroup> {
    constructor(dtm: IScreenGroup = null) {
        super(dtm || {
            id: 0,
            name: '',
            type: 0,
            acronym: '',
            client_id: 0,
            screengroupDevices: [],
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get acronym() {
        return this.dtm.acronym;
    }
    public set acronym(value) {
        this.dtm.acronym = value;
    }

    public get type() {
        return this.dtm.type;
    }
    public set type(value) {
        this.dtm.type = value;
    }

    public get typeName() {
        return ScreenGroupType[this.dtm.type] ? ScreenGroupType[this.dtm.type]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get isLocation() {
        return this.type === ScreenGroupType.Building;
    }

    public get type_lookup() { return this.getLookupProperty(() => this.dtm.type, (x) => this.createLookup(this.dtm.type, this.typeName)); }
    public set type_lookup(value) { this.setLookupProperty(() => this.dtm.type, (x) => { this.dtm.type = x; }, value, (item) => item.id); }


    // public get device_lookups() { return this.getLookupArray(() => this.dtm.screengroupDevices, (x) => this.createLookup(x.device_id, x.device?.name)); }
    // public set device_lookups(value) { this.setLookupArray(() => this.dtm.screengroupDevices, (x) => this.dtm.screengroupDevices = x, value, (item) => ScreenGroupDeviceRelation.getInstance(this.id, item.id).dtm); }


    public get screenGroupDeviceRelations() { return this.getModelArray(() => this.dtm.screengroupDevices, (x) => new ScreenGroupDeviceRelation(x), PropertyUpdateMode.Clear); }
    public set screenGroupDeviceRelations(value) { this.setModelArray(() => this.dtm.screengroupDevices, (x) => this.dtm.screengroupDevices = x, value, PropertyUpdateMode.Clear); }


    public get devices_display_name() {

        let devices: Array<DeviceBase<IDeviceBase>> = [];

        if (this.dtm.screengroupDevices) {
            devices = Enumerable.AsEnumerable(this.dtm.screengroupDevices).Select((x) => new DeviceBase<IDeviceBase>(x.device)).ToArray();
        }

        let ret = Enumerable.AsEnumerable(devices).Distinct((x) => x.id).Select((x) => x.name).ToArray().join(', ');

        if (ret.length > 40) {
            ret = ret.slice(0, 39) + '... (' + devices.length + ')';
        }

        return ret;
    }

    public static getTypeOrder(type: ScreenGroupType) {
        let ret = 100;
        switch (+type) {
            case ScreenGroupType.Building:
                ret = 1;
                break;
            case ScreenGroupType.Floor:
                ret = 2;
                break;
            case ScreenGroupType.Area:
                ret = 3;
                break;
            case ScreenGroupType.Position:
                ret = 4;
                break;
            case ScreenGroupType.Reciver:
                ret = 5;
                break;
            case ScreenGroupType.General:
                ret = 6;
                break;
        }
        return ret;
    }

    public static get_name(items: IScreenGroup[]) {
        return Enumerable.AsEnumerable(items).Distinct((x) => x.id)
            .GroupBy((x) => x.type + '', (x) => x)
            .OrderBy((x) => ScreenGroup.getTypeOrder(+x.key)).Select((group, index) => {
                const groupItems = Enumerable.AsEnumerable(group).Select((y) => y.name).ToArray();
                return groupItems.join(', ');
            }).ToArray().join(', ');
    }

    public static get_acronyms(items: IScreenGroup[], excludeGeneral: boolean) {
        return Enumerable.AsEnumerable(items).Where((x) => x.type > ScreenGroupType.General || !excludeGeneral).Distinct((x) => x.id)
            .GroupBy((x) => x.type + '', (x) => x)
            .OrderBy((x) => ScreenGroup.getTypeOrder(+x.key)).Select((group, index) => {
                return Enumerable.AsEnumerable(group).Where((x) => x.acronym && x.acronym.length > 0).Select((y) => y.acronym).ToArray();
            }).SelectMany().ToArray();
    }

    public static get_acronym_name(items: IScreenGroup[], excludeGeneral: boolean) {
        return this.get_acronyms(items, excludeGeneral).join('-');
    }

    public static get_acronyms_from_lookup(items: IScreenGroupLookup[], excludeGeneral: boolean): string[] {
        return Enumerable.AsEnumerable(items).Where((x) => x.type > ScreenGroupType.General || !excludeGeneral).Distinct((x) => x.id)
            .GroupBy((x) => x.type + '', (x) => x)
            .OrderBy((x) => { ScreenGroup.getTypeOrder(+x.key); }).Select((group, index) => {
                return Enumerable.AsEnumerable(group).Where((x) => x.acronym && x.acronym.length > 0).Select((y) => y.acronym).ToArray();
            }).SelectMany((x) => x).ToArray();
    }

    public static get_acronym_name_from_lookup(items: IScreenGroupLookup[], excludeGeneral: boolean) {
        return this.get_acronyms_from_lookup(items, excludeGeneral).join('-');
    }
    public update(dtm: IScreenGroup, forceReplaceDtm = false, fromConstructor = false) {
        if (!dtm.type) {
            dtm.type = 0;
        }
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IScreenGroup, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
    }
}
