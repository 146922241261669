import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IScreenGroup, ScreenGroup } from '../screen-group/screen-group.model';
import { ObjectType } from '../enums/enums';

export interface IDeviceChannelScreenGroupRelation extends IBaseRelationModel {
    device_channel_id: number;
    screengroup_id: number;
    screengroup: IScreenGroup;
}

export class DeviceChannelScreenGroupRelation extends BaseRelationModel <IDeviceChannelScreenGroupRelation> {
    constructor(dtm: IDeviceChannelScreenGroupRelation = null) {
        super(dtm || {
            device_channel_id: 0,
            screengroup_id: 0,
            screengroup: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get device_channel_id() {
        return this.dtm.device_channel_id;
    }
    public set device_channel_id(value) {
        this.dtm.device_channel_id = value;
    }

    public get screengroup_id() {
        return this.dtm.screengroup_id;
    }
    public set screengroup_id(value) {
        this.dtm.screengroup_id = value;
    }

    public update(dtm: IDeviceChannelScreenGroupRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IDeviceChannelScreenGroupRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.screengroup;

    }

    public static getInstance(device_channel_id: number, screengroup_id: number){
        const empty = new DeviceChannelScreenGroupRelation();
        empty.device_channel_id = device_channel_id;
        empty.screengroup_id = screengroup_id;
        return empty;
    }
}
