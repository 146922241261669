import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMyJob, MyJob } from './background-job.model';
import { ToasterService } from '../../services/toaster.service';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthDataService } from '../auth/auth-data.service';

@Injectable()
export class BackgroundJobDataService {
    private baseUrl = environment.VOOGSTV_JOBAPI_URL;
    private apiKey = environment.VOOGSTV_JOBAPI_KEY;
    constructor(private http: HttpClient, private toastr: ToasterService, private authDataService: AuthDataService) {

    }

    public getAll(): Observable<MyJob[]> {
        return this.getDatas().pipe(map((items) => {
            const models: MyJob[] = [];
            items.forEach((item) => {
                models.push(new MyJob(item));
            });
            return models;
        }), tap((value) => {
            // comment
        }, (error) => {
            this.toastr.error(`Failed to get background jobs all data`, 'Error!');
        }));
    }

    protected getDatas(): Observable<IMyJob[]> {

        const accessToken = this.authDataService.getDomainCookie('access-token');
        const headers = new HttpHeaders({
            'access-token': accessToken,
            'api-key': this.apiKey
        });

        return this.http.get<IMyJob[]>(`${this.baseUrl}/jobs`, {
            headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }
    public requeueJob(jobID: string) {

        const accessToken = this.authDataService.getDomainCookie('access-token');

        const headers = new HttpHeaders({
            'access-token': accessToken,
            'api-key': this.apiKey
        });

        return this.http.put(`${this.baseUrl}/jobs/${jobID}/requeue`, null, {
            headers
        }).pipe(tap((value) => {
            this.toastr.success(`Job requeued`, 'Success!');
        }, (error) => {
            this.toastr.error(`Failed to requeue job`, 'Error!');
        }));
    }

    private handleError(error: Response, caught: Observable<any>) {

        let errorText = 'ERROR';

        if (error.statusText) {
            errorText = error.statusText;
        }

        if (error.status === 401) {
            // alert('Unauthorized request, session will close!');
            // window.location.reload(true);
        }

        return throwError(errorText);
    }
}
