import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IApiAccess, ApiAccess } from './api-access.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ApiAccessDataService extends BaseListableDataService<ApiAccess, IApiAccess, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'api-access', ObjectType.API_ACCESS, ['user', 'distribution-channels'], ['user', 'distribution-channels']);
  }

}
