import { PropertyIntentID, Status, StatusSystem, ObjectType } from '../enums/enums';
import { EventBase, IEventBase } from '../event/event.model';
import { ILookup } from '../lookup.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { IExtCommandGroup } from '../ext-command/ext-command-group.model';
import { IBaseRelatedConfModel, BaseRelatedConfModel } from '../base-relatedconf.model';
import { PropertyUpdateMode } from '../base.model';

export interface IShow extends IBaseRelatedConfModel {
    ext_id: number;
    event_id: number;
    event: IEventBase;
    // extCommands: IExtCommand[];
    extCommandsGrouped: IExtCommandGroup[];
    // auditorium_id: number;
    // auditorium: IAuditorium;
    // start: string;
    // stop: string;
}

export class Show<TEvent extends EventBase<any>> extends BaseRelatedConfModel<IShow> {
    constructor(
        dtm: IShow = null,
        private createEventInstance: (dtm: IEventBase) => TEvent = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            ext_id: 0,
            event_id: 0,
            event: null,
            name: null,
            property_set: [],
            related_conf_base_id: 0,
            relatedConfBase: null,
            // extCommands: null,
            extCommandsGrouped: null,
            // auditorium_id: 0,
            // auditorium: null,
            // start: '',
            // stop: '',

            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }

    public get id() {
        return this.dtm.id;
    }
    public set id(value) {
        this.dtm.id = value;
    }

    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    public get event_id() {
        return this.dtm.event_id;
    }
    public set event_id(value) {
        this.dtm.event_id = value;
    }

    public get eventName() {
        if (this.event) {
            return this.event.displayName;
        }
        return `ID: ${this.event_id}`;
    }

    public get eventStatusName() {
        if (this.event) {
            return this.event.statusName;
        }
        return `Unknown`;
    }

    public get eventLookup() { return this.getLookupProperty(() => this.dtm.event_id, (x) => this.createLookup(this.dtm.event_id, this.eventName)); }
    public set eventLookup(value) { this.setLookupProperty(() => this.dtm.event_id, (x) => { this.dtm.event_id = x; }, value, (item) => item.id); }

    public get event() {
        if (this.createEventInstance != null) {
            return this.getModelProperty(() => this.dtm.event, (x) => this.createEventInstance(x), PropertyUpdateMode.Clear);
        } else {
            return this.getModelProperty(() => this.dtm.event, (x) => new EventBase(x) as TEvent, PropertyUpdateMode.Clear);
        }
    }


    public get start() {
        if (!this._start && this.propertySet) {
            const match = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.EventShowStart);
            if (match && match.values && match.values.length) {
                this._start = match.values[0].contentString;
            }
        }
        return this._start;
    }
    public get stop() {
        if (!this._stop && this.propertySet) {
            const match = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.EventShowStop);
            if (match && match.values && match.values.length) {
                this._stop = match.values[0].contentString;
            }
        }
        return this._stop;
    }

    private _start: string;

    private _stop: string;


    public static createInstance<TShow extends Show<any>>(c: new () => TShow): TShow {
        return new c();
    }

    public static getCleanClone<TShow extends Show<any>>(dtm: IShow, createInstance: (dtm: IShow) => TShow, cloneDTM = true): TShow {

        if (cloneDTM) {
            dtm = JSON.parse(JSON.stringify(dtm)) as IShow;
        }
        dtm.id = 0;

        if (dtm.property_set && dtm.property_set.length) {
            dtm.property_set.forEach((set) => {
                if (set.value && set.value.length) {
                    set.value.forEach((pvalue) => {
                        pvalue.id = 0;
                    });
                }

            });
        }

        return createInstance(dtm);
    }
    public update(dtm: IShow, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);


    }

    public cleanForSave(dtm: IShow, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.event;
        delete dtm.extCommandsGrouped;
    }

    protected clearLocalProps() {
        super.clearLocalProps();
    }

}
