import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Campaign, ICampaign } from '../campaign/campaign.model';
import { ObjectType } from '../enums/enums';

export interface IEventCampaignRelation extends IBaseRelationModel {
    event_id: number;
    campaign_id: number;
    campaign: ICampaign;
}

export class EventCampaignRelation extends BaseRelationModel <IEventCampaignRelation> {
    constructor(dtm: IEventCampaignRelation = null) {
        super(dtm || {
            event_id: 0,
            campaign_id: 0,
            campaign: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get eventID() {
        return this.dtm.event_id;
    }
    public set eventID(value) {
        this.dtm.event_id = value;
    }

    public get campaignID() {
        return this.dtm.campaign_id;
    }
    public set campaignID(value) {
        this.dtm.campaign_id = value;
    }

    public update(dtm: IEventCampaignRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: IEventCampaignRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.campaign;
    }

    public static getInstance(eventID: number, campaignID: number){
        const empty = new EventCampaignRelation();
        empty.eventID = eventID;
        empty.campaignID = campaignID;
        return empty;
    }
}
