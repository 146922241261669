import { ICampaignMediaRelation, CampaignMediaRelation } from './campaign-media-relation.model';
import { PropertyIntentID, Status, StatusSystem, ScreenGroupType, ObjectType, SpotAppSourceTargetType, CampaignType } from '../enums/enums';
import { PropertySet } from '../property/property-set.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import * as moment from 'moment';
import { IScreenGroupLookupRestrict } from '../lookup-restrict.model';
import { CampaignScreenGroupRelation, ICampaignScreenGroupRelation } from './campaign-screengroup-relation.model';
import { ICampaignEventRelation, CampaignEventRelation } from './campaign-event-relation.model';
import { EventBase } from '../event/event.model';
import { Show } from '../show/show.model';
import { Media } from '../media/media.model';
import { RegularEvent } from '../event/regular-event.model';
import { BaseRelatedConfModel, IBaseRelatedConfModel } from '../base-relatedconf.model';
import { ScreenGroup } from '../screen-group/screen-group.model';
import { ICampaignSchedule, CampaignSchedule } from './campaign-schedule.model';
import { ISpotAppSource, SpotAppSource } from '../spot-app/spot-app-source.model';
import { SpotApp } from '../spot-app/spot-app.model';
import { ILookup } from '../lookup.model';
import { PropertyUpdateMode } from '../base.model';

export interface ICampaign extends IBaseRelatedConfModel {
    valid_from: string;
    valid_to: string;
    type_id: CampaignType;
    // rating_id: number;
    // rating: IRating;

    campaignMedia: ICampaignMediaRelation[];
    campaignEvents: ICampaignEventRelation[];
    // campaignScreengroups: ICampaignScreenGroupRelation[];  // hide
    campaignSchedules: ICampaignSchedule[];

    // fakes, filled from campaignScreengroups, not really in response
    campaignLocationScreengroups: ICampaignScreenGroupRelation[];
    campaignRegularScreengroups: ICampaignScreenGroupRelation[];

    spotAppSources: ISpotAppSource[];
}

interface ICampaignReal extends ICampaign {
    campaignScreengroups: ICampaignScreenGroupRelation[]; // unhide
}

export class Campaign extends BaseRelatedConfModel<ICampaign> {
    constructor(dtm: ICampaign = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            valid_from: '',
            valid_to: '',
            type_id: CampaignType.Generic,
            // rating_id: 0,
            // rating: null,
            property_set: null,
            related_conf_base_id: 0,
            relatedConfBase: null,
            campaignMedia: [],
            campaignEvents: [],
            // campaignScreengroups: [],
            campaignSchedules: [],
            spotAppSources: [],

            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null,

            campaignLocationScreengroups: [],
            campaignRegularScreengroups: []
        });
        this.update(this.dtm, true, true);
    }

    private dateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    private _displayName: string;
    public get displayName() {

        if (this.name) {
            this._displayName = this.name;
        }

        if (!this._displayName && this.propertySet) {
            const match = this.titleProperty;
            if (match && match.values && match.values.length) {
                this._displayName = match.values[0].contentString;
                // Enumerable.AsEnumerable(match.values).Select((x) => x.contentString).ToArray().join(', ');
            } else {
                this._displayName = 'Unknown';
            }
        }
        return /*fix*/ this._displayName;
    }

    private _titleProperty: PropertySet;
    public get titleProperty() {
        if (!this._titleProperty && this.propertySet) {
            this._titleProperty = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.CampaignTitle ||
                    x.intent.id === PropertyIntentID.GeneralTitle);
        }

        return /*fix*/ this._titleProperty;
    }



    public get valid_from() {
        let value = this.dtm.valid_from;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set valid_from(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_from = value;
    }

    public get valid_to() {
        let value = this.dtm.valid_to;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set valid_to(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.dtm.valid_to = value;
    }

    private _locationScreengroupLookups: IScreenGroupLookupRestrict[];
    public get locationScreengroupLookups() {
        if (!this._locationScreengroupLookups && this.dtm.campaignLocationScreengroups) {
            this._locationScreengroupLookups = Enumerable.AsEnumerable(this.dtm.campaignLocationScreengroups)
                .Where((x) => x.screengroup.type === ScreenGroupType.Building).Select((x) => {
                    return {
                        id: x.screengroup_id,
                        name: x.screengroup.name,
                        type: x.screengroup.type,
                        acronym: x.screengroup.acronym,
                        restrict: x.is_restricted > 0
                    };
                }).ToArray();
        }
        return /*where lookup*/ this._locationScreengroupLookups;
    }
    public set locationScreengroupLookups(values) {
        if (!this.dtm.campaignLocationScreengroups) {
            this.dtm.campaignLocationScreengroups = [];
        }
        this.dtm.campaignLocationScreengroups.length = 0;
        values = Enumerable.AsEnumerable(values).Where((x) => x.type === ScreenGroupType.Building).ToArray();

        values.forEach((x) => {
            const empty = new CampaignScreenGroupRelation();
            empty.campaign_id = this.id;
            empty.screengroup_id = x.id;
            empty.is_restricted = x.restrict;

            // empty.screengroup.name = x.name;
            // empty.screengroup.type = x.type;
            // empty.screengroup.acronym = x.acronym;
            // empty.dtm.screengroup = empty.screengroup.dtm;

            this.dtm.campaignLocationScreengroups.push(empty.dtm);
        });
        this._locationScreengroupLookups = values;
    }

    private _regularScreengroupLookups: IScreenGroupLookupRestrict[];
    public get regularScreengroupLookups() {
        if (!this._regularScreengroupLookups && this.dtm.campaignRegularScreengroups) {
            this._regularScreengroupLookups = Enumerable.AsEnumerable(this.dtm.campaignRegularScreengroups)
                .Where((x) => x.screengroup.type !== ScreenGroupType.Building).Select((x) => {
                    return {
                        id: x.screengroup_id,
                        name: x.screengroup.name,
                        type: x.screengroup.type,
                        acronym: x.screengroup.acronym,
                        restrict: x.is_restricted > 0
                    };
                }).ToArray();
        }
        return /*where lookup*/ this._regularScreengroupLookups;
    }
    public set regularScreengroupLookups(values) {
        if (!this.dtm.campaignRegularScreengroups) {
            this.dtm.campaignRegularScreengroups = [];
        }
        this.dtm.campaignRegularScreengroups.length = 0;
        values = Enumerable.AsEnumerable(values).Where((x) => x.type !== ScreenGroupType.Building).ToArray();

        values.forEach((x) => {
            const empty = new CampaignScreenGroupRelation();
            empty.campaign_id = this.id;
            empty.screengroup_id = x.id;
            empty.is_restricted = x.restrict;

            // empty.screengroup.name = x.name;
            // empty.screengroup.type = x.type;
            // empty.screengroup.acronym = x.acronym;
            // empty.dtm.screengroup = empty.screengroup.dtm;

            this.dtm.campaignRegularScreengroups.push(empty.dtm);
        });
        this._regularScreengroupLookups = values;
    }

    private _screengroupDisplayNames: string;
    public get screengroupDisplayNames() {
        if (!this._screengroupDisplayNames) {
            let groups: IScreenGroupLookupRestrict[] = [];
            if (this.locationScreengroupLookups) {
                groups = this.locationScreengroupLookups;
            }
            if (this.regularScreengroupLookups) {
                groups = groups.concat(this.regularScreengroupLookups);
            }

            const names = Enumerable.AsEnumerable(groups)
                .OrderBy((x) => ScreenGroup.getTypeOrder(x.type)).Select((x) => `${x.restrict ? ' - ' : ''}${x.name}`).ToArray();
            if (names.length) {
                this._screengroupDisplayNames = names.join(', ').trim();
            }
        }
        return /*fix*/ this._screengroupDisplayNames;
    }

    public get campaignMedia() { return this.getModelArray(() => this.dtm.campaignMedia, (x) => new CampaignMediaRelation(x), PropertyUpdateMode.Clear); }
    public set campaignMedia(value) { this.setModelArray(() => this.dtm.campaignMedia, (x) => this.dtm.campaignMedia = x, value, PropertyUpdateMode.Clear); }

    private _events: Array<EventBase<Show<any>>> = null;
    public get events() {
        if (!this._events && this.dtm.campaignEvents) {
            this._events = [];
            this.dtm.campaignEvents.forEach((x) => {
                this._events.push(new EventBase(x.event, (s) => new Show(s, (e) => new RegularEvent(e))));
            });
        }
        return /*fix*/ this._events;
    }
    public set events(value) {
        this.dtm.campaignEvents.length = 0;
        this._events = value;
        this._events.forEach((x) => {
            const empty = new CampaignEventRelation();
            empty.campaign_id = this.id;
            empty.event_id = x.id;

            this.dtm.campaignEvents.push(empty.dtm);
        });
    }


    public get eventLookups() { return this.getLookupArray(() => this.dtm.campaignEvents, (x) => this.createLookup(x.event_id, new EventBase(x.event).displayName)); }
    public set eventLookups(value) { this.setLookupArray(() => this.dtm.campaignEvents, (x) => this.dtm.campaignEvents = x, value, (item) => CampaignEventRelation.getInstance(this.id, item.id).dtm); }


    public get eventsString() {
        if (this.eventLookups && this.eventLookups.length) {
            return Enumerable.AsEnumerable(this.eventLookups)
                .Select((x) => x.name).Distinct().ToArray().join(', ');
        }
        return '';
    }

    public get schedules() { return this.getModelArray(() => this.dtm.campaignSchedules, (x) => new CampaignSchedule(x), PropertyUpdateMode.Clear); }
    public set schedules(value) { this.setModelArray(() => this.dtm.campaignSchedules, (x) => this.dtm.campaignSchedules = x, value, PropertyUpdateMode.Clear); }

    public get spotAppSources() { return this.getModelArray(() => this.dtm.spotAppSources, (x) => new SpotAppSource(x), PropertyUpdateMode.Clear); }
    public set spotAppSources(value) { this.setModelArray(() => this.dtm.spotAppSources, (x) => this.dtm.spotAppSources = x, value, PropertyUpdateMode.Clear); }


    public update(dtm: ICampaign, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        // create fake dtm props
        if ((this.dtm as ICampaignReal).campaignScreengroups) {
            // get other than building types
            this.dtm.campaignRegularScreengroups = Enumerable.AsEnumerable((this.dtm as ICampaignReal).campaignScreengroups)
                .Where((x) => x.screengroup.type !== ScreenGroupType.Building).ToArray();

            // get building types
            this.dtm.campaignLocationScreengroups = Enumerable.AsEnumerable((this.dtm as ICampaignReal).campaignScreengroups)
                .Where((x) => x.screengroup.type === ScreenGroupType.Building).ToArray();

        }

    }



    public cleanForSave(dtm: ICampaign, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTMs(CampaignEventRelation, dtm.campaignEvents, saver);
        this.cleanDTMs(CampaignSchedule, dtm.campaignSchedules, saver);
        this.cleanDTMs(CampaignMediaRelation, dtm.campaignMedia, saver);
        this.cleanDTMs(CampaignScreenGroupRelation, dtm.campaignLocationScreengroups, saver);
        this.cleanDTMs(CampaignScreenGroupRelation, dtm.campaignRegularScreengroups, saver);

        // refill actual value
        if (dtm.campaignRegularScreengroups || dtm.campaignLocationScreengroups) {
            if (!dtm.campaignRegularScreengroups) {
                dtm.campaignRegularScreengroups = [];
            }
            if (!dtm.campaignLocationScreengroups) {
                dtm.campaignLocationScreengroups = [];
            }

            (dtm as ICampaignReal).campaignScreengroups = dtm.campaignLocationScreengroups.concat(dtm.campaignRegularScreengroups);
        } else {
            (dtm as ICampaignReal).campaignScreengroups = null;
        }

        // cleanup fakes
        delete dtm.campaignRegularScreengroups;
        delete dtm.campaignLocationScreengroups;
    }

    protected clearLocalProps() {
        super.clearLocalProps();

        // this._screengroupLookups = null;
        this._locationScreengroupLookups = null;
        this._regularScreengroupLookups = null;
        this._events = null;

        this._titleProperty = null;

        this._displayName = null;
    }

}
