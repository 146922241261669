import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IResourceType, ResourceType } from './resource-type.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ResourceTypeDataService extends BaseListableDataService<ResourceType, IResourceType, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'resource-type', ObjectType.RESOURCE_TYPE, ['template_bases'], ['template_bases']);
  }

}
