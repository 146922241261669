import { BaseModel } from '../base.model';
import { DeviceDeviceRelationType, ObjectType } from '../enums/enums';
import { IDevice } from './device.model';

export interface IDeviceDeviceRelation {
    device_left_id: number;
    device_right_id: number;
    relation_type_id: DeviceDeviceRelationType;
    deviceLeft: IDevice;
    deviceRight: IDevice;
}

export class DeviceDeviceRelation extends BaseModel<IDeviceDeviceRelation> {
    constructor(dtm: IDeviceDeviceRelation = null) {
        super(dtm || {
            device_left_id: 0,
            device_right_id: 0,
            relation_type_id: DeviceDeviceRelationType.Unknown,
            deviceLeft: null,
            deviceRight: null
        });
        this.update(this.dtm, true, true);
    }

    public get left_id() {
        return this.dtm.device_left_id;
    }
    public set left_id(value) {
        this.dtm.device_left_id = value;
    }

    public get right_id() {
        return this.dtm.device_right_id;
    }
    public set right_id(value) {
        this.dtm.device_right_id = value;
    }

    public get type_id() {
        return this.dtm.relation_type_id;
    }
    public set type_id(value) {
        this.dtm.relation_type_id = value;
    }

    public update(dtm: IDeviceDeviceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IDeviceDeviceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.deviceLeft;
        delete dtm.deviceRight;

    }
}
