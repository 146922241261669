import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IScreenGroup, ScreenGroup } from '../screen-group/screen-group.model';
import { ObjectType } from '../enums/enums';

export interface ICampaignScreenGroupRelation extends IBaseRelationModel {
    campaign_id: number;
    screengroup_id: number;
    is_restricted: number;
    screengroup: IScreenGroup;
}

export class CampaignScreenGroupRelation extends BaseRelationModel <ICampaignScreenGroupRelation> {
    constructor(dtm: ICampaignScreenGroupRelation = null) {
        super(dtm || {
            campaign_id: 0,
            screengroup_id: 0,
            is_restricted: 0,
            screengroup: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get campaign_id() {
        return this.dtm.campaign_id;
    }
    public set campaign_id(value) {
        this.dtm.campaign_id = value;
    }

    public get screengroup_id() {
        return this.dtm.screengroup_id;
    }
    public set screengroup_id(value) {
        this.dtm.screengroup_id = value;
    }

    public get is_restricted() {
        return this.dtm.is_restricted === 1;
    }
    public set is_restricted(value) {
        this.dtm.is_restricted = value ? 1 : 0;
    }


    public update(dtm: ICampaignScreenGroupRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: ICampaignScreenGroupRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.screengroup;

    }

    public static getInstance(campaignID: number, screengroupID: number){
        const empty = new CampaignScreenGroupRelation();
        empty.campaign_id = campaignID;
        empty.screengroup_id = screengroupID;
        return empty;
    }
}
