import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IProductGroup, ProductGroup } from './product-group.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ProductGroupDataService extends BaseListableDataService<ProductGroup, IProductGroup, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'productgroup', ObjectType.PRODUCT_GROUP, ['products'], ['products', 'contentSchedules', 'related']);

    this.resourceDisplayName = 'Product Group';
  }

}
