import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Channel, IChannel } from '../channel/channel.model';
import { IChannelBase, ChannelBase } from '../channel/channel.base.model';
import { ObjectType } from '../enums/enums';

export interface IDeviceChannelRelation extends IBaseRelationModel {
    channel_id: number;
    device_id: number;
    id: number;
    name: string;
    channel: IChannelBase;
}

export class DeviceChannelRelation extends BaseRelationModel <IDeviceChannelRelation> {
    constructor(dtm: IDeviceChannelRelation = null) {
        super(dtm || {
            id: null,
            channel_id: 0,
            device_id: 0,
            created_at: null,
            name: '',
            created_by: null,
            channel: null
        });
        this.update(this.dtm, true, true);
    }

    public get id() {
        return this.dtm.id;
    }
    public set id(value) {
        this.dtm.id = value;
    }

    public get name() {
        return this.dtm.name;
    }
    public set name(value) {
        this.dtm.name = value;
    }

    public get channel_id() {
        return this.dtm.channel_id;
    }
    public set channel_id(value) {
        this.dtm.channel_id = value;
    }

    public get device_id() {
        return this.dtm.device_id;
    }
    public set device_id(value) {
        this.dtm.device_id = value;
    }

    public update(dtm: IDeviceChannelRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IDeviceChannelRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.channel;

    }

    public static getInstance(device_id: number, channel_id: number){
        const empty = new DeviceChannelRelation();
        empty.device_id = device_id;
        empty.channel_id = channel_id;
        return empty;
    }
}
