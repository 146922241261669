import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObjectData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ResourceStatusTechProcess, ResourceStatusTechUpload, ObjectType } from '../enums/enums';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, share } from 'rxjs/operators';

import { IResource, IResourceInit, Resource } from './resource.model';

@Injectable()
export class ResourceDataService extends BaseListableDataService<Resource, IResource, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'resource', ObjectType.RESOURCE, ['types'], ['tags', 'target-audience', 'types']);
  }
  public typelookup$: Observable<ILookup[]>;

  // override lookupItems to get the right name from groups
  public buildLookupItems() {
    super.buildLookupItems();

    this.typelookup$ = this.getTypes().pipe(share());

  }

  public init(data: IResourceInit): Observable<IResource> {
    if (data) {
      if (data.event_id === 0) {
        delete data.event_id;
      }
      if (data.resource_type_id === 0) {
        delete data.resource_type_id;
      }
    }
    return super.init(data);
  }

  public getProcessData(id: number): Observable<IResource> {
    return super.get(id);
  }

  public putUploadStatus(id: number, status: ResourceStatusTechUpload): Observable<IObjectData<IResource>> {

    return this.http.patch<IObjectData<IResource>>(`${this.apiBaseUrl}/${this.resourceName}/${id}`, { status_tech_upload: status }, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  public putProcessStatus(id: number, status: ResourceStatusTechProcess): Observable<IObjectData<IResource>> {

    return this.http.patch<IObjectData<IResource>>(`${this.apiBaseUrl}/${this.resourceName}/${id}`, { status_tech_process: status }, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

}
