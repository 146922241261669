import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ITag, Tag } from '../tag/tag.model';
import { ObjectType } from '../enums/enums';

export interface IMediaTagRelation extends IBaseRelationModel {
    media_id: number;
    tag_id: number;
    tag: ITag;
}

export class MediaTagRelation extends BaseRelationModel <IMediaTagRelation> {
    constructor(dtm: IMediaTagRelation = null) {
        super(dtm || {
            media_id: 0,
            tag_id: 0,
            tag: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get media_id() {
        return this.dtm.media_id;
    }
    public set media_id(value) {
        this.dtm.media_id = value;
    }

    public get tag_id() {
        return this.dtm.tag_id;
    }
    public set tag_id(value) {
        this.dtm.tag_id = value;
    }

    public update(dtm: IMediaTagRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IMediaTagRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.tag;
    }

    public static getInstance(media_id: number, tag_id: number){
        const empty = new MediaTagRelation();
        empty.media_id = media_id;
        empty.tag_id = tag_id;
        return empty;
    }
}
