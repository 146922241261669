import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { PropertyIntentID, Status, StatusSystem, InputElementType, ObjectType } from '../enums/enums';
import { IBaseCreationModel, BaseCreationModel } from '../base-creation.model';

// tslint:disable-next-line:no-empty-interface
export interface IPropertyIntent extends IBaseCreationModel { // IBaseNameModel {
    // comment
    id: PropertyIntentID;
    input_element_regex: string;
    input_element_type_id: InputElementType;
}

export class PropertyIntent extends BaseCreationModel<IPropertyIntent> {
    constructor(dtm: IPropertyIntent = null) {
        super(dtm || {
            id: PropertyIntentID.Undefined,
            input_element_regex: null,
            input_element_type_id: InputElementType.Default,
            client_id: null,
            // name: '',

            // status: Status.Active,
            // status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);

    }

    public get id() {
        return this.dtm.id;
    }
    public set id(value) {
        this.dtm.id = value;
    }
    public get isEndDate() {
        return this.endDates.indexOf(this.id) > -1;
    }

    public get timePlaceholder() {
        if (this.isEndDate) {
            return '23:59';
        } else {
            return '00:00';
        }
    }

    private endDates = [PropertyIntentID.EventShowStop];

    public update(dtm: IPropertyIntent, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IPropertyIntent, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

    }
}
