import { BaseModel, IBaseModel } from './base.model';

export interface IBaseIdModel extends IBaseModel {
    id: number;
    client_id: number;
}

export abstract class BaseIdModel<IT extends IBaseIdModel> extends BaseModel<IT> {
    constructor(dtm: IT) {
        super(dtm);
    }

    public get id() {
        return this.dtm.id;
    }
    public set id(value) {
        this.dtm.id = value;
    }

    public get client_id() {
        return this.dtm.client_id;
    }
    public set client_id(value) {
        this.dtm.client_id = value;
    }

    public static GetInstances<T, IT>(c: new (dtm: IT) => T, dtms: IT[]) {
        const items: T[] = [];
        dtms.forEach((dtm) => items.push(new c(dtm)));
        return items;
    }

}
