import { NumberSymbol } from "@angular/common";
import { BaseModel, IBaseModel } from "../base.model";

export interface IDeviceChannelPinpointGenericData extends IBaseModel {
    i: number;
    a: number;
    n: string;
}

export class DeviceChannelPinpointItemData extends BaseModel<IDeviceChannelPinpointGenericData> {
    constructor(dtm: IDeviceChannelPinpointGenericData) {
        super(dtm);
        this.update(this.dtm, true, true);
    }


    public get id() {
        return this.dtm.i;
    }
    public get name() {
        return this.dtm.n;
    }



    public update(dtm: IDeviceChannelPinpointGenericData, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

}
