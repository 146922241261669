import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IShow, Show } from './show.model';
import { EventBase } from '../event/event.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ShowDataService<TShow extends Show<EventBase<any>>> extends BaseListableDataService<TShow, IShow, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'event-show', ObjectType.EVENT_SHOW, ['related', 'event'], ['related', 'event']);

  }
}
