import * as Enumerable from 'linq-es2015';
import { IGrouping } from 'linq-es2015/lib/enumerable';
import { Moment } from 'moment';

export interface IFilterMy {
    key: string;
    value: number | string;
}

export class FilterMy {
    constructor(dtm: IFilterMy = null) {
        this.dtm = dtm || {
            key: '',
            value: ''
        };
        this.update(this.dtm, true, true);
    }

    public get key() {
        return this.dtm.key;
    }
    public set key(value) {
        this.dtm.key = value;
    }

    public get value() {
        return this.dtm.value;
    }
    public set value(value) {
        this.dtm.value = value;
    }
    public get operator() {
        return this._operator;
    }
    public set operator(value) {
        this._operator = value;
    }
    public get andGroupKey() {
        return this._andGroupKey;
    }
    public set andGroupKey(value) {
        this._andGroupKey = value;
    }
    public get andGroupKeyPure() {
        return FilterMy.getAndGroupKeyPure(this.andGroupKey);
    }
    public get andGroupKeyOrder(): number | undefined {
        let order: number; // undefined
        const orderString = this.andGroupKey.split('-')[1];
        if (orderString && !isNaN(parseInt(orderString, 10))) {
            order = parseInt(orderString, 10);
        }
        return order;
    }

    private _operator = 'eq';

    private _andGroupKey = 'none';

    public dtm: IFilterMy;
    public static getAndGroupKeyPure(andGroupKey: string) {
        return andGroupKey.split('-')[0];
    }

    public static getQueryString(prefix: string, items: FilterMy[]) {
        // add items with andGroupKey with 'and' seperator, else create new $filter query param
        let filter = '';
        if (items) {
            let andGroups: Array<IGrouping<string, FilterMy>> = [];
            const pureAndGroups = Enumerable.AsEnumerable(items)
                .Where((x) => x.andGroupKeyOrder === undefined || x.andGroupKeyOrder === 0).GroupBy((x) => x.andGroupKeyPure).ToArray();
            andGroups = andGroups.concat(pureAndGroups);

            // copy and groups that have items in them taht can be switched out
            // used for date range filters
            const nonPureAndGroups = Enumerable.AsEnumerable(items).Where((x) => x.andGroupKeyOrder > 0).GroupBy((x) => x.andGroupKey).ToArray();
            nonPureAndGroups.forEach((nonPureGroup, i) => {
                const pureGroupMatch = Enumerable.AsEnumerable(pureAndGroups)
                    .FirstOrDefault((x) => x.key === FilterMy.getAndGroupKeyPure(nonPureGroup.key));
                if (pureGroupMatch) {
                    const pureGroupMatchItems = Enumerable.AsEnumerable(pureGroupMatch).ToArray();

                    this.removeWithOrder(pureGroupMatchItems, 0); // remove items that need to be replaced

                    // add new items
                    Enumerable.AsEnumerable(nonPureGroup).ToArray().forEach((nonPureGroupItem) => {
                        pureGroupMatchItems.push(nonPureGroupItem);
                    });

                    // to get it back to type IGrouping
                    const pureGroupCopy = Enumerable.AsEnumerable(pureGroupMatchItems).GroupBy((x) => x.andGroupKeyPure).FirstOrDefault();

                    andGroups.push(pureGroupCopy);
                }

            });

            andGroups.forEach((group, i) => {
                Enumerable.AsEnumerable(group).ToArray().forEach((item, i2) => {
                    if (i2 === 0 || group.key === 'none') {
                        filter = `${filter}${filter.length ? '&' : ''}$filter[]=${item.key} ${item.operator} ${item.value}`;
                    } else {
                        filter = `${filter} and ${item.key} ${item.operator} ${item.value}`;
                    }
                });
            });
        }

        if (filter.length) {
            filter = `${prefix}${filter}`;
        }

        return filter;
    }

    public static remove(filters: FilterMy[], key: string) {
        const toRemove = Enumerable.AsEnumerable(filters).Where((x) => x.key === key).ToArray();

        toRemove.forEach((sort) => {
            filters.splice(filters.indexOf(sort), 1);
        });
    }

    public static removeExact(filters: FilterMy[], key: string, andGroupKey: string, operator: string) {
        const toRemove = Enumerable.AsEnumerable(filters)
            .Where((x) => x.key === key && x.andGroupKey === andGroupKey && x.operator === operator).ToArray();

        toRemove.forEach((sort) => {
            filters.splice(filters.indexOf(sort), 1);
        });
    }

    public static removeWithOrder(filters: FilterMy[], order: number) {
        const toRemove = Enumerable.AsEnumerable(filters).Where((x) => x.andGroupKeyOrder === order).ToArray();

        toRemove.forEach((sort) => {
            filters.splice(filters.indexOf(sort), 1);
        });
    }

    public static create(key: string, value: number | string, andGroupKey: string = null, operator: string = null) {
        const filter = new FilterMy({ key, value });
        if (andGroupKey) {
            filter.andGroupKey = andGroupKey;
        }
        if (operator) {
            filter.operator = operator;
        }
        return filter;
    }

    public static createRangeFilters(startDate: Moment | string, endDate: Moment | string, startKey: string, endKey: string, andGroupKey: string) {

        let startDateString = null;
        if (startDate) {
            if (typeof startDate === 'string') {
                startDateString = startDate as string;
            } else {
                startDateString = (startDate as Moment).format('YYYY-MM-DD');
            }
        }

        let endDateString = null;
        if (endDate) {
            if (typeof endDate === 'string') {
                endDateString = endDate as string;
            } else {
                endDateString = (endDate as Moment).format('YYYY-MM-DD');
            }
        }

        // use 'lt' (less than) for end dates
        // reason: 'le' (less or equal) will not work if time part in use

        // $skip=0&$top=100&$include[]=related&$include[]=event&$include[]=commandsGroupedWithTuner&$filter[]=related_conf_base_type_id eq 3 and i-15 le 2023-10-14 and i-16 ge 2023-10-10&$orderby[]=i-15 asc

        const filters: FilterMy[] = [];


        // start before end and end after start
        {
            const startFilter = FilterMy.create(startKey, endDateString, andGroupKey + '-0', 'le');
            filters.push(startFilter);

            const endFilter = FilterMy.create(endKey, startDateString, andGroupKey + '-0', 'ge');
            filters.push(endFilter);
        }

        // // start between
        // {
        //     const startFilter = FilterMy.create(startKey, startDateString, andGroupKey + '-0', 'ge');
        //     filters.push(startFilter);

        //     const endFilter = FilterMy.create(startKey, endDateString, andGroupKey + '-0', 'lt');
        //     filters.push(endFilter);
        // }

        // // end between
        // {
        //     const startFilter = FilterMy.create(endKey, startDateString, andGroupKey + '-1', 'ge');
        //     filters.push(startFilter);

        //     const endFilter = FilterMy.create(endKey, endDateString, andGroupKey + '-1', 'lt');
        //     filters.push(endFilter);
        // }

        // // start and end around
        // {
        //     const startFilter = FilterMy.create(startKey, startDateString, andGroupKey + '-2', 'lt');
        //     filters.push(startFilter);

        //     const endFilter = FilterMy.create(endKey, endDateString, andGroupKey + '-2', 'ge');
        //     filters.push(endFilter);
        // }

        return filters;
    }

    public update(dtm: IFilterMy, forceReplaceDtm = false, fromConstructor = false) {
        if (this.dtm && !forceReplaceDtm) {
            Object.assign(this.dtm, dtm);
        } else {
            this.dtm = dtm;
        }
        // super.update(dtm);

    }
}
