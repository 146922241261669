import { Status, StatusSystem, ObjectType, ApectRatioRestrictionMode } from '../enums/enums';
import { ILocationScreenGroupRelation, LocationScreenGroupRelation } from './location-screengroup-relation.model';
import { IScreenGroupLookup, ILookup } from '../lookup.model';
import { IResource } from '../resource/resource.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { IBaseRelatedConfModel, BaseRelatedConfModel } from '../base-relatedconf.model';
import { ITimeRangeRelation, TimeRangeRelation } from '../time-range/time-range-relation.model';
import { TimeRange } from '../time-range/time-range.model';

export interface ILocationPlayerOptionSet {
    mediaCacheUrl?: string;
}

export interface ILocationPlaylistOptionSet {
    apect_ratio_restriction_mode?: ApectRatioRestrictionMode;
}

export interface ILocationOptionSet {
    player_option_set?: ILocationPlayerOptionSet;
    playlist_option_set?: ILocationPlaylistOptionSet;
}


export interface ILocationMy extends IBaseRelatedConfModel {
    url: string;
    background_resource_id: number;
    backgroundResource: IResource;
    language_set: string;
    acronym: string;
    screengroupLocations: ILocationScreenGroupRelation[];
    time_zone: string;
    ext_id: string;
    timerangeRelations: ITimeRangeRelation[];
    option_set: ILocationOptionSet; // TODO: remove when option_set in not string
}

export class LocationMy extends BaseRelatedConfModel<ILocationMy> {
    constructor(dtm: ILocationMy = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            url: '',
            background_resource_id: 0,
            backgroundResource: null,
            language_set: '',
            acronym: '',
            ext_id: '',
            time_zone: null,
            screengroupLocations: [],
            related_conf_base_id: 0,
            relatedConfBase: null,
            property_set: null,
            timerangeRelations: null,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null,
            option_set: {

            }
        });
        this.update(this.dtm, true, true);
    }

    public get url() {
        return this.dtm.url;
    }
    public set url(value) {
        this.dtm.url = value;
    }

    public get language_set() {
        return this.dtm.language_set;
    }
    public set language_set(value) {
        this.dtm.language_set = value;
    }

    public get acronym() {
        let ret = '';
        if (this.screengroup_lookup) {
            ret = this.screengroup_lookup.acronym;
        }
        return ret;
    }
    public set acronym(value) {
        // if(this.dtm.screengroupLocations && this.dtm.screengroupLocations.length){
        //     this.dtm.screengroupLocations[0].screengroup.acronym = value;
        // }
        // if(this.screengroup_lookup){
        //     this.screengroup_lookup.acronym = value;
        // }
        this.dtm.acronym = value;
    }

    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    // public get isSystemObject() {
    //     return !this.ext_id || this.ext_id.length === 0;
    // }

    private _screengroupLookup: IScreenGroupLookup;
    public get screengroup_lookup() {
        if (!this._screengroupLookup && this.dtm.screengroupLocations && this.dtm.screengroupLocations.length) {
            this._screengroupLookup = Enumerable.AsEnumerable(this.dtm.screengroupLocations).Select((x) => {
                return {
                    id: x.screengroup_id,
                    name: x.screengroup.name,
                    type: x.screengroup.type,
                    acronym: x.screengroup.acronym
                };
            }).First();
        }
        return /*first lookup*/ this._screengroupLookup;
    }

    public get screengroup_id() {
        if (this.screengroup_lookup) {
            return this.screengroup_lookup.id;
        }
        return 0;
    }

    public get screengroup_name() {
        if (this.screengroup_lookup) {
            return this.screengroup_lookup.name;
        }
        return 0;
    }

    public get has_screengroup() {
        return this.screengroup_id > 0;
    }

    public get background_resource_id() {
        return this.dtm.background_resource_id;
    }
    public set background_resource_id(value) {
        this.dtm.background_resource_id = value;
    }


    public get background_resource_name() {
        if (this.dtm.backgroundResource) {
            return this.dtm.backgroundResource.name;
        }
        return `ID: ${this.background_resource_id}`;
    }

    public get has_background_resource() {
        return this.background_resource_id > 0;
    }

    public get backgroundResourceLookup() { return this.getLookupProperty(() => this.dtm.background_resource_id, (x) => this.createLookup(this.dtm.background_resource_id, this.background_resource_name)); }
    public set backgroundResourceLookup(value) { this.setLookupProperty(() => this.dtm.background_resource_id, (x) => { this.dtm.background_resource_id = x; }, value, (item) => item.id); }


    public get time_zone() {
        return this.dtm.time_zone;
    }
    public set time_zone(value) {
        this.dtm.time_zone = value;
    }

    public get timeRangeLookups() { return this.getLookupArray(() => this.dtm.timerangeRelations, (x) => this.createLookup(x.timerange_id, x.timerange?.name)); }
    public set timeRangeLookups(value) { this.setLookupArray(() => this.dtm.timerangeRelations, (x) => this.dtm.timerangeRelations = x, value, (item) => TimeRangeRelation.getInstance(this.id, item.id).dtm); }


    public get optionApectRatioRestrictionMode() {
        return this.dtm.option_set && this.dtm.option_set.playlist_option_set ? this.dtm.option_set.playlist_option_set.apect_ratio_restriction_mode : null;
    }
    public set optionApectRatioRestrictionMode(value) {
        this.ensureLocationPlaylistOptionSet();
        if (value !== null) {
            this.dtm.option_set.playlist_option_set.apect_ratio_restriction_mode = value;
        } else {
            delete this.dtm.option_set.playlist_option_set.apect_ratio_restriction_mode;
        }
    }


    public update(dtm: ILocationMy, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);


    }

    protected clearLocalProps() {
        super.clearLocalProps();

        this._screengroupLookup = null;
    }

    public cleanForSave(dtm: ILocationMy, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.backgroundResource;

        this.cleanDTMs(LocationScreenGroupRelation, dtm.screengroupLocations, saver);
        this.cleanDTMs(TimeRangeRelation, dtm.timerangeRelations, saver);
    }



    private ensureLocationOptionSet() {
        if (!this.dtm.option_set  || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as ILocationOptionSet;
        }
    }
    private ensureLocationPlaylistOptionSet() {
        this.ensureLocationOptionSet();
        if (!this.dtm.option_set.playlist_option_set || Array.isArray(this.dtm.option_set.playlist_option_set)) {
            this.dtm.option_set.playlist_option_set = {} as ILocationPlaylistOptionSet;
        }
    }
    private ensureLocationPlayerOptionSet() {
        this.ensureLocationOptionSet();
        if (!this.dtm.option_set.player_option_set || Array.isArray(this.dtm.option_set.player_option_set)) {
            this.dtm.option_set.player_option_set = {} as ILocationPlayerOptionSet;
        }
    }
}
