import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';
import { IGenre, Genre } from './genre.model';
import { ILookup } from '../lookup.model';
import { BaseListableDataService } from '../base-listable-data.service';
import { ObjectType } from '../enums/enums';

@Injectable()
export class GenreDataService extends BaseListableDataService<Genre, IGenre, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'genre', ObjectType.GENRE, [], []);
  }

}
