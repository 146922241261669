import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { PlaylistCalculationFrequency } from '../enums/enums';
// fake service for lookup
@Injectable()
export class PlaylistCalculationFrequencyDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: null, name: 'Default' });
        lookups.push({ id: PlaylistCalculationFrequency.OnceInHour, name: 'Once In Hour' });
        lookups.push({ id: PlaylistCalculationFrequency.TwiceInHour, name: 'Twice In Hour' });
        lookups.push({ id: PlaylistCalculationFrequency.OnceInTwoHours, name: 'Once In Two Hours' });
        lookups.push({ id: PlaylistCalculationFrequency.OnceInRange, name: 'Once In Day' });
        lookups.push({ id: PlaylistCalculationFrequency.TwiceInRange, name: 'Twice In Day' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
