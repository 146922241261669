import { BaseNameModel, IBaseNameModel } from '../base-name.model';

import { ObjectType, RelatedObjectType, Status, StatusSystem } from '../enums/enums';

export interface IProperty extends IBaseNameModel {
    related_property_object_type_id: RelatedObjectType;
    related_property_model_object_type_id: ObjectType;
    is_lang_dependent: number;
    related_property_allow_multiple: number;
    is_overridable: number;
    is_synced: number;
}

export class Property extends BaseNameModel<IProperty> {
    constructor(dtm: IProperty = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            // related_conf_base_id: 0,
            // related_conf_property_intent_id: null,
            related_property_object_type_id: null,
            related_property_model_object_type_id: null,
            is_lang_dependent: null,
            related_property_allow_multiple: 0,

            is_overridable: 0,
            is_synced: 0,

            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get id() {
        return this.dtm.id;
    }
    public set id(value) {
        this.dtm.id = value;
    }

    public get name() {
        return this.dtm.name;
    }
    public set name(value) {
        this.dtm.name = value;
    }

    public get required(): boolean {
        return null;
    }

    public get placeholder(): string {
        return null;
    }

    public get hint(): string {
        return null;
    }

    public get groupTitle(): string {
        return null;
    }

    public get objectType() {
        return this.dtm.related_property_model_object_type_id;
    }
    

    public get isString() {
        return this.dtm.related_property_object_type_id === RelatedObjectType.CONTENT;
    }
    public get isDate() {
        return this.dtm.related_property_object_type_id === RelatedObjectType.DATETIME;
    }
    public get isObject() {
        return this.dtm.related_property_object_type_id === RelatedObjectType.OBJECT;
    }
    public get isBoolean() {
        return this.dtm.related_property_object_type_id === RelatedObjectType.BOOLEAN;
    }
    public get isUnknown() {
        return !this.isString && !this.isDate && !this.isObject && !this.isBoolean;
    }

    public get allowMultiple() {
        return this.dtm.related_property_allow_multiple > 0;
    }

    public get isLangDependent() {
        return this.dtm.is_lang_dependent > 0;
    }

    public get isOverridable() {
        return this.dtm.is_overridable > 0 && this.isSynced; // only synced properties can be overriden
    }

    public get isSynced() {
        return this.dtm.is_synced > 0;
    }

    public update(dtm: IProperty, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IProperty, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.is_lang_dependent;
        delete dtm.related_property_allow_multiple;
        delete dtm.is_overridable;
        delete dtm.is_synced;
    }
}
