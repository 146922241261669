import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IProductPrice, ProductPrice } from './product-price.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ProductPriceDataService extends BaseListableDataService<ProductPrice, IProductPrice, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'productprice', ObjectType.PRODUCT_PRICE, ['products', 'productpricegroups'], ['products', 'productpricegroups']);

    this.resourceDisplayName = 'Product Price';
  }

}
