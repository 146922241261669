import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ApplicationInsightsService {
  private routerSubscription: Subscription;

  private appInsights: ApplicationInsights;

  constructor(private router: Router) {

    if (environment.ApplicationInsightsInstrumentationKey && environment.ApplicationInsightsInstrumentationKey.length) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.ApplicationInsightsInstrumentationKey
        }
      });


      this.appInsights.loadAppInsights();
      this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
      });
    }
  }

  setUserId(userId: string) {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  clearUserId() {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (this.appInsights) {
      this.appInsights.trackPageView({ name, uri });
    }
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
