import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MessageBoxResult } from './message-box-result';

interface IDialogData {
  okLabel: string;
  cancelLabel: string;
  title: string;
  content: string;
}

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {

  }

  public ngOnInit() {
    // comment
  }

  public onOK() {
    this.dialogRef.close(MessageBoxResult.OK);
  }

  public onCancelClick() {
    this.dialogRef.close(MessageBoxResult.Cancel);
  }

}
