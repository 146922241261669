import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ObjectType } from '../enums/enums';
import { Device, IDevice } from '../device/device.model';
import { PropertyUpdateMode } from '../base.model';

export interface ITagDeviceRelation extends IBaseRelationModel {
    device_id: number;
    tag_id: number;
    device: IDevice;
}

export class TagDeviceRelation extends BaseRelationModel <ITagDeviceRelation> {
    constructor(dtm: ITagDeviceRelation = null) {
        super(dtm || {
            device_id: 0,
            tag_id: 0,
            device: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get deviceID() {
        return this.dtm.device_id;
    }
    public set deviceID(value) {
        this.dtm.device_id = value;
    }

    public get tagID() {
        return this.dtm.tag_id;
    }
    public set tagID(value) {
        this.dtm.tag_id = value;
    }

    public get deviceName() {
        if (this.dtm.device) {
            return this.dtm.device.name;
        }
        return null;
    }

    public get device() { return this.getModelProperty(() => this.dtm.device, (x) => new Device(x), PropertyUpdateMode.Update); }
    public set device(value) { this.setModelProperty(() => this.dtm.device, (x) => this.dtm.device = x, value, PropertyUpdateMode.Update); }

    public update(dtm: ITagDeviceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.device, (x) => new Device(x), (x) => this.device = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: ITagDeviceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.device;

    }

    public static getInstance(tagID: number, deviceID: number){
        const empty = new TagDeviceRelation();
        empty.tagID = tagID;
        empty.deviceID = deviceID;
        return empty;
    }
}
