import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { TemplateBase, ITemplateBase } from './template-base.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class TemplateBaseDataService extends BaseListableDataService<TemplateBase, ITemplateBase, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'template-base', ObjectType.TEMPLATE_BASE, ['resource_types'], ['resource_types']);
  }

}
