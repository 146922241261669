import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { SpotAppEqLevel } from '../enums/enums';

// fake service for lookup
@Injectable()
export class SpotAppEqLevelDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    public lookupItemsForEvent$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: SpotAppEqLevel.MediaVersion, name: 'Media Version' });
        lookups.push({ id: SpotAppEqLevel.Media, name: 'Media' });
        lookups.push({ id: SpotAppEqLevel.Campaign, name: 'Campaign' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });

        const lookupsForEvent: ILookup[] = [];

        lookupsForEvent.push({ id: SpotAppEqLevel.Media, name: 'Media' });
        lookupsForEvent.push({ id: SpotAppEqLevel.Campaign, name: 'Campaign' });

        this.lookupItemsForEvent$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookupsForEvent);
            observer.complete();
        });
    }
}
