import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ObjectType } from '../enums/enums';
import { IScreenGroupLookupRestrict } from '../lookup-restrict.model';
import { ILookup, IScreenGroupLookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { IScreenGroup, ScreenGroup } from './screen-group.model';

@Injectable()
export class ScreenGroupDataService extends BaseListableDataService<ScreenGroup, IScreenGroup, IScreenGroupLookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'screengroup', ObjectType.SCREEN_GROUP, ['devices'], ['devices']);

  }

  public typelookup$: Observable<ILookup[]>;

  public lookupItemsForDeviceChannel$: Observable<IScreenGroupLookup[]>;
  public lookupItemsForChannel$: Observable<IScreenGroupLookup[]>;
  public lookupItemsForDevice$: Observable<IScreenGroupLookup[]>;
  public lookupItemsForMedia$: Observable<IScreenGroupLookupRestrict[]>;
  public lookupItemsForCampaign$: Observable<IScreenGroupLookupRestrict[]>;
  public lookupItemsForSchedule$: Observable<IScreenGroupLookup[]>;

  public buildLookupItems() {
    super.buildLookupItems();

    this.typelookup$ = this.getTypes().pipe(share());

    this.lookupItemsForDeviceChannel$ = this.getLookup(`target_type=${ObjectType.SCREEN}`).pipe(share());

    this.lookupItemsForChannel$ = this.getLookup(`target_type=${ObjectType.CHANNEL}`).pipe(share());

    this.lookupItemsForDevice$ = this.getLookup(`target_type=${ObjectType.DEVICE}`).pipe(share());

    this.lookupItemsForMedia$ = (this.getLookup(`target_type=${ObjectType.MEDIA}`) as Observable<IScreenGroupLookupRestrict[]>)
      .pipe(share());
    // `&target_type=${ObjectType.}`

    this.lookupItemsForCampaign$ = (this.getLookup(`target_type=${ObjectType.CAMPAIGN}`) as Observable<IScreenGroupLookupRestrict[]>)
      .pipe(share());

    // TODO: we should rethink what groups we want to schedule
    this.lookupItemsForSchedule$ = this.getLookup(`target_type=${ObjectType.PROGRAM_SCHEDULE}`)
      .pipe(share());

    // TODO: we should rethink what groups we want to schedule
    // this.lookupItemsForSchedule$ = this.getLookup()
    // .pipe(share());

    // this.lookupItemsForSchedule$ = this.getLookup()
    // .pipe(map(x => {
    //   return Enumerable.AsEnumerable(x).Where(x => x.type == ScreenGroupType.General).ToArray();
    // }), share());

  }

}
