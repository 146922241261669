import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CollectionItem, ICollectionItem } from './collection.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class TrashDataService extends BaseListableDataService<CollectionItem, ICollectionItem, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'collection/trash', ObjectType.COLLECTION, [], []);
  }

  public restore(id: number): Observable<any> {
    return super.deleteData(id).pipe(tap((value) => {
      this.toastr.success(`Object restored`, 'Success!');
    }, (error) => {
      this.toastr.error(`Failed to restore Object`, 'Error!');
    }, () => {
      this.resetCachedObservables();
    }));
  }
}
