import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Route, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService  {
    constructor(private authService: AuthService, private router: Router) {
        // comment
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): boolean {
        const url = `/${route.path}`;
        return this.checkLogin(url);
    }

    private checkLogin(url: string): boolean {
        if (this.authService.isLoggedIn) { return true; }

        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;

        //// Create a dummy session id
        // let sessionId = 123456789;

        //// Set our navigation extras object
        //// that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: { redirectUrl: url },
            // fragment: 'anchor'
        };

        // Navigate to the login page with extras
        this.router.navigate(['auth', 'login'], navigationExtras);
        return false;
    }
}
