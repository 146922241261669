import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { ProductPriceGroupType } from '../enums/enums';

// fake service for lookup
@Injectable()
export class ProductPriceGroupTypeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: ProductPriceGroupType.Loyalty, name: 'Loyalty' });
        lookups.push({ id: ProductPriceGroupType.SalesPoint, name: 'Sales Point' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
