import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService, IListData } from '../base-data.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { IUser, User } from './user.model';
import { ToasterService } from '../../services/toaster.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup, ILookupWithStringID } from '../lookup.model';
import { FilterMy } from '../filter/filter.model';
import { SortMy } from '../sort/sort.model';
import * as Enumerable from 'linq-es2015';
import { ClientDataService } from '../client/client-data.service';
import { IChangePassword } from './change-password.model';
import { ObjectType } from '../enums/enums';
import { ApplicationInsightsService } from 'app/core/services/application-insights.service';

@Injectable()
export class UserDataService extends BaseListableDataService<User, IUser, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService, private applicationInsightsService: ApplicationInsightsService) {
    super(http, toastr, 'user', ObjectType.USER, [], []);
  }

  public sessionUser: User;

  public get(id: number) {
    return this.getData(id, []).pipe(share());
  }
  // public sessionUser$: Observable<User>;
  public sessionGet() {
    return this.sessionGetData().pipe(map((x) => {
      return new User(x);
    }), tap((value) => {
      this.sessionUser = value;
      try {
        this.applicationInsightsService.setUserId(value.username);
      } catch (ex) {
        console.error(ex);
      }
    }, (error) => {
      console.error(error);
      try {
        this.applicationInsightsService.clearUserId();
      } catch (ex) {
        console.error(ex);
      }
    }), share());
  }

  public sessionChangePassword(oldPass: string, newPass: string, confirmNewPass: string, alertSucces = true) {
    return this.changePasswordData({ old_password: oldPass, password: newPass, password_confirmation: confirmNewPass }).pipe(map((x) => {
      return true;
    }), tap((value) => {
      if (alertSucces) {
        this.toastr.success(`${this.resourceDisplayName} password changed`, 'Success!');
      }
    }, (error) => {
      this.toastr.error(`Failed to change password`, 'Error!');
    }));
  }

  private sessionGetData(): Observable<IUser> {
    return this.http.get<IUser>(`${this.apiBaseUrl}/session/${this.resourceName}`, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  private changePasswordData(model: IChangePassword): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/${this.resourceName}/change-password`, model, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  protected getLookupDatas(
    pageNr: number, pageSize: number,
    filters: FilterMy[], sorts: SortMy[], includes: string[],
    search = '', query = '', subResourceName = ''): Observable<IListData<ILookup>> {

    sorts = [new SortMy({ key: 'created_at', direction: 'desc' })];

    return this.getPage(pageNr, pageSize, filters, sorts, search, query, subResourceName, includes).pipe(map((res) => {

      return {
        data: Enumerable.AsEnumerable(res.data).Select((x) => {
          const user = new User(x);

          return {
            id: x.id,
            name: `${user.name} (${user.username})`
          };
        }).ToArray(),
        filter: res.filter,
        totalCount: res.totalCount,
      };
    }));
  }

  public getRolesLookup(): Observable<ILookupWithStringID[]> {
    return this.getRolesLookupData().pipe(map((x) => x.data)
      , tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to get ${this.resourceDisplayName} roles data`, 'Error!');
      }));
  }

  protected getRolesLookupData(): Observable<IListData<ILookupWithStringID>> {
    const url = `${this.apiBaseUrl}/${this.resourceName}/available-roles`;
    return this.http.get<IListData<ILookupWithStringID>>(url, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

}
