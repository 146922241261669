import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import {
    ResourceStatusTechProcess,
    ResourceStatusTechUpload,
    ResourceMainType,
    Status,
    StatusSystem,
    FileType,
    ObjectType,
} from '../enums/enums';
import { FileMy, IFileMy } from '../file/file.model';
import { UploadItem } from '../upload-item/upload-item.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { MyJob } from '../background-job/background-job.model';
import { IResourceResourceTypeRelation, ResourceResourceTypeRelation } from './resource-resource-type-relation.model';
import { ILookup } from '../lookup.model';
import { KeyValue } from '@angular/common';

export interface IResource extends IBaseNameModel {
    hash: string;
    type_id: ResourceMainType;

    status: Status;

    files: IFileMy[];
    resourceResourceTypes: IResourceResourceTypeRelation[];

    status_tech_upload: ResourceStatusTechUpload;
    status_tech_process: ResourceStatusTechProcess;
    upload_progress_current: number;
    upload_progress_total: number;
    process_progress_current: number;
    process_progress_total: number;

}

export interface IResourceInit {
    name: string;
    // mime_type: string;
    type_id: ResourceMainType;

    resource_type_id: number;
    event_id: number;
    adspace_id: number;
}

export class Resource extends BaseNameModel<IResource> {
    constructor(dtm: IResource = null) {
        super(dtm || {
            id: 0,
            type_id: ResourceMainType.Undefined,
            client_id: 0,
            hash: '',
            name: '',

            status: Status.Active,
            status_system: StatusSystem.Default,

            files: [],
            resourceResourceTypes: [],
            status_tech_upload: ResourceStatusTechUpload.Undefined,
            status_tech_process: ResourceStatusTechProcess.Undefined,
            upload_progress_current: 0,
            upload_progress_total: 0,
            process_progress_current: 0,
            process_progress_total: 0,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get hash() {
        return this.dtm.hash;
    }
    public set hash(value) {
        this.dtm.hash = value;
    }

    public get type() {
        return this.dtm.type_id;
    }
    public set type(value) {
        this.dtm.type_id = value;
    }

    public get mainTypeName() {
        return ResourceMainType[this.type] ? ResourceMainType[this.type]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get allTypesName() {
        let names = [this.mainTypeName];

        if (this.resourceTypeLookups) {
            names = names.concat(Enumerable.From(this.resourceTypeLookups)
                .Select((x) => x.name).Distinct().ToArray());
        }
        return names.join(' - ');
    }

    public get resourceTypeLookups() { return this.getLookupArray(() => this.dtm.resourceResourceTypes, (x) => this.createLookup(x.type_id, x.resourceType?.name)); }
    public set resourceTypeLookups(value) { this.setLookupArray(() => this.dtm.resourceResourceTypes, (x) => this.dtm.resourceResourceTypes = x, value, (item) => ResourceResourceTypeRelation.getInstance(this.id, item.id).dtm); }



    public get status_tech_upload() {
        return this.dtm.status_tech_upload;
    }
    public set status_tech_upload(value) {
        this.dtm.status_tech_upload = value;
    }

    public get status_tech_process() {
        return this.dtm.status_tech_process;
    }
    public set status_tech_process(value) {
        this.dtm.status_tech_process = value;
    }

    public get statusTechProcessName() {
        return ResourceStatusTechProcess[this.dtm.status_tech_process]
            ? ResourceStatusTechProcess[this.dtm.status_tech_process]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get upload_progress_current() {
        return this.dtm.upload_progress_current;
    }
    public set upload_progress_current(value) {
        this.dtm.upload_progress_current = value;
    }
    public get upload_progress_total() {
        return this.dtm.upload_progress_total;
    }
    public set upload_progress_total(value) {
        this.dtm.upload_progress_total = value;
    }

    public get process_progress_current() {
        return this.dtm.process_progress_current;
    }
    public set process_progress_current(value) {
        this.dtm.process_progress_current = value;
    }

    public get process_progress_total() {
        return this.dtm.process_progress_total;
    }
    public set process_progress_total(value) {
        this.dtm.process_progress_total = value;
    }



    public get processProgressDisplayName() {
        if (this.process_progress_total > 1) {
            return `${this.statusTechProcessName} ${this.process_progress_current}/${this.process_progress_total}`;
        }
        return `${this.statusTechProcessName}...`;
    }

    public get resolution() {
        // if (!this.fileWidth || !this.fileHeight) {
        //     return 'unprocessed';
        // }
        return `${this.fileWidth}x${this.fileHeight}`;
    }
    public get uploadItem() {
        return this._uploadItem;
    }
    // public file: File = null;
    // public blobProgresses: IBlobProgress[] = [];

    public get isCreated() {
        return this.id > 0;
    }

    public get isVideo() {
        return this.mimeType && this.mimeType.toLowerCase().indexOf('video') > -1;
    }

    public get isImage() {
        return this.mimeType && this.mimeType.toLowerCase().indexOf('image') > -1;
    }

    public get isProcessing() {
        return this.status_tech_process === ResourceStatusTechProcess.Processing; // this.upload_status == UploadStatus.Processing;
    }

    public get isProcessed() {
        return this.status_tech_process === ResourceStatusTechProcess.Processed;
    }

    public get isProcessError() {
        return this.status_tech_process === ResourceStatusTechProcess.ProcessError;
    }
    public get isUploadingCanceled() {
        return !this.isUploadingCalled
            && (this.status_tech_upload === ResourceStatusTechUpload.Uploading
                || this.status_tech_upload === ResourceStatusTechUpload.UploadInit);
    }

    public get isUploadInit() {
        return this.isUploadingCalled && this.status_tech_upload === ResourceStatusTechUpload.UploadInit;
    }

    public get isUploading() {
        return this.isUploadingCalled
            && this.status_tech_upload === ResourceStatusTechUpload.Uploading;
        // this.upload_status == UploadStatus.Uploading;
    }
    public get isUploaded() {
        return this.status_tech_upload === ResourceStatusTechUpload.Uploaded;
    }
    public get isUploadError() {
        return this.status_tech_upload === ResourceStatusTechUpload.UploadError;
    }

    public get isSuccess() {
        return this.isProcessed && this.isUploaded;
    }

    public get isProcess() {
        return this.isProcessing || this.isProcessed || this.isProcessError;
    }

    public get isUpload() {
        return this.isUploadInit || this.isUploading || this.isUploaded || this.isUploadError || this.isUploadingCanceled;
    }

    public get isError() {
        return this.isUploadError
            || this.isProcessError;
    }

    public get status_tech_upload_name() {
        if (this.isUploadingCanceled) {
            return 'Upload Canceled';
        }
        return ResourceStatusTechUpload[this.dtm.status_tech_upload]
            ? ResourceStatusTechUpload[this.dtm.status_tech_upload]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }
    public get status_tech_process_name() {
        return ResourceStatusTechProcess[this.dtm.status_tech_process]
            ? ResourceStatusTechProcess[this.dtm.status_tech_process]
                .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get status_tech_name() {
        if (this.dtm.status_tech_process === ResourceStatusTechProcess.Undefined) {
            return this.status_tech_upload_name;
        }
        return this.status_tech_process_name;
    }

    // public get progress() {
    //     if (this.blobProgresses.length < 1) return 0;
    //     return Math.floor(Enumerable.From(this.blobProgresses).Sum(x => x.progress) / this.blobProgresses.length);
    // }

    public get progressMode(): 'indeterminate' | 'determinate' | 'buffer' | 'query' {
        // if (this.isProcessing) {
        //     return 'indeterminate';
        // }
        return 'determinate';
    }

    // public get progressLabel() {
    //     if (this.isProcessing) {
    //         return `${this.process_progress_current}/${this.process_progress_total}`;
    //     }
    //     return `${this.progress}% (${this.upload_progress_current}/${this.upload_progress_total})`;
    // }

    // public get progressColor() {
    //     // 'primary' | 'accent' | 'warn';
    //     if (this.isProcessed) {
    //         return "accent";
    //     } else if (this.isError) {
    //         return "warn";
    //     }

    //     return "primary";
    // }

    public get isPortrait() {
        return this.fileWidth < this.fileHeight;
    }

    public get isLandscape() {
        return this.fileWidth > this.fileHeight;
    }

    public get isSquare() {
        return this.fileWidth === this.fileHeight;
    }

    public get thumbheight() {
        if (this.fileWidth > this.fileHeight) {
            return 300;
        }
        return 300;
    }
    public get thumbwidth() {
        if (this.fileWidth <= this.fileHeight) {
            return 0;
        }
        return 0; // 400;
    }

    public get contheight() {
        if (this.fileWidth <= this.fileHeight) {
            return 0;
        }
        return 0; // 200;
    }
    public get contwidth() {
        if (this.fileWidth > this.fileHeight) {
            return 0;
        }
        return 0; // 200;
    }

    public files: FileMy[] = [];
    public jobs: MyJob[] = [];

    public originalFile: FileMy;
    public hasThumbanils: boolean;
    public thumbUrl: string;

    public mimeType: string;
    public fileWidth: number;
    public fileHeight: number;


    public _uploadItem: UploadItem = null;

    public isUploadingCalled = false;

    public uploadRelation: KeyValue<ObjectType, number>; // for matching uploads

    public static updateList(list: Resource[], dtms: IResource[]) {

        const ids = dtms ? Enumerable.AsEnumerable(dtms).Select((x) => x.id).ToArray() : [];
        const removes = Enumerable.AsEnumerable(list).Where((x) => ids.indexOf(x.id) === -1).ToArray();
        removes.forEach((remove) => {
            list.splice(list.indexOf(remove), 1);
        });
        if (dtms) {
            dtms.forEach((value) => {
                const update = Enumerable.AsEnumerable(list).FirstOrDefault((x) => x.id === value.id);
                if (update) {
                    update.update(value);
                } else {
                    list.push(new Resource(value));
                }
            });
        }
    }
    public update(dtm: IResource, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        if (this.uploadItem) {
            this.uploadItem.parentID = this.dtm.id;
        }

        this.updateProcessData(dtm);


    }

    public cleanForSave(dtm: IResource, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        // these properties are updated by external services or with batch request, don't overwrite them
        delete dtm.files;
        delete dtm.process_progress_current;
        delete dtm.process_progress_total;
        delete dtm.upload_progress_current;
        delete dtm.upload_progress_total;
        delete dtm.status_tech_process;
        delete dtm.status_tech_upload;

        this.cleanDTMs(ResourceResourceTypeRelation, dtm.resourceResourceTypes, saver);
    }

    public updateProcessData(dtm: IResource) {

        this.status_tech_process = dtm.status_tech_process;

        this.process_progress_current = dtm.process_progress_current;
        this.process_progress_total = dtm.process_progress_total;

        if (!dtm.files) {
            dtm.files = [];
        }

        this.files = Enumerable.AsEnumerable(dtm.files).Select((x) => new FileMy(x)).ToArray();
        this.originalFile = Enumerable.AsEnumerable(this.files).FirstOrDefault((x) => x.type === FileType.Original);
        const thumbnail = Enumerable.AsEnumerable(this.files).FirstOrDefault((x) => x.type === FileType.Thumbnail);
        this.hasThumbanils = thumbnail ? true : false;
        if (thumbnail) {
            this.thumbUrl = thumbnail.url;
        }
        if (this.originalFile) {
            this.mimeType = this.originalFile.mime_type;
            this.fileWidth = this.originalFile.width;
            this.fileHeight = this.originalFile.height;
        }
        // super.update(dtm);
    }

    public updateJobs(jobs: MyJob[]) {
        this.jobs.length = 0;

        jobs.forEach((job) => {
            this.jobs.push(job);
        });

    }
    public setUploadItem(uploadItem: UploadItem) {
        uploadItem.parentID = this.id;
        this._uploadItem = uploadItem;
    }
}
