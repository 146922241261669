import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { CollectionItem, ICollectionItem } from './collection.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class CollectionDataService extends BaseListableDataService<CollectionItem, ICollectionItem, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'collection', ObjectType.COLLECTION, [], []);
  }

}
