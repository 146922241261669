import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '../data/user/user-data.service';
import { User } from '../data/user/user.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserResolver  {
    constructor(private dataService: UserDataService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | User {
        if (this.dataService.sessionUser) {
            return this.dataService.sessionUser;
        } else {
            return this.dataService.sessionGet();
        }
    }
}
