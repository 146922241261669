import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { SpotAppFilterRule, ISpotAppFilterRule } from './spot-app-filter-rule.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class SpotAppFilterRuleDataService extends BaseListableDataService<SpotAppFilterRule, ISpotAppFilterRule, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'spot-filter-rule', ObjectType.SPOT_APP_FILTER_RULE, [], []);
  }

}
