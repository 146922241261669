import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { ITag, Tag } from '../tag/tag.model';
import { DeviceAuditoriumType, ObjectType } from '../enums/enums';
import { PropertyUpdateMode } from '../base.model';

export interface IDeviceTagRelation extends IBaseRelationModel {
    device_id: number;
    tag_id: number;
    tag: ITag;
}

export class DeviceTagRelation extends BaseRelationModel <IDeviceTagRelation> {
    constructor(dtm: IDeviceTagRelation = null) {
        super(dtm || {
            device_id: 0,
            tag_id: 0,
            tag: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get deviceID() {
        return this.dtm.device_id;
    }
    public set deviceID(value) {
        this.dtm.device_id = value;
    }

    public get tagID() {
        return this.dtm.tag_id;
    }
    public set tagID(value) {
        this.dtm.tag_id = value;
    }

    public get tagName() {
        if (this.dtm.tag) {
            return this.dtm.tag.name;
        }
        return null;
    }


    public get tag() { return this.getModelProperty(() => this.dtm.tag, (x) => new Tag(x), PropertyUpdateMode.Update); }
    public set tag(value) { this.setModelProperty(() => this.dtm.tag, (x) => this.dtm.tag = x, value, PropertyUpdateMode.Update); }

    public update(dtm: IDeviceTagRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.tag, (x) => new Tag(x), (x) => this.tag = x, forceReplaceDtm);
    }

    public cleanForSave(dtm: IDeviceTagRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.tag;

    }

    public static getInstance(deviceID: number, tagID: number){
        const empty = new DeviceTagRelation();
        empty.deviceID = deviceID;
        empty.tagID = tagID;
        return empty;
    }
}
