import { Injectable } from '@angular/core';
import { HubConnection, LogLevel, HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { ClientDataService } from '../data/client/client-data.service';

export enum HubAppType { Unknown = 0, ClientPortal = 1, FrontWebApp = 2 }
export interface IHubConnectionInfo {
    ConnectionId: string;
    Hash: string;
    Type: HubAppType;
}

@Injectable()
export class HubService {
    constructor(private cliendDataService: ClientDataService) {

        if (environment.production) {
            this.heartbeatIntervalTimout = 30 * 60 * 1000; // 30m
        }

    }

    private messageHub: HubConnection;
    private restartTimout = 60 * 1000; // 10s
    private heartbeatIntervalTimout = 60 * 1000; // 60s
    private heartbeatTimeout = 2 * 1000; // 2s

    private inited = false;

    private heartbeatInterval: number;
    // private heartbeatOK = false;
    // private startHeartbeat(hash: string) {
    //     clearInterval(this.heartbeatInterval);
    //     this.heartbeatInterval = window.setInterval(() => {
    //         this.heartbeatOK = false;
    //         this.messageHub.invoke('Heartbeat').then((response: boolean) => {
    //             if (response) {
    //                 this.heartbeatOK = true;
    //             }
    //         });
    //         // wait to check if heartbeat is OK
    //         window.setTimeout(() => {
    //             if (!this.heartbeatOK) {
    //                 this.restart(hash);
    //             }
    //         }, this.heartbeatTimeout);
    //     }, this.heartbeatIntervalTimout);
    // }

    private restartTimer: number;
    public init() {

        if (!this.inited && this.cliendDataService.sessionClient && this.cliendDataService.sessionClient.hash) {
            this.inited = true;
            // can't restart the connection if non in 'initial' state, se reset also the connection
            this.start(this.cliendDataService.sessionClient.hash);
        }
    }

    private start(hash: string) {

        this.messageHub = new HubConnectionBuilder()
            .withUrl(`${environment.MESSAGE_HUB_URL}&hash=${hash}`, {
                transport: HttpTransportType.WebSockets,
                logger: LogLevel.Debug
            })
            .build();

        this.messageHub.onclose((error: any) => {
            this.restart(hash);
        });

        // this is used for getting connection info back to client
        // this.messageHub.on('Ping', (connectionId: string) => {
        //     this.messageHub.send('Pong', connectionId);
        // });

        this.messageHub.on('Pong', (connectionInfo: IHubConnectionInfo) => {
            // TODO: save distinct and active connection infos to an array
        });

        this.messageHub.on('Heartbeat', (connectionInfo: IHubConnectionInfo) => {
            // TODO: save distinct and active connection infos to an array
        });

        this.messageHub.start().then(() => {
            // if heartbeat doesn't have have a valid response in n secounds
            // then the connection will be restarted
            // maybe Heartbeat is overkill for Client App this.startHeartbeat(hash);

            // TODO: remove, just for testing
            window.setTimeout(() => {
                this.messageHub.send('Ping');
            }, 1000);

        }, (reason: any) => {
            throw reason;
        }).catch((reason: any) => {
            this.restart(hash);
        });
    }
    private restart(hash: string) {
        clearInterval(this.heartbeatInterval);
        clearTimeout(this.restartTimer);
        delete this.messageHub;

        this.restartTimer = window.setTimeout(() => {
            this.start(hash);
        }, this.restartTimout);
    }

}
