import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, AbstractControlOptions, UntypedFormArray, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { getPropertyName } from './getPropertyName';
import '@angular/compiler';
import { IBaseModel } from 'app/core/data/base.model';
/*
Why `import '@angular/compiler'`?
Had this Error when `ng serve` specifically Angular 9.1.2.
The app would show blank page with the below error in dev tools console:
```
main.ts:15 Error: Angular JIT compilation failed: '@angular/compiler' not loaded!
  - JIT compilation is discouraged for production use-cases! Consider AOT mode instead.
  - Did you bootstrap using '@angular/platform-browser-dynamic' or '@angular/platform-server'?
  - Alternatively provide the compiler with 'import "@angular/compiler";' before bootstrapping.
    at getCompilerFacade (core.js:643)
    at Function.get (core.js:16349)
    at getFactoryDef (core.js:2200)
    at providerToFactory (core.js:17183)
    at providerToRecord (core.js:17165)
    at R3Injector.processProvider (core.js:16981)
    at core.js:16960
    at core.js:1400
    at Array.forEach (<anonymous>)
    at deepForEach (core.js:1400)
```
StackOverflow solution - import '@angular/compiler';
[solution](https://stackoverflow.com/questions/60183056/ionic-5-with-angular-9-angular-jit-compilation-failed-angular-compiler-not#answer-60183174)
*/

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};


// tslint:disable-next-line:no-empty-interface
export interface IFormTypeValue {

}

// tslint:disable-next-line:no-empty-interface
export interface IFormTypeControls {
  // [key: string]: AbstractControl;
}

export interface FormTypeArray<Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
  Tcont extends IFormTypeControls,
  Tvalue extends IFormTypeValue,
  Tmodel extends IBaseModel>
  extends UntypedFormArray {
  controls: Tgroup[];
  value: Tvalue[];
  removeItem: (item: Tgroup) => void;
}

export interface IGenericFormGroup<Tmodel extends IBaseModel> extends FormTypeGroup<IFormTypeControls, IFormTypeValue, Tmodel> {

}

export interface AbstractControlTypeSafe<T> extends AbstractControl {
  // common properties to FormGroup, FormControl and FormArray
  readonly value: T;
  readonly valueChanges: Observable<T>;

  /*
    Angular `get` signature:
      get(path: Array<string | number> | string): AbstractControl | null;

      split into two get methods
  */
  // tslint:disable-next-line: array-type
  get(path: Array<string> | string): AbstractControl | null;
  get(path: number[]): AbstractControlTypeSafe<T extends (infer R)[] ? R : T> | null;
  readonly statusChanges: Observable<ControlStatus>;
}



export type ControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';


// the idea is to use Angular's FormGroup exactly as is but just sprinkle a bit of type-safety in-between
export interface FormTypeGroup<Tcont extends IFormTypeControls, Tvalue extends IFormTypeValue, Tmodel extends IBaseModel> extends UntypedFormGroup {
  // readonly controls: Tcont;

  // make sure controls return AbstractControl, needed for extending FormGroup
  readonly controls: { [P in keyof Tcont]: Tcont[P] extends AbstractControl ? Tcont[P] : AbstractControl };

  readonly value: Tvalue;
  readonly valueChanges: Observable<Tvalue>;
  model: Tmodel;
  // parentModel: IBaseModel;
  readonly status: ControlStatus;
  readonly statusChanges: Observable<ControlStatus>;
  /* ----- new functions added not part of FormGroup  ----- */
  getSafe<P>(propertyFunction: (typeVal: Tvalue) => P): Tcont | null;

  // setControlSafe(propertyFunction: (typeVal: Tvalue) => any, control: AbstractControl): void;
  setControlSafe<K extends keyof Tcont>(key: K, control: Tcont[K]): void;

  // removeControlSafe(propertyFunction: (typeVal: Tvalue) => any): void;
  /* -------------------------------- */

  setValue(value: Tvalue, options?: { onlySelf?: boolean; emitEvent?: boolean }): void;

  // tslint:disable-next-line:ban-types
  patchValue(value: RecursivePartial<Tvalue>, options?: Object): void;

}


// export const generateSetControlSafeFunction = <Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
//   Tcont extends IFormTypeControls = {},
//   Tvalue extends IFormTypeValue = {},
//   Tmodel extends IBaseModel = {}>(gr: Tgroup) => {
//   return (propertyFunction: (typeVal: Tvalue) => any, control: AbstractControl): void => {
//     const getStr = getPropertyName(propertyFunction.toString());
//     gr.setControl(getStr, control);
//   };
// };

export const generateSetControlSafeFunction = <Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
  K extends keyof Tcont,
  Tcont extends IFormTypeControls = {},
  Tvalue extends IFormTypeValue = {},
  Tmodel extends IBaseModel = {}>(gr: Tgroup) => {
  return (key: K, control: Tcont[K]): void => {
    gr.setControl(key as string, control as any as AbstractControl) ;
  };
};


export const generateRemoveControlSafeFunction = <Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
  Tcont extends IFormTypeControls = {},
  Tvalue extends IFormTypeValue = {},
  Tmodel extends IBaseModel = {}>(gr: Tgroup) => {
  return (propertyFunction: (typeVal: Tvalue) => any): void => {
    const getStr = getPropertyName(propertyFunction.toString());
    gr.removeControl(getStr);
  };
};




// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class FormTypeBuilder extends UntypedFormBuilder {
  // override group to be type safe
  public group<
    Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
    Tcont extends IFormTypeControls = {},
    Tvalue extends IFormTypeValue = {},
    Tmodel extends IBaseModel = {}>(
      controlsConfig: Tcont,
      model?: Tmodel | null,
      // parentModel?: IBaseModel | null,
      options?: AbstractControlOptions | null): Tgroup {
    /* NOTE the return FormGroupTypeSafe<T> */

    // instantiate group from angular type
    const gr = super.group(controlsConfig, options) as Tgroup;

    if (gr) {
      gr.model = model;
      // gr.parentModel = parentModel;
      // implement getSafe method
      // gr.getSafe = generateGetSafeFunction(gr);
      // implement setControlSafe
      gr.setControlSafe = generateSetControlSafeFunction(gr);

      // gr.setControlSafe2 = generateSetControlSafeFunction2(gr);
      // implement removeControlSafe
      // gr.removeControlSafe = generateRemoveControlSafeFunction(gr);
    }

    return gr;
  }

  public array<
    Tarray extends FormTypeArray<Tgroup, Tcont, Tvalue, Tmodel>,
    Tgroup extends FormTypeGroup<Tcont, Tvalue, Tmodel>,
    Tcont extends IFormTypeControls = IFormTypeControls,
    Tvalue extends IFormTypeValue = IFormTypeValue,
    Tmodel extends IBaseModel = IBaseModel>(
      controlsConfig: Tgroup[],
      validator?: ValidatorFn | null,
      asyncValidator?: AsyncValidatorFn | null): Tarray {

    const array = super.array(controlsConfig, validator, asyncValidator) as Tarray;
    array.removeItem = (item) => {
      const index = array.controls.indexOf(item);
      if (index >= 0) {
        array.removeAt(index);
      }
    };
    return array;
  }
}
