import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { IDeviceBase, DeviceBase } from '../device/device.base.model';
import { ObjectType } from '../enums/enums';

export interface IExtContentSourceDeviceRelation extends IBaseRelationModel {
    ext_content_source_id: number;
    device_id: number;
    channel_number: number;
    device: IDeviceBase;
    service_reference: string;
}

export class ExtContentSourceDeviceRelation extends BaseRelationModel <IExtContentSourceDeviceRelation> {
    constructor(dtm: IExtContentSourceDeviceRelation = null) {
        super(dtm || {
            // id: null,
            ext_content_source_id: 0,
            device_id: 0,
            device: null,
            channel_number: null,
            service_reference: null,
            created_at: null,
            created_by: null,
        });
        this.update(this.dtm, true, true);
    }

    public get serviceReference() {
        return this.dtm.service_reference;
    }
    public set serviceReference(value) {
        this.dtm.service_reference = value;
    }

    public get channelNumber() {
        return this.dtm.channel_number;
    }
    public set channelNumber(value) {
        this.dtm.channel_number = value;
    }

    public get ext_content_source_id() {
        return this.dtm.ext_content_source_id;
    }
    public set ext_content_source_id(value) {
        this.dtm.ext_content_source_id = value;
    }

    public get device_id() {
        return this.dtm.device_id;
    }
    public set device_id(value) {
        this.dtm.device_id = value;
    }

    // public device: DeviceBase<IDeviceBase>;

    public update(dtm: IExtContentSourceDeviceRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

    }

    public cleanForSave(dtm: IExtContentSourceDeviceRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.device;
    }

    public static getInstance(ext_content_source_id: number, device_id: number){
        const empty = new ExtContentSourceDeviceRelation();
        empty.ext_content_source_id = ext_content_source_id;
        empty.device_id = device_id;
        return empty;
    }
}
