import * as Enumerable from 'linq-es2015';

export interface ISortMy {
    key: string;
    direction: 'asc' | 'desc' | '';
}

export class SortMy {
    constructor(dtm: ISortMy = null) {
        this.dtm = dtm || {
            key: '',
            direction: ''
        };
        this.update(this.dtm, true, true);
    }

    public get key() {
        return this.dtm.key;
    }
    public set key(value) {
        this.dtm.key = value;
    }

    public get direction() {
        return this.dtm.direction;
    }
    public set direction(value) {
        this.dtm.direction = value;
    }

    public dtm: ISortMy;

    public static getQueryString(prefix: string, items: SortMy[]) {

        let sort = '';
        if (items) {
            items.forEach((item, i) => {
                if (item.direction && item.direction.length) {
                    sort = `${sort}${i > 0 ? '&' : ''}$orderby[]=${item.key} ${item.direction}`;
                }
            });
        }

        if (sort.length) {
            sort = `${prefix}${sort}`;
        }

        return sort;
    }

    public static removeSorts(sorts: SortMy[], key: string) {
        const toRemove = Enumerable.AsEnumerable(sorts).Where((x) => x.key === key).ToArray();

        toRemove.forEach((sort) => {
            sorts.splice(sorts.indexOf(sort), 1);
        });
    }

    public static create(key: string, direction: string) {
        return new SortMy({ key, direction: direction as 'asc' | 'desc' | '' });
    }

    public update(dtm: ISortMy, forceReplaceDtm = false, fromConstructor = false) {
        if (this.dtm && !forceReplaceDtm) {
            Object.assign(this.dtm, dtm);
        } else {
            this.dtm = dtm;
        }
        // super.update(dtm);

    }
}
