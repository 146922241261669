<div class="sidebar" *ngIf="isLoggedIn">
  <mat-toolbar color="primary">
    <a href="/" class="logo">
      <ng-content select="[logo]"></ng-content>
    </a>
    <mat-nav-list class="main-menu">
      <ng-content select="[mainMenu]"></ng-content>
    </mat-nav-list>
    <mat-nav-list class="bottom-menu">
      <ng-content select="[bottomMenu]"></ng-content>
    </mat-nav-list>
  </mat-toolbar>
</div>

<div class="main-wrapper">
  <mat-progress-bar mode="indeterminate" *ngIf="!isRouterOutletOpened" style="position: fixed; z-index: 30;">
  </mat-progress-bar>
  <div class="main-outlet">
    <ng-content></ng-content>
  </div>
</div>