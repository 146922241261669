import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { ILookup } from '../lookup.model';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { ExtContentSourceDeviceRelation, IExtContentSourceDeviceRelation } from './ext-content-source-device-relation';

export interface IExtContentSource extends IBaseNameModel {
    extContentSourceDevices: IExtContentSourceDeviceRelation[];
    service_reference: string;
}

export class ExtContentSource extends BaseNameModel<IExtContentSource> {
    constructor(dtm: IExtContentSource = null) {
        super(dtm || {
            id: 0,
            name: '',
            client_id: 0,
            extContentSourceDevices: [],
            status: Status.Active,
            status_system: StatusSystem.Default,
            service_reference: null,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get serviceReference() {
        return this.dtm.service_reference;
    }
    public set serviceReference(value) {
        this.dtm.service_reference = value;
    }

    private _deviceLookups: ILookup[];
    public get deviceLookups() {
        if (!this._deviceLookups && this.dtm.extContentSourceDevices) {
            this._deviceLookups = Enumerable.AsEnumerable(this.dtm.extContentSourceDevices).Select((x) => {
                return {
                    id: x.device_id,
                    name: x.device.name
                };
            }).ToArray();
        }
        return /*is not lookup ?  lookup*/ this._deviceLookups;
    }
    public set deviceLookups(values) {
        if (values) {
            // if select input is not 'multiple' then will not be passed as array
            if (!Array.isArray(values)) {
                values = [values];
            }

            if (values.length && values[0] as any !== 'none') {

                if (this.dtm.extContentSourceDevices.length) {
                    const deviceIds = Enumerable.AsEnumerable(values).Select((x) => x.id).ToArray();
                    // clear  relations

                    const toClear = Enumerable.AsEnumerable(this.dtm.extContentSourceDevices)
                        .Where((x) => deviceIds.indexOf(x.device_id) === -1).ToArray();

                    toClear.forEach((x) => {
                        this.dtm.extContentSourceDevices.splice(this.dtm.extContentSourceDevices.indexOf(x), 1);
                    });
                }

                values.forEach((value) => {
                    if (value as any !== 'none') {
                        const match = Enumerable.AsEnumerable(this.dtm.extContentSourceDevices)
                            .Where((rel) => rel.ext_content_source_id === this.id && rel.device_id === value.id).FirstOrDefault();

                        if (!match) {
                            const empty = new ExtContentSourceDeviceRelation();
                            empty.device_id = value.id;
                            empty.ext_content_source_id = this.id;
                            this.dtm.extContentSourceDevices.push(empty.dtm);
                        }
                    }
                });

            } else {
                this.dtm.extContentSourceDevices.length = 0;
            }

        }

        this._deviceLookups = values;
    }

    private _tunerNames: string = null;
    public get tunerNames() {
        if (!this._tunerNames && this.deviceLookups) {
            this._tunerNames = Enumerable.AsEnumerable(this.deviceLookups).Select((x) => x.name).ToArray().join(', ');
        }
        return /*fix*/ this._tunerNames;
    }

    public update(dtm: IExtContentSource, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this._deviceLookups = null;
        this._tunerNames = null;


    }

    public cleanForSave(dtm: IExtContentSource, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTMs(ExtContentSourceDeviceRelation, dtm.extContentSourceDevices, saver);
    }
}
