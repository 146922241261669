import { NumberSymbol } from "@angular/common";
import { BaseModel, IBaseModel } from "../base.model";
import { DeviceChannelPinpointItemData, IDeviceChannelPinpointGenericData } from "./device-channel-pinpoint-item-data.model";


export interface IDeviceChannelPinpointItem extends IBaseModel {
    i: number;
    a: number;
    n: string;
    si: number;
    pt: number;
    pts: boolean;
    ts: IDeviceChannelPinpointGenericData[];
    mv: IDeviceChannelPinpointGenericData;
    me: IDeviceChannelPinpointGenericData;
    ca: IDeviceChannelPinpointGenericData[];
    ev: IDeviceChannelPinpointGenericData[];
    pp: IDeviceChannelPinpointGenericData[];
}


export class DeviceChannelPinpointItem extends BaseModel<IDeviceChannelPinpointItem> {
    constructor(dtm: IDeviceChannelPinpointItem) {
        super(dtm);
        this.update(this.dtm, true, true);
    }


    public get id() {
        return this.dtm.i;
    }
    public get name() {
        return this.dtm.n;
    }

    public mediaVersion: DeviceChannelPinpointItemData;
    public media: DeviceChannelPinpointItemData;
    public campaign: DeviceChannelPinpointItemData;
    public event: DeviceChannelPinpointItemData;

    public update(dtm: IDeviceChannelPinpointItem, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        if (dtm.mv?.i) {
            this.mediaVersion = new DeviceChannelPinpointItemData(dtm.mv);
        }

        if (dtm.me?.i) {
            this.media = new DeviceChannelPinpointItemData(dtm.me);
        }

        if (dtm.ca?.length && dtm.ca[0].i) {
            this.campaign = new DeviceChannelPinpointItemData(dtm.ca[0]);
        }

        if (dtm.ev?.length && dtm.ev[0].i) {
            this.event = new DeviceChannelPinpointItemData(dtm.ev[0]);
        }
    }

}
