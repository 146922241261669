import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { SpotAppType } from '../enums/enums';

// fake service for lookup
@Injectable()
export class SpotAppTypeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: SpotAppType.Media, name: 'Media' });
        lookups.push({ id: SpotAppType.Schedule, name: 'Schedule' });
        lookups.push({ id: SpotAppType.Product, name: 'Product' });
        lookups.push({ id: SpotAppType.Event, name: 'Event' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
