import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData } from 'app/core/data/base-data.service';
import { ClientDataService } from 'app/core/data/client/client-data.service';
import { FilterMy } from 'app/core/data/filter/filter.model';
import * as Enumerable from 'linq-es2015';
import { Observable } from 'rxjs';

import { ToasterService } from '../../services/toaster.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { IDevice } from '../device/device.model';
import { LocationMy, ILocationMy } from '../location/location.model';
import { ILookup } from '../lookup.model';
import { FloorMap, IFloorMap } from './floor-map.model';
import { FloorMapPoint, IFloorMapPoint, FloorMapPointPosition } from './floor-map-point.model';
import { SortMy } from '../sort/sort.model';
import { ObjectType } from '../enums/enums';
import { LocationDataService } from '../location/location-data.service';

@Injectable()
export class FloorMapDataService extends BaseListableDataService<FloorMap, IFloorMap, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService, private cliendDataService: ClientDataService) {
    super(http, toastr, 'floor-map', ObjectType.FLOOR_MAP, [], []);
  }

  protected getDatas(
    pageNr: number, pageSize: number,
    filters: FilterMy[], sorts: SortMy[], includes: string[],
    search = '', query = '', subResourceName = ''): Observable<IListData<IFloorMap>> {

    const maps = this.getFloorMaps();

    const listData: IListData<IFloorMap> = {
      totalCount: maps.length,
      data: maps,
      filter: []
    };

    return new Observable<IListData<IFloorMap>>((observer) => {
      observer.next(listData);
      observer.complete();
    });
  }

  protected getData(id: number, includes: string[]): Observable<IFloorMap> {
    const include = this.getIncludeQueryString('?', includes);

    const maps = this.getFloorMaps();
    const map = Enumerable.AsEnumerable(maps).FirstOrDefault((x) => x.id === id);
    return new Observable<IFloorMap>((observer) => {
      observer.next(map);
      observer.complete();
    });
  }

  private getFloorMaps(): IFloorMap[] {
    const maps: IFloorMap[] = [];

    // if olearysee
    if (this.cliendDataService.sessionClient.hash === 'n6owen') {

      // maps.push(this.createFloorMap(1037, 'O\'Learys Kristiine', 1001, '1st FLOOR',
      // 'https://voogtvmedia.blob.core.windows.net/test/voog_map_OLK_1F.png', 3236, 2325));

      // maps.push(this.createFloorMap(1037, 'O\'Learys Kristiine', 1002, '2nd FLOOR',
      // 'https://voogtvmedia.blob.core.windows.net/test/voog_map_OLK_2F.png', 4722, 4162));

    } else if (this.cliendDataService.sessionClient.hash === 'nvjlon') { // if apollokino

      maps.push(this.createFloorMap(1011, 'Kuressaare', 1003, 'Apollo Kino Kuressaare (Aurigi keskuse 2. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/xplyu6rrzj/original/main.png', 2448, 7524));


      maps.push(this.createFloorMap(1010, 'Lõunakeskus', 1004, 'Apollo Kino Lõunakeskus (2. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/3043i7ee2j/original/main.png', 1888, 4348));

      maps.push(this.createFloorMap(1010, 'Lõunakeskus', 1005, 'Apollo Kino Lõunakeskus (3. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/grl0hrmm17/original/main.png', 1462, 3714));

      maps.push(this.createFloorMap(1007, 'Solaris', 1006, 'Apollo Kino Solaris (2. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/odvof100v6/original/main.png', 2856, 4242));

      maps.push(this.createFloorMap(1007, 'Solaris', 1007, 'Apollo Kino Solaris (3. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/po72soee6r/original/main.png', 3076, 3066));

      maps.push(this.createFloorMap(1007, 'Ülemiste', 1008, 'Apollo Kino Ülemiste (2. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/13y2ijgg60/original/main.png', 2828, 2790));

      maps.push(this.createFloorMap(1007, 'Ülemiste', 1009, 'Apollo Kino Ülemiste (3. korrus)',
        'https://voogtvmedia.blob.core.windows.net/nvjlon/resource/background/rdp3fx88yk/original/main.png', 2762, 2796));

    }

    return maps;
  }

  private createFloorMap(
    locationID: number, locationName: string,
    floorMapID: number, floorMapName: string, floorMapImg: string,
    floorMapImgWidth: number, floorMapImgHeight: number) {

    const location = new LocationMy();
    location.id = locationID;
    location.name = locationName;

    const map = new FloorMap();
    map.id = floorMapID;
    map.name = floorMapName;
    map.dtm.location_id = location.id;
    map.dtm.location = location.dtm;
    map.dtm.map_image_url = floorMapImg;
    map.dtm.map_image_width = floorMapImgWidth;
    map.dtm.map_image_height = floorMapImgHeight;

    return map.dtm;

  }

  public setMapPoints(map: FloorMap) {
    const points: IFloorMapPoint[] = [];
    const cords = this.getCordsMapping(map.id);

    cords.forEach((cord) => {
      const point = new FloorMapPoint();
      point.dtm.device_id = cord[0];
      point.dtm.name = cord[1];
      point.dtm.lat = cord[2];
      point.dtm.lng = cord[3];
      point.dtm.pos = cord[4] ? cord[4] : FloorMapPointPosition.Default;
      points.push(point.dtm);
    });

    map.dtm.points = points;
  }

  private getCordsMapping(mapId: number) {
    const points = [];
    if (mapId === 1001) {

      points.push([1260, '1', 492.35748760699164, 40.33630118209492]);

    } else if (mapId === 1002) {
      // points.push([1260, '1', 492.35748760699164, 40.33630118209492]);
    } else if (mapId === 1003) { // Apollo Kino Kuressaare (Aurigi keskuse 2. korrus)

      points.push([2249, '1', 2330, 1587.5]);
      points.push([2250, '2', 2133.5, 1589.5]);
      points.push([2251, '3', 1938.25, 1586.75]);
      points.push([2252, '4', 1740, 1585.25]);
      points.push([2253, '5', 1550, 1588.25]);
      points.push([2254, '6', 1352, 1586]);
      points.push([2255, '7', 1549, 1446]);
      points.push([2256, '8', 1350.25, 1445.25]);
      points.push([2257, '9', 1184.5, 876.5]);
      points.push([2258, '10', 1188.75, 1140.5]);
      points.push([2259, '11', 2514.5, 305.25]);
      points.push([2260, '12', 4474.25, 304]);
      points.push([2261, '13', 6547, 303.5]);
      points.push([2262, '14', 3432.75, 685.75]);
      points.push([2263, '15', 6876, 684.5]);
      points.push([0, '16', 2706.75, 1092]);
      points.push([0, '17', 286.25, 2113]);
      points.push([0, '18', 1575.75, 1919.75]);

    } else if (mapId === 1004) { // Apollo Kino Lõunakeskus (2. korrus)

      points.push([1194, '1 ', 2926.25, 790.2202891637984]);
      points.push([1195, '2 ', 2875.25, 789.720363627047]);
      points.push([1196, '3 ', 2814.75, 883.9563273046812]);
      points.push([1197, '4 ', 2762.75, 884.4562528414325]);
      points.push([1199, '5 ', 1440.75, 719.9499979285479]);
      points.push([1198, '6 ', 1388.25, 720.1999606969237]);
      points.push([1201, '7 ', 949.5, 757.945827986625]);
      points.push([1200, '8 ', 897, 757.945827986625]);
      points.push([1202, '9 ', 805.5, 757.9517850465149]);
      points.push([1203, '10', 753.75, 757.7018222781393]);
      points.push([1204, '11', 1060.25, 848.199886233675]);
      points.push([1205, '12', 1007.5, 848.199886233675]);
      points.push([1211, '13', 1696.5, 1090.8781781252487]);
      points.push([1212, '14', 1757, 1090.8781781252487]);
      points.push([1213, '15', 1818.5, 1090.3782525884972]);
      points.push([1214, '16', 1877.5, 1091.378103662]);
      points.push([1215, '17', 1937.5, 1091.4390890626244]);
      points.push([1216, '18', 1997.75, 1090.6892007574972]);
      points.push([1932, '19', 2057.75, 1090.6892007574972]);
      points.push([1933, '20', 2147.25, 1090.189275220746]);
      points.push([1934, '21', 2208, 1090.189275220746]);
      points.push([1935, '22', 2270, 1090.4392379891215]);
      points.push([1936, '23', 2328, 1089.9393124523701]);
      points.push([1937, '24', 2388, 1090.6892007574972]);
      points.push([1938, '25', 2448.25, 1090.4392379891215]);
      points.push([1939, '26', 2507.5, 1089.9393124523701]);
      points.push([1912, '27', 2967.25, 1138.6854403634416]);
      points.push([1913, '28', 3067.75, 1138.435477595066]);
      points.push([1914, '29', 3161.25, 1137.9355520583144]);
      points.push([1911, '30', 3635, 1255.4328341497398]);

      points.push([1926, '31', 262.5, 426.7155413241169]);
      points.push([1929, '32', 210, 426.2156438796999]);
      points.push([1927, '33', 262.5, 548.940466484077]);
      points.push([1930, '34', 210.25, 548.6905177618685]);
      points.push([1928, '35', 262.25, 668.1660069775348]);
      points.push([1931, '36', 207.75, 668.1660069775348]);
      points.push([1922, '37', 1209.5, 744.1665197554498]);
      points.push([1921, '38', 1269.5, 976.3860607473032]);
      points.push([1923, '39', 1543.5, 952.1381118589628]);
      points.push([1924, '40', 2643.25, 955.6219593546426]);
      points.push([1925, '41', 2895.75, 1062.4460557633497]);
      points.push([1907, '42', 1451, 1185.9472864303455]);
      points.push([1908, '43', 1817.25, 1332.4434405959835]);
      points.push([1909, '44', 2394, 1330.944517429605]);
      points.push([1910, '45', 2753.75, 1185.9516963204137]);
      points.push([1915, '46', 910.25, 1386.2004143756265]);
      points.push([1916, '47', 963.5, 1386.7003118200437]);
      points.push([1917, '48', 1016, 1386.2004143756265]);
      points.push([1918, '49', 1066.5, 1386.9502605422522]);
      points.push([1919, '50', 1119.75, 1386.450363097835]);
      points.push([1920, '51', 2105.5, 1245.9485683751327]);
      points.push([1177, '52', 1401.25, 1287.2059523771077]);
      points.push([1178, '53', 1393.5, 1339.4452353186857]);
      points.push([1179, '54', 1444, 1318.6994913753797]);
      points.push([1180, '55', 2834, 1277.9400049839535]);
      points.push([1181, '56', 2823.25, 1330.1792879255315]);
      points.push([1182, '57', 2875.5, 1312.682877370936]);
      points.push([1183, '58', 503.25, 1461.9415433176982]);
      points.push([1184, '59', 534.25, 1506.4324158708125]);
      points.push([1185, '60', 478.75, 1510.4315954261488]);
      points.push([1189, '61', 1231.25, 1710.197132596971]);
      points.push([1190, '62', 1231.75, 1776.6834927044338]);
      points.push([1191, '63', 1324.25, 1661.9570292107292]);
      points.push([1186, '64', 4086.75, 1330.4592854335547]);
      points.push([1187, '65', 4231.25, 1805.11190890751]);

    } else if (mapId === 1005) { // Apollo Kino Lõunakeskus (3. korrus)

      points.push([1206, '66', 1322.75, 1066.7082959202996]);
      points.push([1207, '67', 1445.5, 1067.2081019478358]);
      points.push([1208, '68', 2105.5, 1066.2071320855173]);
      points.push([1209, '69', 2228, 1066.4570350992853]);
      points.push([1210, '70', 2537.5, 1149.6914203159556]);
      points.push([1188, '71', 989.75, 1017.9550953746482]);

    } else if (mapId === 1006) { // Apollo Kino Solaris (2. korrus)

      points.push([1050, '1	', 1103.25, 962.2110288784655]);
      points.push([1051, '2	', 1238.25, 962.2110288784655]);
      points.push([1052, '3	', 1369.25, 961.4611827118399]);
      points.push([1053, '4	', 1503.75, 961.9610801562569]);
      points.push([1054, '5	', 1637, 961.7111314340484]);
      points.push([1055, '6	', 1770.25, 962.2110288784654]);
      points.push([1056, '7	', 1902.25, 961.961080156257]);
      points.push([1057, '8	', 2035.25, 961.7111314340484]);
      points.push([1061, '9	', 2501.25, 807.9746687710026]);
      points.push([1062, '10', 2577.75, 715.9935389982719]);
      points.push([1063, '11', 2651.5, 623.9566189883972]);
      points.push([1064, '12', 2728, 531.9526193206607]);
      points.push([1065, '13', 2802, 437.472002325845]);
      points.push([1066, '14', 2877.5, 345.7408212753228]);
      points.push([1067, '15', 2951.5, 253.50974278038365]);
      points.push([1068, '16', 3027.5, 161.528613007653]);
      points.push([1039, '17', 902.75, 1361.9462095967242]);
      points.push([1040, '18', 996, 1361.9462095967242]);
      points.push([1041, '19', 1085.25, 1361.4463121523072]);
      points.push([1042, '20', 1178.25, 1361.9462095967242]);
      points.push([1071, '21', 2268.25, 1789.9424150401537]);
      points.push([1072, '22', 2144.5, 1789.6924663179452]);
      points.push([1069, '23', 389.5, 761.7156438796999]);
      points.push([1070, '24', 536.75, 762.2155413241169]);
      points.push([1060, '25', 119.25, 1258.6788777031995]);

      points.push([0, '26', 1045.25, 2204.6815441483573]);
      points.push([0, '27', 1098.25, 2107.9513886536647]);
      points.push([0, '28', 991.5, 2108.201337375873]);
      points.push([0, '29', 3073.25, 2201.9399537061618]);
      points.push([0, '30', 3121.75, 2105.459746933678]);
      points.push([0, '31', 3019.25, 2106.2095931003037]);

      points.push([1690, '53', 655.5, 1023.7276941607006]);
      points.push([1691, '54', 655, 1170.9474915415115]);
      points.push([1692, '55', 744, 1302.9204168676033]);

    } else if (mapId === 1007) { // Apollo Kino Solaris (3. korrus)

      points.push([1043, '32', 1759.5, 1388.4568240995632]);
      points.push([1044, '33', 1647, 1387.9569266551462]);
      points.push([1045, '34', 1534.75, 1388.4593879891377]);
      points.push([1046, '35', 1423.25, 1388.2094392669292]);
      points.push([1047, '36', 1019, 1387.7047729879034]);
      points.push([1048, '37', 2095.25, 338.75046150012344]);
      points.push([1049, '38', 2095.25, 625.9415433176982]);
      points.push([1073, '39', 2095, 1250.148931472968]);
      points.push([1074, '40', 715.25, 1345.9032900852446]);
      points.push([1075, '41', 667.5, 2152.381189357111]);
      points.push([1076, '42', 667.75, 2294.6020122937516]);
      points.push([1077, '43', 934, 2527.600986737922]);
      points.push([1078, '44', 941, 1984.6301638012815]);
      points.push([1079, '45', 941.5, 1863.9049309745724]);
      points.push([1058, '46', 2094.5, 846.21461832387]);
      points.push([1059, '47', 2095, 1022.178518758659]);
      points.push([0, '48', 1975, 165.91636592207533]);
      points.push([0, '49', 2693.75, 1570.1005252377986]);
      points.push([0, '50', 1225.25, 1388.0867827896784]);
      points.push([0, '51', 806.75, 1436.8324241774017]);
      points.push([0, '52', 807.25, 2610.5491961685143]);

    } else if (mapId === 1008) {

      points.push([2475, '1', 1668.5, 305.9695409918533]);
      points.push([2476, '2', 1668.75, 358.70872137784835]);
      points.push([2477, '3', 991, 437.200363097835]);
      points.push([2478, '4', 1015.25, 486.1903126507024]);
      points.push([2637, '5', 1112.5, 745.4198528118968]);
      points.push([2398, '6', 1718, 1007.7029782652012]);
      points.push([2399, '7', 1717.5, 1064.1913894843237]);
      points.push([2400, '8', 1717.5, 1119.9299545368208]);
      points.push([2401, '9', 1693.25, 1315.7000041532947]);
      points.push([2402, '10', 1693.25, 1372.4383640946257]);
      points.push([2403, '11', 1693.5, 1428.9267753137483]);
      points.push([2404, '12', 1693.5, 1484.1654429218283]);
      points.push([2405, '13', 1693.75, 1539.654059252117]);
      points.push([2406, '14', 1693.75, 1596.6423679156567]);
      points.push([2407, '15', 1693, 1652.1309842459452]);
      points.push([2408, '16', 1693, 1708.3694467428595]);
      points.push([2409, '17', 1694, 1764.6079092397736]);
      points.push([2410, '18', 1693.75, 1819.8465768478536]);
      points.push([2411, '19', 1251.25, 1111.3628831855483]);
      points.push([2412, '20', 1252.25, 1168.1012431268796]);
      points.push([2413, '21', 1251.75, 1224.5896543460021]);
      points.push([2414, '22', 1252.25, 1280.8281168429162]);
      points.push([2415, '23', 1252, 1336.0667844509965]);
      points.push([2416, '24', 1252, 1393.0550931145362]);
      points.push([2417, '25', 1251.5, 1449.0436068892418]);
      points.push([2418, '26', 1251.5, 1504.0323257751133]);
      points.push([2419, '27', 1251.75, 1561.020634438653]);
      points.push([2420, '28', 1251.5, 1616.0093533245245]);
      points.push([2421, '29', 1251.75, 1672.2478158214387]);
      points.push([2422, '30', 1251.75, 1728.2363295961443]);
      points.push([2423, '31', 1252, 1785.224638259684]);
      points.push([2424, '32', 1251.75, 1840.9632033121811]);
      points.push([2425, '33', 1251.75, 1896.4634084233471]);
      points.push([2426, '34', 1267, 984.2564304904095]);
      points.push([2427, '35', 1251.75, 2010.199050641729]);
      points.push([2535, '36', 1641, 2112.7011322647077]);
      points.push([2486, '37', 959.5, 2080.70569598815]);
      points.push([2487, '38', 959, 2132.445081485311]);
      points.push([2488, '39', 1759.5, 2087.2059523771077]);
      points.push([2489, '40', 1759.5, 2139.9451327631027]);

      points.push([2490, '41', 2049.75, 2333.9477479304687]);
      points.push([2491, '42', 2049.75, 2386.9368770386723]);
      points.push([2492, '43', 1848, 2333.69779920826]);
      points.push([2493, '44', 1847.5, 2386.6869283164638]);
      points.push([2494, '45', 818, 2405.44641470789]);
      points.push([2495, '46', 817.75, 2457.9356463716767]);
      points.push([2496, '47', 419.5, 2408.198004319426]);
      points.push([2497, '48', 420, 2459.6874410943788]);
      points.push([2428, '49', 2137.75, 2179.9514912092477]);
      points.push([2439, '50', 1041.75, 2458.209336711346]);
      points.push([2441, '51', 944.75, 2458.4592854335547]);
      points.push([2442, '52', 587.75, 2458.2006194867927]);
      points.push([2436, '53', 177, 2430.2043627655717]);
      points.push([2443, '54', 970.25, 2206.7001067088777]);
      points.push([2444, '55', 563.25, 2286.1890307059152]);
      points.push([2445, '56', 1148, 1462.212515934419]);
      points.push([2446, '57', 930.75, 1776.4427739846942]);
      points.push([2447, '58', 932, 1671.9642081015381]);
      points.push([2448, '59', 1052.25, 1359.7782540631126]);

      points.push([2505, '104', 1533.25, 700.9663017535864]);
      points.push([2506, '105', 1465, 615.2312739019283]);
      points.push([2507, '106', 1406.25, 659.75]);
      points.push([2508, '107', 1472.75, 746]);

      points.push([2509, '108', 1613.25, 1966.4517988759967]);
      points.push([2510, '109', 1614.5, 1910.4632851012911]);
      points.push([2511, '110', 1614.25, 1853.9748738821686]);
      points.push([2512, '111', 1550.25, 1853.4749764377516]);
      points.push([2513, '112', 1549.75, 1910.4632851012911]);
      points.push([2514, '113', 1550.25, 1966.7017475982052]);
      points.push([2515, '114', 1432.25, 1936.2080034887674]);
      points.push([2516, '115', 1432, 1880.2194897140619]);
      points.push([2517, '116', 1431.75, 1823.9810272171476]);
      points.push([2518, '117', 1367.25, 1824.4809246615646]);
      points.push([2519, '118', 1368.5, 1880.2194897140619]);
      points.push([2520, '119', 1367.75, 1936.7079009331844]);

    } else if (mapId === 1009) {

      points.push([2483, '60', 1635.25, 462.20354232090756]);
      points.push([2484, '61', 1635.75, 522.1912356509492]);
      points.push([2485, '62', 1635.75, 582.9287751476165]);
      points.push([2479, '63', 1667.75, 947.9660541020318]);
      points.push([2480, '64', 1668.5, 1018.4515937648308]);
      points.push([2481, '65', 1668.25, 1088.1872872610043]);
      points.push([2482, '66', 1668.25, 1157.9229807571778]);
      points.push([2449, '67', 1340.75, 389.2054395991928]);
      points.push([2450, '68', 1342, 278.2282069386156]);
      points.push([2451, '69', 1396, 173.24974361104253]);
      points.push([2452, '70', 1473.75, 173.49969233325106]);
      points.push([2454, '71', 1714.25, 803.9528244318267]);
      points.push([2455, '72', 1875.5, 864.190466484077]);
      points.push([2456, '73', 1789.25, 863.9405177618685]);

      points.push([2457, '74', 1433.25, 1054.7091316001802]);
      points.push([2458, '75', 1433.75, 969.4766173270793]);
      points.push([2459, '76', 1657.75, 1328.2099007670527]);
      points.push([2460, '77', 1657.25, 1530.918314478152]);
      points.push([2461, '78', 1657, 1774.3683699092383]);
      points.push([2429, '79', 1593.5, 2180.2003118200437]);
      points.push([2430, '80', 1490.75, 2179.950363097835]);
      points.push([2431, '81', 1371.25, 2247.1928252624857]);
      points.push([2432, '82', 951.25, 2308.1979530416347]);
      points.push([2437, '83', 785.5, 2237.9523116539117]);
      points.push([2433, '84', 975.75, 2112.4587213778486]);
      points.push([2434, '85', 1061.5, 2028.975848160207]);
      points.push([2435, '86', 1193.5, 1994.2329757732246]);
      points.push([2462, '87', 1983.5, 2038.9848730515096]);
      points.push([2534, '88', 1519.5, 1845.9955901099315]);
      points.push([2533, '89', 1467, 1845.745641387723]);
      points.push([2532, '90', 1851.75, 1994.2457952210975]);
      points.push([2531, '91', 1799.75, 1994.995641387723]);

      // points.push([2530, '92', 1865.5, 2222.7324629953096]);
      points.push([2530, '92', 1866.5, 2274.221899770262, FloorMapPointPosition.Top]);
      points.push([2529, '93', 1866.5, 2274.221899770262, FloorMapPointPosition.Bottom]);

      // points.push([2527, '94', 1796.75, 2222.482514273101]);
      points.push([2527, '94', 1796.5, 2274.9717459368876, FloorMapPointPosition.Top]);
      points.push([2528, '95', 1796.5, 2274.9717459368876, FloorMapPointPosition.Bottom]);

      points.push([2526, '96', 507.25, 2286.234514106969]);
      points.push([2525, '97', 507.5, 2338.973694492964]);
      points.push([2725, '98', 2251.5, 756.4714382701385]);
      points.push([2726, '99', 2147, 755.9715408257215]);
      points.push([2727, '100', 2043.75, 757.2212844367641]);
      points.push([2894, '101', 2493.75, 271.9936928316464]);
      points.push([2896, '102', 2493.5, 618.672570534846]);
      points.push([2895, '103', 1705.5, 438.6768778693313]);

      points.push([2521, '120', 1610.5, 945.7472309992594]);
      points.push([2522, '121', 1610.5, 1018.482309161935]);
      points.push([2523, '122', 1610.5, 1088.9678488247341]);
      points.push([2524, '123', 1610.5, 1158.9534910431162]);

    }

    return points;
  }

}
