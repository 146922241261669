import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { TransitionDirection } from '../enums/enums';
// fake service for lookup
@Injectable()
export class TransitionDirectionDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: TransitionDirection.None, name: 'None' });
        lookups.push({ id: TransitionDirection.LeftToRight, name: 'Left To Right' });
        lookups.push({ id: TransitionDirection.RightToLeft, name: 'Right To Left' });
        lookups.push({ id: TransitionDirection.TopToBottom, name: 'Top To Bottom' });
        lookups.push({ id: TransitionDirection.BottomToTop, name: 'Bottom To Top' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
