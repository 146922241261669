import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData, IObjectData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ISpotApp } from '../spot-app/spot-app.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IMedia, IMediaInitUpload, Media } from './media.model';
import { MediaVersion } from '../media-version/media-version.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class MediaDataService extends BaseListableDataService<Media, IMedia, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'media', ObjectType.MEDIA,
      ['versions', 'locations', 'campaigns', 'types', 'tags', 'screengroups', 'timeranges'],
      ['campaigns', 'versions', 'types', 'tags', 'locations', 'screengroups', 'device-channels', 'devices', 'timeranges']);
  }

  public initUpload(data: IMediaInitUpload): Observable<IMedia> {
    return this.initUploadData(data).pipe(map((x) => x.data),
      tap((value) => {
        // comment
      }, (error) => {
        this.toastr.error(`Failed to init upload ${this.resourceDisplayName}`, 'Error!');
      }, () => {
        // comment
      }));
  }

  // public put(id: number, dtm: IMedia, alertSucces = false): Observable<IMedia> {

  //   // clean object
  //   const copy = JSON.parse(JSON.stringify(dtm)) as IMedia; // Object.assign({}, dtm); // not copying array objects

  //   Media.cleanForSave(copy, 'PUT');

  //   return super.put(id, copy, alertSucces);
  // }

  public getAppPool(data: ISpotApp, pageNr: number, pageSize: number): Observable<IListData<IMedia>> {
    return this.getAppPoolData(data, pageNr, pageSize, this.listIncludes).pipe(tap((value) => {

      // this.toastr.success(`${this.resourceDisplayName} inited`, "Success!");
    }, (error) => {
      this.toastr.error(`Failed to get app pool`, 'Error!');
    }, () => {
      // comment
    }));
  }

  private getAppPoolData(data: ISpotApp, pageNr: number, pageSize: number, includes: string[]): Observable<IListData<IMedia>> {
    const top = pageSize;
    let skip = 0;
    if (pageNr > 0) {
      skip = ((pageNr - 1) * pageSize);
    }

    const include = this.getIncludeQueryString('&', includes);

    const url = `${this.apiBaseUrl}/${this.resourceName}/get-app-pool?$skip=${skip}&$top=${top}${include}`;
    return this.http.post<IListData<IMedia>>(url, data, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }

  private initUploadData(data: any): Observable<IObjectData<IMedia>> {
    let query = '';

    // if (addToInbox) {
    //   query = `${query}&add_to_inbox=1`;
    // }

    query = this.fixQueryStringStart(query);

    return this.http.post<IObjectData<IMedia>>(`${this.apiBaseUrl}/${this.resourceName}/init-upload${query}`, data, {
      headers: this.headers
    }).pipe(catchError((err, caught) => this.handleError(err, caught)));
  }
}
