import { Injectable } from '@angular/core';
import { HubConnection, LogLevel, HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ClientDataService } from '../data/client/client-data.service';
import { DeviceConnection, DateReportType } from '../data/device/device-connection.model';

export enum HubAppType { Unknown = 0, ClientPortal = 1, FrontWebApp = 2 }
export interface IDeviceHubConnectionInfo {
    connectionId: string;
    hash: string;
    type: HubAppType;
    dateCreated: string;
    clientID: number;
    deviceID: number;
}
export interface IHubCommand {
    Name: string;
    Type: string;
    Value: string;
    DeviceHashs: string[];
}
enum IFrameMediaState { Unknown = 0, Loaded = 1, Error = 2, Partial = 3, Ended = 4 }
export interface IHubMediaData {
    type: string;
    hash: string;
    mediaState: IFrameMediaState;
    mediaStateReason: string;
    remainingTime: number;
}
export interface IHubMediaStatusInfo {
    connectionInfo: IDeviceHubConnectionInfo;
    mediaData: IHubMediaData;
    dateCreated: string;
}
enum IFramePlayerState { Unknown = 0, Loaded = 1 }
export interface IHubPlayerData {
    type: string;
    hash: string;
    date: string;
    playerState: IFramePlayerState;
    playerStartedDate: string;
    playerVersion: string;
    userAgent: string;
    playerUrl: string;
    consoleLog: string;
}
export interface IHubPlayerDataInfo {
    connectionInfo: IDeviceHubConnectionInfo;
    playerData: IHubPlayerData;
    dateCreated: string;
}
export interface IDeviceConsoleLogItem {
    type: string;
    timestamp: string;
    message: string;
    index: number;
}
export interface IHubPlayerConsoleLogData {
    type: string;
    hash: string;
    date: string;
    consoleLog: IDeviceConsoleLogItem[];
}
export interface IHubPlayerConsoleLogDataInfo {
    connectionInfo: IDeviceHubConnectionInfo;
    playerConsoleLogData: IHubPlayerConsoleLogData;
    dateCreated: string;

}

enum IFrameContentState { Unknown = 0, Loaded = 1 }
export interface IHubContentData {
    type: string;
    hash: string;
    date: string;
    contentState: IFrameContentState;
}
export interface IHubContentDataInfo {
    connectionInfo: IDeviceHubConnectionInfo;
    contentData: IHubContentData;
    dateCreated: string;
}

@Injectable()
export class DeviceHubService {
    // private heartbeatIntervalTimout = 60 * 1000; // 60s
    // private heartbeatTimeout = 2 * 1000; // 2s
    constructor(private clientDataService: ClientDataService) {

        // if (environment.production) {
        //     this.heartbeatIntervalTimout = 5 * 60 * 1000; // 5m
        // }

    }

    private messageHub: HubConnection;
    private restartTimout = 60 * 1000; // 10s

    private inited = false;

    public deviceConnections: DeviceConnection[] = [];
    private deviceConnectionAddedSubject = new Subject<DeviceConnection>();
    public deviceConnectionAdded$ = this.deviceConnectionAddedSubject.asObservable().pipe(share());

    private deviceConnectionUpdatedSubject = new Subject<DeviceConnection>();
    public deviceConnectionUpdated$ = this.deviceConnectionUpdatedSubject.asObservable().pipe(share());


    private deviceConseoleLogsAddedSubject = new Subject<IHubPlayerConsoleLogDataInfo>();
    public deviceConseoleLogsAdded$ = this.deviceConseoleLogsAddedSubject.asObservable().pipe(share());

    private heartbeatInterval: number;
    // private heartbeatOK = false;
    // private startHeartbeat(hash: string) {
    //     clearInterval(this.heartbeatInterval);
    //     this.heartbeatInterval = window.setInterval(() => {
    //         this.heartbeatOK = false;
    //         this.messageHub.invoke('Heartbeat').then((response: boolean) => {
    //             if (response) {
    //                 this.heartbeatOK = true;
    //             }
    //         });
    //         // wait to check if heartbeat is OK
    //         window.setTimeout(() => {
    //             if (!this.heartbeatOK) {
    //                 this.restart(hash);
    //             }
    //         }, this.heartbeatTimeout);
    //     }, this.heartbeatIntervalTimout);
    // }

    private restartTimer: number;
    public init() {

        if (!this.inited && this.clientDataService.sessionClient && this.clientDataService.sessionClient.hash) {
            this.inited = true;
            // can't restart the connection if non in 'initial' state, se reset also the connection
            this.start(this.clientDataService.sessionClient.hash);
        }
    }

    private start(clientHash: string) {
        this.messageHub = new HubConnectionBuilder()
            .withUrl(`${environment.MESSAGE_HUB_URL}&hash=${clientHash}`, {
                transport: HttpTransportType.WebSockets,
                logger: LogLevel.Debug
            })
            .build();

        this.messageHub.onclose((error: any) => {
            this.restart(clientHash);
        });

        this.messageHub.on('FrontDisconnected', (connectionInfo: IDeviceHubConnectionInfo) => {
            this.removeConnectionInfo(connectionInfo);
        });

        // this.messageHub.on('FrontConnected', (connectionInfo: IDeviceHubConnectionInfo) => {

        // });


        this.messageHub.on('Pong', (connectionInfo: IDeviceHubConnectionInfo) => {
            this.saveConnectionInfo(connectionInfo, DateReportType.Heartbeat,
                new Date(connectionInfo.dateCreated));
        });

        this.messageHub.on('Heartbeat', (connectionInfo: IDeviceHubConnectionInfo) => {
            this.saveConnectionInfo(connectionInfo,
                DateReportType.Heartbeat, new Date(connectionInfo.dateCreated));
        });

        this.messageHub.on('PlayerDataInfo', (playerDataInfo: IHubPlayerDataInfo) => {
            if (playerDataInfo.playerData.playerState === IFramePlayerState.Loaded) {
                this.saveConnectionInfo(playerDataInfo.connectionInfo, DateReportType.PlayerLoaded,
                    new Date(playerDataInfo.playerData.date), new Date(playerDataInfo.playerData.playerStartedDate), null, null, playerDataInfo.playerData);
            }
        });
        this.messageHub.on('PlayerConsoleLogDataInfo', (playerConsoleLogDataInfo: IHubPlayerConsoleLogDataInfo) => {
            this.saveConsoleLogInfo(playerConsoleLogDataInfo);
        });
        this.messageHub.on('ContentDataInfo', (contentDataInfo: IHubContentDataInfo) => {
            if (contentDataInfo.contentData.contentState === IFrameContentState.Loaded) {
                this.saveConnectionInfo(contentDataInfo.connectionInfo, DateReportType.ContentLoaded,
                    new Date(contentDataInfo.contentData.date), null,
                    null, contentDataInfo.contentData.hash);
            }
        });

        this.messageHub.on('MediaStatusInfo', (mediaStatusInfo: IHubMediaStatusInfo) => {
            // this.saveConnectionInfo(hash, connectionInfo);

            let dateReportType = DateReportType.Unknown;
            if (mediaStatusInfo.mediaData.mediaState === IFrameMediaState.Error) {
                dateReportType = DateReportType.MediaError;
            } else if (mediaStatusInfo.mediaData.mediaState === IFrameMediaState.Partial) {
                dateReportType = DateReportType.MediaPartial;
            } else if (mediaStatusInfo.mediaData.mediaState === IFrameMediaState.Ended) {
                dateReportType = DateReportType.MediaEnded;
            }

            this.saveConnectionInfo(mediaStatusInfo.connectionInfo, dateReportType,
                new Date(mediaStatusInfo.dateCreated), null,
                mediaStatusInfo.mediaData.mediaStateReason);
        });

        this.messageHub.start().then(() => {
            // if heartbeat doesn't have have a valid response in n secounds
            // then the connection will be restarted
            // maybe Heartbeat is overkill for Client App this.startHeartbeat(hash);

            this.messageHub.send('Ping');

        }, (reason: any) => {
            throw reason;
        }).catch((reason: any) => {
            this.restart(clientHash);
        });
    }

    public saveConnectionInfo(
        connectionInfo: IDeviceHubConnectionInfo, dateReportType: DateReportType,
        date1: Date, date2: Date = null, reason: string = null, hash: string = null, playerData: IHubPlayerData = null) {

        // console.log('saveConnectionInfo');
        // console.log(connectionInfo);
        const match = this.findDeviceConnection(connectionInfo.deviceID);
        if (match) {

            this.setDateReport(match, dateReportType, date1, date2, reason, hash);

            if (playerData) {
                match.playerVersion = playerData.playerVersion;
                match.userAgent = playerData.userAgent;
                match.playerUrl = playerData.playerUrl;
            }

            const connectionInfoMatch = Enumerable.AsEnumerable(match.connectionInfos)
                .FirstOrDefault((x) => x.connectionId === connectionInfo.connectionId);
            if (connectionInfoMatch) {
                connectionInfoMatch.dateCreated = connectionInfo.dateCreated;
            } else {
                match.connectionInfos.push(connectionInfo);
            }

            this.deviceConnectionUpdatedSubject.next(match);

        } else {
            const deviceConnection = new DeviceConnection();
            // deviceConnection.hash = connectionInfo.hash;
            deviceConnection.clientID = connectionInfo.clientID;
            deviceConnection.deviceID = connectionInfo.deviceID;

            if (playerData) {
                deviceConnection.playerVersion = playerData.playerVersion;
                deviceConnection.userAgent = playerData.userAgent;
                deviceConnection.playerUrl = playerData.playerUrl;
            }

            this.setDateReport(deviceConnection, dateReportType, date1, date2, reason, hash);

            deviceConnection.connectionInfos.push(connectionInfo);
            this.deviceConnections.push(deviceConnection);
            this.deviceConnectionAddedSubject.next(deviceConnection);
        }
    }

    public saveConsoleLogInfo(consoleLogDataInfo: IHubPlayerConsoleLogDataInfo) {
        const match = this.findDeviceConnection(consoleLogDataInfo.connectionInfo.deviceID);
        if (match) {
            this.deviceConseoleLogsAddedSubject.next(consoleLogDataInfo);
        }
    }

    public removeConnectionInfo(connectionInfo: IDeviceHubConnectionInfo) {
        const match = this.findDeviceConnection(connectionInfo.deviceID);
        if (match) {

            const connectionInfoMatch = Enumerable.AsEnumerable(match.connectionInfos)
                .FirstOrDefault((x) => x.connectionId === connectionInfo.connectionId);
            if (connectionInfoMatch) {
                const index = match.connectionInfos.indexOf(connectionInfoMatch);
                match.connectionInfos.splice(index, 1);
            }
        }
    }

    private setDateReport(deviceConnection: DeviceConnection, dateReportType: DateReportType, date1: Date, date2: Date, reason: string, hash: string) {
        if (dateReportType === DateReportType.PlayerLoaded) {
            deviceConnection.playerResponse = date1;
            deviceConnection.playerStarted = date2;
        } else if (dateReportType === DateReportType.ContentLoaded) {
            deviceConnection.lastContentLoaded = date1;
            deviceConnection.lastContentLoadedHash = hash;
        } else if (dateReportType === DateReportType.Heartbeat) {
            deviceConnection.lastHeartbeat = date1;
        } else if (dateReportType === DateReportType.MediaError) {
            deviceConnection.lastMediaError = date1;
            deviceConnection.lastMediaErrorReason = reason;
        } else if (dateReportType === DateReportType.MediaPartial) {
            deviceConnection.lastMediaPartial = date1;
            deviceConnection.lastMediaPartialReason = reason;
        } else if (dateReportType === DateReportType.MediaEnded) {
            deviceConnection.lastMediaEnded = date1;
            deviceConnection.lastMediaEndedReason = reason;
        }
    }

    public findDeviceConnection(deviceID: number) {
        return Enumerable.AsEnumerable(this.deviceConnections).FirstOrDefault((x) => x.deviceID === deviceID);
    }

    public sendCommand(command: IHubCommand) {
        if (this.messageHub) {
            this.messageHub.send('SendCommand', command);
        } else {
            console.error('Send Command failed - MessageHub not started');
        }
    }

    private restart(clientHash: string) {
        clearInterval(this.heartbeatInterval);
        clearTimeout(this.restartTimer);
        delete this.messageHub;

        this.restartTimer = window.setTimeout(() => {
            this.start(clientHash);
        }, this.restartTimout);
    }

}
