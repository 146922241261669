import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IDistributionChannel, DistributionChannel } from './distribution-channel.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class DistributionChannelDataService extends BaseListableDataService<DistributionChannel, IDistributionChannel, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'distribution-channel', ObjectType.DISTRIBUTION_CHANNEL, [], []);
  }

}
