import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { SpotType, RemoteTriggerType } from '../enums/enums';

// fake service for lookup
@Injectable()
export class SpotRemoteTriggerTypeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: RemoteTriggerType.InstanceScreenTimeRequest, name: 'Instance Screen Time Request' });
        // lookups.push({ id: RemoteTriggerType.ProximityDetected, name: 'Proximity Detected' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
