
export interface IDeviceProgram {

    id: number;
    name: string;
}

export interface IDeviceProgramSchedule {
    program_id: number;
    start: string;
    stop: string;
}

export interface IDeviceProgramResult {
    programs: IDeviceProgram[];
    schedule: IDeviceProgramSchedule[];
}

export class DeviceProgram {
    constructor(dtm: IDeviceProgram, scheduleDtm: IDeviceProgramSchedule) {
        this.dtm = dtm;
    }
    private dtm: IDeviceProgram;

    public get id() {
        return this.dtm.id;
    }

    public get name() {
        return this.dtm.name;
    }
}
