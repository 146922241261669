import { BaseModel } from './base.model';
import { ObjectType } from './enums/enums';

export interface IBaseRelationModel {
    created_at: string;
    created_by: number;
}

export abstract class BaseRelationModel<IT extends IBaseRelationModel> extends BaseModel<IT> {
    constructor(dtm: IT) {
        super(dtm);
    }

    public get created_at() {
        return this.dtm.created_at;
    }
    public set created_at(value) {
        this.dtm.created_at = value;
    }

    public get created_by() {
        return this.dtm.created_by;
    }
    public set created_by(value) {
        this.dtm.created_by = value;
    }

    public cleanForSave(dtm: IT, saver: ObjectType) {
        super.cleanForSave(dtm, saver);
        delete dtm.created_at;
        delete dtm.created_by;
    }

}
