export const environment = {
  production: false,
  VOOGSTV_CLIENTAPI_URL: 'https://capi-test.strealer.io',
  VOOGSTV_CLIENTAPI_KEY: '',
  VOOGSTV_JOBAPI_URL: 'https://processapi-test.strealer.io/api',
  VOOGSTV_JOBAPI_KEY: '06w3qAiziRYrxaeBG0lZ9uxmQcIKGrzR',
  VOOGSTV_UPLOADAPI_URL: 'https://uploadapi-test.strealer.io/api',
  VOOGSTV_UPLOADAPI_KEY: '06w3qAiziRYrxaeBG0lZ9uxmQcIKGrzR',
  VOOGSTV_PROCESSAPI_URL: 'https://processapi-test.strealer.io/api',
  VOOGSTV_PROCESSAPI_KEY: '06w3qAiziRYrxaeBG0lZ9uxmQcIKGrzR',
  VOOGSTV_IMAGEAPI_URL: 'https://imageapi.strealer.io/remote.axd',
  PROCESSAPI_JOB_HUB_URL: 'https://processapi-test.strealer.io/jobhub?api-key=06w3qAiziRYrxaeBG0lZ9uxmQcIKGrzR',
  MESSAGE_HUB_URL: 'https://hub-v2-test.strealer.io/message?apikey=FcBSV9I0JkiwcROOP6ydiiDhgeKKCt9r&apptype=1',
  ApplicationInsightsInstrumentationKey: 'ea4eb69d-1b93-4c33-8ee6-6d25aae5fac9',
  VOOGSTV_FRONT_URL: 'http://player-test.strealer.io',
  VOOGSTV_RENDERERV3_URL: 'http://render-v3-test.azurewebsites.net',
  PLAYLIST_TEST_URL: 'https://playlistapi-test.strealer.io'
};
