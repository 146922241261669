import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Campaign } from './campaign.model';
import { IMedia, Media } from '../media/media.model';
import { ObjectType } from '../enums/enums';
import { PropertyUpdateMode } from '../base.model';

export interface ICampaignMediaRelationOptionSet {
    duration: number;
}

export interface ICampaignMediaRelation extends IBaseRelationModel {
    media_id: number;
    campaign_id: number;
    media: IMedia;
    option_set: ICampaignMediaRelationOptionSet;
}

export class CampaignMediaRelation extends BaseRelationModel <ICampaignMediaRelation> {
    constructor(dtm: ICampaignMediaRelation = null) {
        super(dtm || {
            media_id: 0,
            campaign_id: 0,
            media: null,
            option_set: {
                duration: null
            },
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get mediaID() {
        return this.dtm.media_id;
    }
    public set mediaID(value) {
        this.dtm.media_id = value;
    }

    public get campaignID() {
        return this.dtm.campaign_id;
    }
    public set campaignID(value) {
        this.dtm.campaign_id = value;
    }

    public get optionDuration() {
        return this.dtm.option_set ? this.dtm.option_set.duration : null;
    }
    public set optionDuration(value) {
        if (!this.dtm.option_set || Array.isArray(this.dtm.option_set)) {
            this.dtm.option_set = {} as ICampaignMediaRelationOptionSet;
        }
        this.dtm.option_set.duration = value;
    }


    public get media() { return this.getModelProperty(() => this.dtm.media, (x) => new Media(x), PropertyUpdateMode.Update); }
    public set media(value) { this.setModelProperty(() => this.dtm.media, (x) => this.dtm.media = x, value, PropertyUpdateMode.Update); }


    public update(dtm: ICampaignMediaRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this.updateModelProperty(() => this.dtm.media, (x) => new Media(x), (x) => this.media = x, forceReplaceDtm);

    }

    public cleanForSave(dtm: ICampaignMediaRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.media;

    }

    public static getInstance(campaignID: number, mediaID: number){
        const empty = new CampaignMediaRelation();
        empty.campaignID = campaignID;
        empty.mediaID = mediaID;
        return empty;
    }
}
