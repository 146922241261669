<!-- <div mat-dialog-title class="flex-row-spacebetween-center">
  <div>TEST</div>
</div>

<mat-dialog-content>


</mat-dialog-content> -->

<mat-expansion-panel expanded>
  <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
    <mat-panel-title>
      {{title}}
      <button type="button" class="close-button" mat-icon-button (click)="onCloseClick();$event.stopPropagation()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-panel-title>
    <!-- <mat-panel-description>
      Type your name and age
    </mat-panel-description> -->

  </mat-expansion-panel-header>
  <mat-list>

    <ng-template ngFor let-item [ngForOf]="items">
      <mat-list-item>
        <mat-icon mat-list-icon color="primary">{{item.mime_icon}}</mat-icon>
        <div mat-line>
          <span class="item-name one-line" [matTooltip]="item.name">{{item.name}}</span>
          <div class="spinners" *ngIf="item.isUploading" [matTooltip]="item.progressLabel">
            <mat-progress-spinner class="bg-spinner" mode="determinate" diameter="24" strokeWidth="3" value="100">
            </mat-progress-spinner>
            <mat-progress-spinner mode="determinate" diameter="24" strokeWidth="3" [value]="item.progress">
            </mat-progress-spinner>
          </div>

          <button type="button" *ngIf="item.isWaiting" class="end-button" matTooltip="In Upload Queue" mat-icon-button>
            <mat-icon>watch_later</mat-icon>
          </button>
          <!-- [matTooltip]="item.find_text"  -->
          <button type="button" *ngIf="item.isUploaded" class="end-button" mat-icon-button
            (click)="onFindInInboxClick(item);$event.stopPropagation()">
            <mat-icon class="success">check_circle</mat-icon>
            <mat-icon class="search">search</mat-icon>
          </button>
          <button type="button" *ngIf="item.isUploadError" class="end-button" mat-icon-button
            (click)="onRetryUploadClick(item);$event.stopPropagation()">
            <mat-icon class="error">error</mat-icon>
            <mat-icon class="retry">autorenew</mat-icon>
          </button>
        </div>
      </mat-list-item>

      <mat-divider></mat-divider>
    </ng-template>

    <!-- <mat-list-item>
      <mat-icon mat-list-icon>image</mat-icon>
      <div mat-line>
        <span class="one-line">//item.namw</span>
        <div class="spinners">
          <mat-progress-spinner class="bg-spinner" mode="determinate" diameter="24" strokeWidth="3" value="100">
          </mat-progress-spinner>
          <mat-progress-spinner mode="determinate" diameter="24" strokeWidth="3" value="45">
          </mat-progress-spinner>
        </div>

      </div>
    </mat-list-item> -->

  </mat-list>

</mat-expansion-panel>