import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToasterService } from '../toaster.service';
import { map, tap } from 'rxjs/operators';
import * as Enumerable from 'linq-es2015';
import { Observable, combineLatest } from 'rxjs';
import { IPackagesRequestResponse } from './models/packages-request-response';
import { InstalledPackage, IInstalledPackage } from './models/installed-package.model';
import { IotDeviceInfo, IIotDeviceInfo } from './models/iot-device-info.model';

@Injectable({
  providedIn: 'root'
})
export class DevicePortalApiService {

  constructor(private http: HttpClient, private toastr: ToasterService) {

    // note: CORS needs to be disabled as OPTIONS requests get 401 response

  }


  private getIotPlayerAppInfo(baseUrl: string): Observable<IInstalledPackage> {

    baseUrl = this.cleanBaseUrl(baseUrl);

    return this.http.get<IPackagesRequestResponse>(`${baseUrl}/api/app/packagemanager/packages`).pipe(map((data) => {
      const match = Enumerable.AsEnumerable(data.InstalledPackages).FirstOrDefault((x) => x.Name === 'Strealer Player');

      if (match) {
        return match; // new InstalledPackage(match);
      }
      throw new Error('Package not found');

    }),
      tap((value) => {
        // this.toastr.success(`Reprocess task added to the que`, 'Success!');
      }, (error) => {
        console.error(error);
        // this.toastr.error(`Failed to get package info`, 'Error!');
      }));
  }

  private getIotDeviceInformation(baseUrl: string): Observable<IIotDeviceInfo> {

    baseUrl = this.cleanBaseUrl(baseUrl);

    return this.http.get<IIotDeviceInfo>(`${baseUrl}/api/iot/device/information`).pipe(
      tap((value) => {
        // this.toastr.success(`Reprocess task added to the que`, 'Success!');
      }, (error) => {
        console.error(error);
        // this.toastr.error(`Failed to get package info`, 'Error!');
      }));
  }


  public getIotDeviceInfo(baseUrl: string): Observable<IotDeviceInfo> {

    const joined = combineLatest([this.getIotDeviceInformation(baseUrl), this.getIotPlayerAppInfo(baseUrl)]);

    return joined.pipe(map((res) => {

      const deviceInfo = res[0];
      deviceInfo.playerAppPackage = res[1];

      return new IotDeviceInfo(deviceInfo);
    }));
  }



  private getUsernameFromBaseUrl(baseUrl: string) {
    const split1 = baseUrl.split('//')[1];
    const split2 = split1 ? split1.split(':')[0] : null;
    return split2;
  }
  private getPasswordFromBaseUrl(baseUrl: string, username: string) {
    const split1 = baseUrl.split(username + ':')[1];
    const lastIndexofAt = split1 ? split1.lastIndexOf('@') : -1;
    if (lastIndexofAt > 1) {
      return split1.slice(0, lastIndexofAt - 1);
    }
    return '';
  }
  // private cleanBaseUrl(baseUrl: string, username: string, password: string) {
  //   return baseUrl.replace(`${username}:${password}@`, '').replace(new RegExp('[' + '/' + ']+$'), '');
  // }
  private cleanBaseUrl(baseUrl: string) {
    return baseUrl.replace(new RegExp('[' + '/' + ']+$'), '');
  }
}
