import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { UploadItem, UploadItemType } from '../../../core/data/upload-item/upload-item.model';
import { UploadItemService } from '../../../core/services/upload-item.service';

@Component({
  selector: 'app-upload-summary-dialog',
  templateUrl: './upload-summary-dialog.component.html',
  styleUrls: ['./upload-summary-dialog.component.scss']
})
export class UploadSummaryDialogComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<UploadSummaryDialogComponent>,
    public uploadItemService: UploadItemService, private router: Router) {

  }

  public ngOnInit() {
    // comment
  }

  public get items() {
    return this.uploadItemService.items;
  }
  private get uploadingCount() {
    return this.uploadItemService.uploadingCount + this.uploadItemService.waitingCount;
  }
  private get uploadedCount() {
    return this.uploadItemService.uploadedCount;
  }
  private get uploadErrorCount() {
    return this.uploadItemService.uploadErrorCount;
  }

  public get title() {
    let totalLablel = '';

    if (this.uploadErrorCount > 0) {
      totalLablel = `/${this.items.length}`;
    }

    if (this.uploadingCount === 1) {
      return 'Uploading 1 item';
    } else if (this.uploadingCount > 1) {
      return `Uploading ${this.uploadingCount} items`;
    } else if (this.uploadedCount === 1) {
      return `1${totalLablel} upload complete`;
    } else if (this.uploadedCount > 1) {
      return `${this.uploadedCount}${totalLablel} uploads complete`;
    } else if (this.uploadErrorCount === 1) {
      return '1 upload error';
    } else if (this.uploadErrorCount > 1) {
      return `${this.uploadErrorCount} upload errors`;
    }

    return 'Done';
  }

  public onCloseClick() {
    this.dialogRef.close();
  }

  public onFindInInboxClick(item: UploadItem) {
    if (item.type === UploadItemType.MediaVersion) {
      // this.router.navigate(['collection', 'inbox'], { queryParams: { find: item.parentID } });
      this.router.navigate(['content', 'media'], { queryParams: { find: item.parentID } });
    } else if (item.type === UploadItemType.Resource) {
      this.router.navigate(['configuration', 'resources'], { queryParams: { find: item.parentID } });
    }

  }

  public onRetryUploadClick(item: UploadItem) {
    alert('Failed upload resume not implemented');
  }

  public ngOnDestroy(): void {
    // if (this._statusChangesSub)
    //   this._statusChangesSub.unsubscribe();
  }
}
