import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem, DeviceType } from '../enums/enums';
import { Device, IDevice } from 'app/core/data/device/device.model';


export enum FloorMapPointPosition { Default = 0, Top = 1, Bottom = 2 }

export interface IFloorMapPoint extends IBaseNameModel {
    device_id: number;
    lat: number;
    lng: number;
    pos: FloorMapPointPosition;
}

export class FloorMapPoint extends BaseNameModel<IFloorMapPoint> {
    constructor(dtm: IFloorMapPoint = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            name: '',
            device_id: null,
            lat: 0,
            lng: 0,
            pos: FloorMapPointPosition.Default,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }

    public get lat() {
        return this.dtm.lat;
    }
    public set lat(value) {
        this.dtm.lat = value;
    }

    public get lng() {
        return this.dtm.lng;
    }
    public set lng(value) {
        this.dtm.lng = value;
    }


    public get pos() {
        return this.dtm.pos;
    }
    public set pos(value) {
        this.dtm.pos = value;
    }

    private _device: Device = null;
    public get device() {
        return /*fix*/ this._device;
    }
    public set device(value) {
        this._device = value;
    }

    public get deviceName() {
        if (this.device) {
            return this.device.name;
        }
        if (this.dtm.device_id) {
            return 'Not loaded device: ' + this.dtm.device_id;
        }
        return 'Unknown device: ' + this.dtm.device_id;
    }

    public get deviceTypeClass() {
        if (this.device) {
            return DeviceType[this.device.dtm.type_id] ? DeviceType[this.device.dtm.type_id] : 'UnknownType';
        }
        return 'UnknownType';
    }

    public get deviceIsPortrait() {
        if (this.device) {
            return this.device.isPortrait;
        }
        return false;
    }

    public get deviceIsLandscape() {
        if (this.device) {
            return this.device.isLandscape;
        }
        return false;
    }

    public update(dtm: IFloorMapPoint, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        this._device = null;
    }

}
