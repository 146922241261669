import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { ApectRatioRestrictionMode } from '../enums/enums';
// fake service for lookup
@Injectable()
export class PlaylistApectRatioRestrictionModeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];
        lookups.push({ id: null, name: 'Inherit' });
        lookups.push({ id: ApectRatioRestrictionMode.None, name: 'No Restriction' });
        lookups.push({ id: ApectRatioRestrictionMode.Loose, name: 'Loose' });
        lookups.push({ id: ApectRatioRestrictionMode.Approximate20, name: 'Approximate 20%' });
        lookups.push({ id: ApectRatioRestrictionMode.Approximate1, name: 'Approximate 1%' });
        lookups.push({ id: ApectRatioRestrictionMode.Strict, name: 'Strict' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
