import { IDeviceHubConnectionInfo } from '../../hub/device-hub.service';

export enum DateReportType { Unknown, PlayerLoaded, ContentLoaded, Heartbeat, MediaError, MediaPartial, MediaEnded, PlayerConsoleLog }
export class DeviceConnection {
    // public hash: string;
    public clientID: number;
    public deviceID: number;
    public connectionInfos: IDeviceHubConnectionInfo[] = [];
    public lastContentLoaded: Date;
    public lastContentLoadedHash: string;
    public lastHeartbeat: Date;
    public lastMediaError: Date;
    public lastMediaPartial: Date;
    public lastMediaEnded: Date;
    public lastMediaErrorReason: string;
    public lastMediaPartialReason: string;
    public lastMediaEndedReason: string;

    // TODO: refactor, this info should/can be diffrent per connectionInfo
    public playerResponse: Date;
    public playerStarted: Date;
    public playerVersion: string;
    public userAgent: string;
    public playerUrl: string;
}
