import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { RelatedConfBase, IRelatedConfBase } from 'app/core/data/related-conf-base/related-conf-base.model';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { RelatedConfBaseType, ObjectType } from '../enums/enums';

@Injectable()
export class RelatedConfBaseDataService extends BaseListableDataService<RelatedConfBase, IRelatedConfBase, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'related-conf-base', ObjectType.RELATED_CONF_BASE, [], []);

  }

  public tvEventLookups$: Observable<ILookup[]>;
  public tvEventShowLookups$: Observable<ILookup[]>;

  public roomEventLookups$: Observable<ILookup[]>;
  public roomEventShowLookups$: Observable<ILookup[]>;

  protected buildLookupItems() {
    super.buildLookupItems();
    this.tvEventLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.TvEvent}`).pipe(share());
    this.tvEventShowLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.TvEventShow}`).pipe(share());

    this.roomEventLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.RoomEvent}`).pipe(share());
    this.roomEventShowLookups$ = this.getLookup(`type_id=${RelatedConfBaseType.RoomEventShow}`).pipe(share());
  }
}
