import { IBaseNameModel, BaseNameModel } from './base-name.model';
import { PropertySet, IPropertySet } from './property/property-set.model';
import { ObjectType } from './enums/enums';
import { PropertyUpdateMode } from './base.model';

export interface IBaseRelatedConfModel extends IBaseNameModel {
    property_set: IPropertySet[];
    related_conf_base_id: number;
    relatedConfBase: IBaseNameModel;
}

export abstract class BaseRelatedConfModel<IT extends IBaseRelatedConfModel> extends BaseNameModel<IT> {
    constructor(dtm: IT) {
        super(dtm);

    }


    public get propertySet() { return this.getModelArray(() => this.dtm.property_set, (x) => new PropertySet(x), PropertyUpdateMode.Clear); }
    public set propertySet(value) { this.setModelArray(() => this.dtm.property_set, (x) => this.dtm.property_set = x, value, PropertyUpdateMode.Clear); }

    public get relatedConfBaseID() {
        return this.dtm.related_conf_base_id;
    }
    public get relatedConfBaseName() {
        return this.dtm.relatedConfBase ? this.dtm.relatedConfBase.name : this.relatedConfBaseID + '';
    }

    protected clearLocalProps() {
        super.clearLocalProps();

        PropertySet.fillMissingValues(this.propertySet);
    }




    public cleanForSave(dtm: IT, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.relatedConfBase;

        this.cleanDTMs(PropertySet, dtm.property_set, saver);

    }
}
