import { NumberSymbol } from "@angular/common";
import { BaseModel, IBaseModel } from "../base.model";
import { DeviceChannelPinpointItem, IDeviceChannelPinpointItem } from "./device-channel-pinpoint-item.model";
import * as Enumerable from 'linq-es2015';


export interface IDeviceChannelPinpoint extends IBaseModel {
    i: number;
    pi: number;
    pn: string;
    pc: string;
    si: number;
    sn: string;
    sc: string;
    ai: number;
    an: string;
    ac: string;
    ti: number;
    tsi: number;
    tst: string;
    dm: number;
    ot: number;
    is: IDeviceChannelPinpointItem[];
    te: {
        i: number;
        a: number;
        n: string;
    },
    wih: NumberSymbol;
    aih: number;
    ri: {
        cd: string;
        cb: string;
    }
}



export class DeviceChannelPinpoint extends BaseModel<IDeviceChannelPinpoint> {
    constructor(dtm: IDeviceChannelPinpoint) {
        super(dtm);
        this.update(this.dtm, true, true);
    }


    public get id() {
        return this.dtm.i;
    }
    public get programID() {
        return this.dtm.pi;
    }

    public get programName() {
        return this.dtm.pn;
    }

    public get spotID() {
        return this.dtm.si;
    }

    public get spotName() {
        return this.dtm.sn;
    }

    public get appID() {
        return this.dtm.ai;
    }

    public get appName() {
        return this.dtm.an;
    }

    public items: DeviceChannelPinpointItem[];
    public firstItem: DeviceChannelPinpointItem;

    public update(dtm: IDeviceChannelPinpoint, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        let items: DeviceChannelPinpointItem[] = [];

        if (this.dtm.is) {
            items = Enumerable.AsEnumerable(this.dtm.is).Select((x) => new DeviceChannelPinpointItem(x)).ToArray();
        }

        this.items = items;

        if(this.items.length){
            this.firstItem = this.items[0];
        }

    }

}
