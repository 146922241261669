import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookupRestrict } from '../lookup-restrict.model';
import { ToasterService } from '../../services/toaster.service';

import { ILocationMy, LocationMy } from './location.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class LocationDataService extends BaseListableDataService<LocationMy, ILocationMy, ILookupRestrict> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'location', ObjectType.LOCATION, ['related', 'relatedConfBase', 'timeranges'], ['related', 'timeranges']);
  }

}
