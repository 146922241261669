<app-main-wrapper [isLoggedIn]="authService.isLoggedIn" [isRouterOutletOpened]="isRouterOutletOpened">
	<img logo src="/assets/images/logo_small.png?v=2" />

	<!-- <a mainMenu mat-list-item routerLink="collection/inbox" routerLinkActive="active" matTooltip="Inbox"
	 matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon style="margin: 0;" [matBadge]="totalInboxItemsCount" [matBadgeHidden]="!totalInboxItemsCount"
			 matBadgeColor="accent" matBadgeSize="small" matBadgePosition="below after">inbox</mat-icon>
		</div>
	</a> -->

	<a mainMenu mat-list-item [routerLink]="['content', 'media']" routerLinkActive="active" matTooltip="Content"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">local_movies</mat-icon>
		</div>
	</a>

	<a mainMenu mat-list-item [routerLink]="['content', 'campaigns']" routerLinkActive="active" matTooltip="Campaigns"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">assignment</mat-icon>
		</div>
	</a>

	<mat-list-item mainMenu routerLinkActive="active" [matMenuTriggerFor]="programmingMenu" matTooltip="Programming"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">featured_play_list</mat-icon>
		</div>

		<mat-menu #programmingMenu="matMenu" class="sidenav-menu" [overlapTrigger]="true">
			<a mat-menu-item [routerLink]="['programming', 'programs']" routerLinkActive="active">Programs</a>
			<a mat-menu-item [routerLink]="['programming', 'spots']" routerLinkActive="active">Spots</a>
		</mat-menu>
	</mat-list-item>


	<mat-list-item mainMenu routerLinkActive="active" [matMenuTriggerFor]="outputMenu" matTooltip="Output"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">cast</mat-icon>
		</div>

		<mat-menu #outputMenu="matMenu" class="sidenav-menu" [overlapTrigger]="true">
			<a mat-menu-item [routerLink]="['output', 'devices']" routerLinkActive="active">Devices</a>
			<a mat-menu-item [routerLink]="['output', 'channels']" routerLinkActive="active">Channels</a>
			<a mat-menu-item [routerLink]="['output', 'playlists']" routerLinkActive="active">Content Streams</a>
			<a mat-menu-item [routerLink]="['output', 'groups']" routerLinkActive="active">Groups</a>
			<a mat-menu-item [routerLink]="['output', 'floormaps']" routerLinkActive="active">Floor Maps</a>
		</mat-menu>
	</mat-list-item>


	<mat-list-item mainMenu routerLinkActive="active" [matMenuTriggerFor]="integrationMenu" matTooltip="Integration"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">sync</mat-icon>
		</div>

		<mat-menu #integrationMenu="matMenu" class="sidenav-menu" [overlapTrigger]="true">
			<a mat-menu-item [routerLink]="['integration', 'events']" routerLinkActive="active">Events</a>
			<a mat-menu-item [routerLink]="['integration', 'shows']" routerLinkActive="active">Shows</a>
			<a mat-menu-item [routerLink]="['integration', 'auditoriums']" routerLinkActive="active">Auditoriums</a>
			<a mat-menu-item [routerLink]="['integration', 'extsources']" routerLinkActive="active">External Sources</a>
			<a mat-menu-item [routerLink]="['integration', 'ratings']" routerLinkActive="active">Ratings</a>
			<a mat-menu-item [routerLink]="['integration', 'products']" routerLinkActive="active">Products</a>
			<a mat-menu-item [routerLink]="['integration', 'product-groups']" routerLinkActive="active">Product
				Groups</a>
			<!-- <a mat-menu-item [routerLink]="['integration', 'product-prices']" routerLinkActive="active">Product
				Prices</a> -->
			<a mat-menu-item [routerLink]="['integration', 'product-price-groups']" routerLinkActive="active">Product
				Price Groups</a>
			<a mat-menu-item [routerLink]="['integration', 'salespoints']" routerLinkActive="active">Sales Points</a>
		</mat-menu>
	</mat-list-item>


	<mat-list-item mainMenu routerLinkActive="active" [matMenuTriggerFor]="configurationMenu" matTooltip="Configuration"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">settings_applications</mat-icon>
		</div>

		<mat-menu #configurationMenu="matMenu" class="sidenav-menu" [overlapTrigger]="true">
			<a mat-menu-item [routerLink]="['configuration', 'locations']" routerLinkActive="active">Locations</a>
			<a mat-menu-item [routerLink]="['configuration', 'mediatypes']" routerLinkActive="active">Media Types</a>
			<a mat-menu-item [routerLink]="['configuration', 'tags']" routerLinkActive="active">Tags</a>
			<a mat-menu-item [routerLink]="['configuration', 'resources']" routerLinkActive="active">Resources</a>
			<a mat-menu-item [routerLink]="['configuration', 'resourcetypes']" routerLinkActive="active">Resource
				Types</a>
			<a mat-menu-item [routerLink]="['configuration', 'languages']" routerLinkActive="active">Languages</a>
			<a mat-menu-item [routerLink]="['configuration', 'distributionchannels']"
				routerLinkActive="active">Distribution Channels</a>
			<a mat-menu-item [routerLink]="['configuration', 'genres']" routerLinkActive="active">Genres</a>
			<a mat-menu-item [routerLink]="['configuration', 'apiaccess']" routerLinkActive="active">API Access</a>
			<a mat-menu-item [routerLink]="['configuration', 'template-base']" routerLinkActive="active">Templates Bases</a>
			<a mat-menu-item [routerLink]="['configuration', 'template-instance']" routerLinkActive="active">Template
				Instances</a>
			<a mat-menu-item [routerLink]="['configuration', 'time-ranges']" routerLinkActive="active">Time Ranges</a>
			<a mat-menu-item [routerLink]="['configuration', 'ad-spaces']" routerLinkActive="active">Ad Spaces</a>


		</mat-menu>
	</mat-list-item>


	<mat-list-item bottomMenu routerLinkActive="active" [matMenuTriggerFor]="moreMenu" matTooltip="More"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon color="primary">more_vert</mat-icon>
		</div>

		<mat-menu class="sidenav-menu" #moreMenu="matMenu">
			<a mat-menu-item [routerLink]="['collection','trash']" routerLinkActive="active">
				<mat-icon>delete</mat-icon>
				<span>Trash</span>
			</a>
			<a mat-menu-item [routerLink]="['dashboard']" routerLinkActive="active">
				<mat-icon>dashboard</mat-icon>
				<span>Dashboard</span>
			</a>
			<!-- <a mat-menu-item [routerLink]="['dashboard', 'changelog']" routerLinkActive="active">
				<mat-icon>change_history</mat-icon>
				<span>Changelog</span>
			</a> -->

			<!-- <a mat-menu-item routerLink="settings" routerLinkActive="active">
					<mat-icon>settings</mat-icon>
					<span>Settings</span>
				</a> -->

		</mat-menu>

	</mat-list-item>

	<mat-list-item bottomMenu routerLinkActive="active" [matMenuTriggerFor]="accountMenu" matTooltip="Account"
		matTooltipPosition="right">
		<div matListAvatar>
			<mat-icon>account_box</mat-icon>
		</div>

		<mat-menu class="sidenav-menu" #accountMenu="matMenu">
			<a mat-menu-item routerLink="account/info" routerLinkActive="active">
				<mat-icon>account_circle</mat-icon>
				<span>Profile</span>
			</a>
			<a mat-menu-item routerLink="account/users" routerLinkActive="active">
				<mat-icon>supervised_user_circle</mat-icon>
				<span>Users</span>
			</a>
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="onLogOutClick()">
				<mat-icon>power_settings_new</mat-icon>
				<span>Log Out</span>
			</button>
		</mat-menu>
	</mat-list-item>

	<router-outlet (activate)="onRouterOutletActivate($event)" (deactivate)="onRouterOutletDeactivate($event)">

	</router-outlet>
</app-main-wrapper>