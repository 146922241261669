import { from } from 'rxjs';
import { BaseNameModel, IBaseNameModel } from '../base-name.model';
import { Status, StatusSystem, ObjectType } from '../enums/enums';
import { ISalesPointScreenGroupRelation, SalesPointScreenGroupRelation } from './sales-point-screen-group-relation.model';

export interface ISalesPoint extends IBaseNameModel {
    ext_id: string;
    salespointScreengroups: ISalesPointScreenGroupRelation[]
}

export class SalesPoint extends BaseNameModel<ISalesPoint> {
    constructor(dtm: ISalesPoint = null) {
        super(dtm || {
            id: 0,
            name: '',
            client_id: 0,
            ext_id: '',
            salespointScreengroups: [],
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get extID() {
        return this.dtm.ext_id;
    }
    public set extID(value) {
        this.dtm.ext_id = value;
    }


    public get screenGroupLookups() { return this.getLookupArray(() => this.dtm.salespointScreengroups, (x) => this.createLookup(x.screengroup_id, x.screengroup?.name)); }
    public set screenGroupLookups(value) { this.setLookupArray(() => this.dtm.salespointScreengroups, (x) => this.dtm.salespointScreengroups = x, value, (item) => SalesPointScreenGroupRelation.getInstance(this.id, item.id).dtm); }


    public update(dtm: ISalesPoint, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: ISalesPoint, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTMs(SalesPointScreenGroupRelation, dtm.salespointScreengroups, saver);
    }
}
