import { BaseRelationModel, IBaseRelationModel } from '../base-relation.model';
import { Campaign } from './campaign.model';
import { EventBase, IEventBase } from '../event/event.model';
import { Show } from '../show/show.model';
import { RegularEvent } from '../event/regular-event.model';
import { ObjectType } from '../enums/enums';

export interface ICampaignEventRelation extends IBaseRelationModel {
    event_id: number;
    campaign_id: number;
    event: IEventBase;
}

export class CampaignEventRelation extends BaseRelationModel <ICampaignEventRelation> {
    constructor(dtm: ICampaignEventRelation = null) {
        super(dtm || {
            event_id: 0,
            campaign_id: 0,
            event: null,
            created_at: null,
            created_by: null
        });
        this.update(this.dtm, true, true);
    }

    public get event_id() {
        return this.dtm.event_id;
    }
    public set event_id(value) {
        this.dtm.event_id = value;
    }

    public get campaign_id() {
        return this.dtm.campaign_id;
    }
    public set campaign_id(value) {
        this.dtm.campaign_id = value;
    }

    public update(dtm: ICampaignEventRelation, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);
    }

    public cleanForSave(dtm: ICampaignEventRelation, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        delete dtm.event;

    }

    public static getInstance(campaignId: number, eventId: number){
        const empty = new CampaignEventRelation();
        empty.event_id = eventId;
        empty.campaign_id = campaignId;
        return empty;
    }
}
