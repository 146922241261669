import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup, ILookupWithStringID } from '../lookup.model';
import { Observable } from 'rxjs';
import { DeviceDataService } from '../device/device-data.service';

@Injectable()
export class TimeZoneDataService implements ILookupable<ILookupWithStringID> {
    public lookupItems$: Observable<ILookupWithStringID[]>;

    constructor() {
        const lookups: ILookupWithStringID[] = [];

        lookups.push({ id: 'Europe/Tallinn', name: 'Estonia/Tallinn' });
        lookups.push({ id: 'Europe/Riga', name: 'Latvia/Riga' });
        lookups.push({ id: 'Europe/Vilnius', name: 'Lithuania/Vilnius' });
        lookups.push({ id: 'Europe/Warsaw', name: 'Poland/Warsaw' });
        lookups.push({ id: 'Europe/Helsinki', name: 'Finland/Helsinki' });
        lookups.push({ id: 'Atlantic/Reykjavik', name: 'Iceland/Reykjavik' });

        lookups.push({ id: 'UTC', name: 'Coordinated Universal Time' });

        this.lookupItems$ = new Observable<ILookupWithStringID[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
