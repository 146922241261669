import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { SpotAppType, SpotTriggerType, ObjectType, SpotTriggerAction, SpotType } from '../enums/enums';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { ISpot, Spot } from './spot.model';
import { FilterMy } from '../filter/filter.model';
import { SortMy } from '../sort/sort.model';

@Injectable()
export class SpotDataService extends BaseListableDataService<Spot, ISpot, ISpot> {

    constructor(http: HttpClient, toastr: ToasterService) {
        super(http, toastr, 'spot', ObjectType.SPOT, ['programs', 'timeranges'], ['programs', 'timeranges']);

    }

    // public spotAppTypes$: Observable<ILookup[]>;


    public static getTriggerActionsList(spotType: SpotType, spotTriggerType: SpotTriggerType, inTriggerAction: SpotTriggerAction = SpotTriggerAction.Unknown) {
        const lookups: ILookup[] = [];


        if (spotType === SpotType.Generic) {
            if (spotTriggerType === SpotTriggerType.In) {
                lookups.push({ id: SpotTriggerAction.IN_PlayTurn, name: 'Play Turn' });
                // lookups.push({ id: SpotTriggerAction.IN_ShowStart, name: 'Show Start' }); // TODO: TEMP, remove in future
                lookups.push({ id: SpotTriggerAction.IN_RemoteTrigger, name: 'Remote Trigger' });
            } else if (spotTriggerType === SpotTriggerType.Out) {
                lookups.push({ id: SpotTriggerAction.OUT_ContentEnd, name: 'Content End' });
            }
        } else if (spotType === SpotType.Interval) {

        } else if (spotType === SpotType.Timed) {
            if (spotTriggerType === SpotTriggerType.In) {
                lookups.push({ id: SpotTriggerAction.IN_ShowStart, name: 'Show Start' });
                lookups.push({ id: SpotTriggerAction.IN_ShowEnd, name: 'Show End' });
                lookups.push({ id: SpotTriggerAction.IN_Time, name: 'Time' });

            } else if (spotTriggerType === SpotTriggerType.Out) {
                if (inTriggerAction === SpotTriggerAction.IN_ShowStart) {

                    lookups.push({ id: SpotTriggerAction.OUT_ContentEnd, name: 'Content End' });
                    lookups.push({ id: SpotTriggerAction.OUT_ShowStart, name: 'Show Start' });
                    lookups.push({ id: SpotTriggerAction.OUT_ShowEnd, name: 'Show End' });

                } else if (inTriggerAction === SpotTriggerAction.IN_ShowEnd) {

                    lookups.push({ id: SpotTriggerAction.OUT_ContentEnd, name: 'Content End' });
                    lookups.push({ id: SpotTriggerAction.OUT_ShowEnd, name: 'Show End' });

                    // can be implemented, but not available for users, for now
                    // lookups.push({ id: SpotTriggerAction.OUT_ShowStart, name: 'Show Start' });

                } else if (inTriggerAction === SpotTriggerAction.IN_Time) {

                    lookups.push({ id: SpotTriggerAction.OUT_ContentEnd, name: 'Content End' });
                    lookups.push({ id: SpotTriggerAction.OUT_Time, name: 'Time' });

                }
            }
        }

        return lookups;
    }

    public resetCachedObservables() {
        super.resetCachedObservables();
    }

    public buildLookupItems() {
        super.buildLookupItems();

        // this.spotAppTypes$ = this.getAppTypes().pipe(share());
    }

    // replace lookup request with full request
    protected getLookupDatas(
        pageNr: number, pageSize: number,
        filters: FilterMy[], sorts: SortMy[], includes: string[],
        search = '', query = '', subResourceName = ''): Observable<IListData<ISpot>> {
        return super.getDatas(pageNr, pageSize, filters, sorts, includes, search, query, subResourceName);
    }

    public getTriggerActions(spotType: SpotType, spotTriggerType: SpotTriggerType, inTriggerAction: SpotTriggerAction = SpotTriggerAction.Unknown)
        : Observable<ILookup[]> {
        return this.getTriggerActionsData(spotType, spotTriggerType, inTriggerAction).pipe(map((x) => x.data)
            , tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} trigger actions data`, 'Error!');
            }));
    }

    public getSourceTypes(sportappType: SpotAppType): Observable<ILookup[]> {
        return this.getSourceTypesData(sportappType).pipe(map((x) => x.data)
            , tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} source types data`, 'Error!');
            }));
    }

    public getAppTypes(): Observable<ILookup[]> {
        return this.getAppTypesData().pipe(map((x) => x.data)
            , tap((value) => {
                // comment
            }, (error) => {
                this.toastr.error(`Failed to get ${this.resourceDisplayName} app types data`, 'Error!');
            }));
    }



    protected getTriggerActionsData(spotType: SpotType, spotTriggerType: SpotTriggerType, inTriggerAction: SpotTriggerAction = SpotTriggerAction.Unknown)
        : Observable<IListData<ILookup>> {


        const listData: IListData<ILookup> = {
            totalCount: 0,
            data: [],
            filter: []
        };

        listData.data = SpotDataService.getTriggerActionsList(spotType, spotTriggerType, inTriggerAction);

        listData.totalCount = listData.data.length;

        return new Observable<IListData<ILookup>>((observer) => {
            observer.next(listData);
            observer.complete();
        });


        // let query = '';
        // if (keyActionId > 0) {
        //     query = `&key_action_id=${keyActionId}`;
        // }

        // const url = `${this.apiBaseUrl}/${this.resourceName}/get-trigger-actions?type_id=${spotTriggerType}${query}`;
        // return this.http.get<IListData<ILookup>>(url, {
        //     headers: this.headers
        // }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    // id 1 == Content Type
    protected getSourceTypesData(type: SpotAppType): Observable<IListData<ILookup>> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/get-source-types?type_id=${type}`;
        return this.http.get<IListData<ILookup>>(url, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected getAppTypesData(): Observable<IListData<ILookup>> {
        const url = `${this.apiBaseUrl}/${this.resourceName}/get-app-types`;
        return this.http.get<IListData<ILookup>>(url, {
            headers: this.headers
        }).pipe(catchError((err, caught) => this.handleError(err, caught)));
    }



}
