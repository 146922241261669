import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MessageBoxService } from '../../shared/components/message-box/message-box.service';

@Injectable()
export class ToasterService {

    constructor(private snackBar: MatSnackBar, private messageBoxService: MessageBoxService) {
        // comment
    }

    public success(msg: string, titel: string = '') {
        let message = msg;
        if (titel && titel.length) {
            message = `${titel} ${msg}`;
        }
        this.snackBar.open(message, null, {
            duration: 5000,
            panelClass: 'success'
        });
    }

    public error(msg: string, title: string = null) {
        let message = msg;
        if (title && title.length) {
            message = `${title} - ${msg}`;
        }
        this.snackBar.open(message, null, {
            duration: 5000,
            panelClass: 'error'
        });
    }

    public alert(msg: string, title: string = null, trimMsg = true) {
        if (trimMsg && msg.length > 500) {
            msg = msg.slice(0, 500) + '...';
        }

        this.messageBoxService.alert(msg, title);
    }
}
