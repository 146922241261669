import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageBoxResult } from './message-box-result';
import { Observable } from 'rxjs';

import { MessageBoxComponent } from './message-box.component';

@Injectable()
export class MessageBoxService {

  constructor(private dialog: MatDialog) {

  }

  public confirm(title: string, content: string, okLabel = 'Yes', cancelLabel = 'Cancel'): Observable<MessageBoxResult> {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {
        okLabel,
        cancelLabel,
        title,
        content
      }
    });
    return dialogRef.afterClosed();
  }

  public alert(content: string, title = 'Info', okLabel = 'OK'): Observable<MessageBoxResult> {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {
        okLabel,
        title,
        content
      }
    });
    return dialogRef.afterClosed();
  }

}
