import { Injectable } from '@angular/core';
import { UploadItem, UploadItemStatus, UploadItemType } from '../data/upload-item/upload-item.model';
import * as Enumerable from 'linq-es2015';
import { formatNumber } from '@angular/common';

@Injectable()
export class UploadItemService {

    constructor() {
        // comment
    }

    public get uploadingCount() {
        return Enumerable.AsEnumerable(this.items)
            .Count((x) => x.isUploading);
    }
    public get uploadedCount() {
        return Enumerable.AsEnumerable(this.items).Count((x) => x.isUploaded);
    }
    public get uploadErrorCount() {
        return Enumerable.AsEnumerable(this.items).Count((x) => x.isUploadError);
    }
    public get waitingCount() {
        return Enumerable.AsEnumerable(this.items)
            .Count((x) => x.isWaiting);
    }

    public items: UploadItem[] = [];

    // private calculateSpeed() {

    // }

    private maxUploads = 3;
    public createUploadItems(fileList: FileList, type: UploadItemType) {
        const items: UploadItem[] = [];
        for (let index = 0; index < fileList.length; index++) {
            const file = fileList.item(index);
            items.push(new UploadItem(file, type));
        }
        return items;
    }
    public addUploadItems(items: UploadItem[]) {

        items.forEach((item) => {
            this.items.unshift(item);
        });

        this.setUploadingItems();
    }
    private setUploadingItems() {
        if (this.waitingCount > 0 && this.uploadingCount < this.maxUploads) {
            let top = this.maxUploads - this.uploadingCount;
            if (top > this.waitingCount) {
                top = this.waitingCount;
            }
            const nextItems = Enumerable.AsEnumerable(this.items).Where((x) => x.isWaiting).Reverse().Take(top).ToArray();
            nextItems.forEach((nextItem) => {
                nextItem.status = UploadItemStatus.Uploading;
                nextItem.uploadFunction().then((filled) => {
                    nextItem.status = UploadItemStatus.Uploaded;
                }, (reject) => {
                    nextItem.status = UploadItemStatus.UploadError;
                }).catch((error) => {
                    nextItem.status = UploadItemStatus.UploadError;
                }).then(() => {
                    // always
                    this.setUploadingItems();
                });

            });
        }
    }
}
