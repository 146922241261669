import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { TimeRange, ITimeRange } from './time-range.model';
import { TimeRangeType, ObjectType } from '../enums/enums';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TimeRangeDataService extends BaseListableDataService<TimeRange, ITimeRange, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'timerange', ObjectType.TIMERANGE, [], []);
  }

  public lookupItemsMediaRating$: Observable<ILookup[]>;
  public lookupItemsOutputActivity$: Observable<ILookup[]>;
  public lookupItemsProgramValidity$: Observable<ILookup[]>;

  public buildLookupItems() {
    super.buildLookupItems();

    this.lookupItemsMediaRating$ = this.getLookup(`type=${TimeRangeType.MediaRating}`).pipe(share());
    this.lookupItemsOutputActivity$ = this.getLookup(`type=${TimeRangeType.OutputActivity}`).pipe(share());
    this.lookupItemsProgramValidity$ = this.getLookup(`type=${TimeRangeType.ProgramValidity}`).pipe(share());
  }

}
