import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {

  public static enableScrollStop(elem: HTMLElement, setPositions: boolean) {

    const scrollLeft = elem.scrollLeft;
    const scrollTop = elem.scrollTop;

    elem.dataset.cstyleleft = elem.style.left || '';
    elem.dataset.cstyletop = elem.style.top || '';

    elem.dataset.cscrollleft = scrollLeft + '';
    elem.dataset.cscrolltop = scrollTop + '';

    if (setPositions) {
      elem.style.left = scrollLeft * -1 + 'px';
      elem.style.top = scrollTop * -1 + 'px';
    } else {
      elem.style.left = 'unset';
      elem.style.top = 'unset';
    }
    elem.classList.add('custom-scrollstop');
  }

  public static disableScrollStop(elem: HTMLElement) {
    const previousScrollBehavior = elem.style['scrollBehavior' as any] || '';

    elem.style.left = elem.dataset.cstyleleft;
    elem.style.top = elem.dataset.cstyletop;
    elem.classList.remove('custom-scrollstop');

    delete elem.dataset.cstyleleft;
    delete elem.dataset.cstyletop;

    // Disable user-defined smooth scrolling temporarily while we restore the scroll position.
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
    elem.style['scrollBehavior' as any] = 'auto';

    elem.scroll(+elem.dataset.cscrollleft, +elem.dataset.cscrolltop);

    delete elem.dataset.cscrollleft;
    delete elem.dataset.cscrolltop;

    elem.style['scrollBehavior' as any] = previousScrollBehavior;
  }
}
