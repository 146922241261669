import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss']
})
export class MainWrapperComponent {
  @Input() public isLoggedIn: boolean;
  @Input() public isRouterOutletOpened: boolean;
}
