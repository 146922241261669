import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { TransitionMode } from '../enums/enums';
// fake service for lookup
@Injectable()
export class TransitionModeDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: TransitionMode.None, name: 'None' });
        lookups.push({ id: TransitionMode.Push, name: 'Push' });
        lookups.push({ id: TransitionMode.Slide, name: 'Slide' });
        lookups.push({ id: TransitionMode.Fade, name: 'Fade' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
