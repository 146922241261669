import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';

import { IProductPriceGroup, ProductPriceGroup } from './product-price-group.model';
import { ObjectType } from '../enums/enums';

@Injectable()
export class ProductPriceGroupDataService extends BaseListableDataService<ProductPriceGroup, IProductPriceGroup, ILookup> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'productpricegroup', ObjectType.PRODUCT_PRICE_GROUP, [], []);

    this.resourceDisplayName = 'Product Price Group';
  }

}
